<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PurchaseOrder from "../../api/order/purchase_order";
// import Station from "../../api/order/station";

import OrderCollar from "./OrderCollar.vue";
import OrderFabric from "./OrderFabric.vue";
import OrderThread from "./OrderThread.vue";
import OrderSmooth from "./OrderSmooth.vue";
import OrderJmaop from "./OrderJmaop.vue";
import OrderEmbroidery from "./OrderEmbroidery.vue";
import OrderBox from "./OrderBox.vue";
import OrderOther from "./OrderOther.vue";

import PurchaseOrderBox from "../../api/order/purchase_order_box";
import PurchaseOrderCollar from "../../api/order/purchase_order_collar";
import PurchaseOrderEmbroidery from "../../api/order/purchase_order_embroidery";
import PurchaseOrderFabric from "../../api/order/purchase_order_fabric";
import PurchaseOrderJmaop from "../../api/order/purchase_order_jmaop";
import PurchaseOrderSmooth from "../../api/order/purchase_order_smooth";
import PurchaseOrderThread from "../../api/order/purchase_order_thread";
import PurchaseOrderOther from "../../api/order/purchase_order_other";

import Item from "../../api/order/item";
import Customer from "../../api/order/customer";
import CmtFactory from "../../api/order/cmt_factory";
import Agent from "../../api/order/agent";
import ProductGroup from "../../api/order/product_group";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileUpload";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";
import SizeItem from "../../api/order/purchase_size_item";
import SizeType from "../../api/order/purchase_size_type";

let size_itemAPI = new SizeItem();
let size_typeAPI = new SizeType();
let userApi = new User();
let purchase_orderAPI = new PurchaseOrder();
let itemAPI = new Item();
let customerAPI = new Customer();
let cmt_factoryAPI = new CmtFactory();
let product_groupAPI = new ProductGroup();
let agentAPI = new Agent();

let purchase_order_boxAPI = new PurchaseOrderBox();
let purchase_order_collarAPI = new PurchaseOrderCollar();
let purchase_order_embroideryAPI = new PurchaseOrderEmbroidery();
let purchase_order_fabricAPI = new PurchaseOrderFabric();
let purchase_order_jmaopAPI = new PurchaseOrderJmaop();
let purchase_order_smoothAPI = new PurchaseOrderSmooth();
let purchase_order_threadAPI = new PurchaseOrderThread();

let purchase_order_otherAPI = new PurchaseOrderOther();

// let stationAPI = new Station();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  customerSearch: "",
  statusSearch: "",
  itemSearch: "",
  cmt_factoryEditSearch: "",
  agentEditSearch: "",
  agentAddSearch: "",
  selectAgentList: [],
  selectCmtFactoryList: [],
  selectItemList: [],
  selectCustomerList: [],
  selectProductGroupList: [],
  finish_purchase_order: {
    shipping_date: "", //最後
    shipping_count: 0, //最後
    invoice: "", //最後
  },
  add_purchase_order: {
    item_id: "",
    order_customer_no: "",
    customized_order_id: "",
    ie: "",
    order_price: 0,
    new_agent_ids: [],
    order_count_xs: 0,
    order_count_s: 0,
    order_count_m: 0,
    order_count_l: 0,
    order_count_xl: 0,
    order_count_2l: 0,
    order_count_3l: 0,
    order_total_price: "",
    predict_delivery_date: "",
    shipping_date: "", //最後
    shipping_count: 0, //最後
    customer_id: "",
    color: "",
    status: "",
    remark: "",
    description: "",
    invoice: "", //最後
    commission_rate: 0,
    total_commission: 0,
    image: null,
    cmt_factory_id: "",
    product_group_id: "",
    employer_id: "",
  },
  purchase_orderLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  finishDialog: false,
  cancelDialog: false,
  purchase_orders: { data: null },
  purchase_order: {
    item_id: "",
    order_customer_no: "",
    customized_order_id: "",
    ie: "",
    order_price: 0,
    new_agent_ids: [],
    order_count_xs: 0,
    order_count_s: 0,
    order_count_m: 0,
    order_count_l: 0,
    order_count_xl: 0,
    order_count_2l: 0,
    order_count_3l: 0,
    order_total_price: "",
    predict_delivery_date: "",
    shipping_date: "", //最後
    shipping_count: 0, //最後
    customer_id: "",
    color: "",
    status: "",
    remark: "",
    description: "",
    invoice: "", //最後
    commission_rate: 0,
    total_commission: 0,
    image: null,
    cmt_factory_id: "",
    product_group_id: "",
    employer_id: "",
  },
  page: 1,
  page_num: 10,
  selected_purchase_orders: null,
  purchase_orderFilters: {
    item_id: { value: "", matchMode: "contains" },
    customer_id: { value: "", matchMode: "contains" },
    customized_order_id: { value: "", matchMode: "contains" },
    order_customer_no: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    predict_delivery_date_begin: { value: "", matchMode: "contains" },
    predict_delivery_date_end: { value: "", matchMode: "contains" },
    shipping_date_begin: { value: "", matchMode: "contains" },
    shipping_date_end: { value: "", matchMode: "contains" },
  },
  excel: { data: null },
  excel_box: { data: null },
  excel_collar: { data: null },
  excel_embroidery: { data: null },
  excel_fabric: { data: null },
  excel_jmaop: { data: null },
  excel_smooth: { data: null },
  excel_thread: { data: null },
  excel_other: { data: null },
  excel_detailSearch: null,
  selectExcelDetailList: [
    {
      id: 1,
      name: i18n.$t("PurchaseOrderBox").Title,
    },
    {
      id: 2,
      name: i18n.$t("PurchaseOrderEmbroidery").Title,
    },
    {
      id: 3,
      name: i18n.$t("PurchaseOrderJmaop").Title,
    },
    {
      id: 4,
      name: i18n.$t("PurchaseOrderSmooth").Title,
    },
    {
      id: 5,
      name: i18n.$t("PurchaseOrderThread").Title,
    },
    {
      id: 6,
      name: i18n.$t("PurchaseOrderFabric").Title,
    },
    {
      id: 7,
      name: i18n.$t("PurchaseOrderCollar").Title,
    },
    {
      id: 8,
      name: i18n.$t("PurchaseOrderOther").Title,
    },
  ],
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPurchaseOrderList();
    getItemSelectList();
    getCustomerSelectList();
    getCmtFactorySelectList();
    getAgentSelectList();
    getSizeTypeSelectList();
    // getProductGroupSelectList();
  }
});
const dt = ref();

const dt_box = ref();
const dt_collar = ref();
const dt_embroidery = ref();
const dt_fabric = ref();
const dt_jmaop = ref();
const dt_smooth = ref();
const dt_thread = ref();
const dt_other = ref();

function getSizeTypeSelectList() {
  size_typeAPI.getSizeTypeSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectSizeTypeList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function ExcelDetail() {
  let ids = [];
  for (let i = 0; i < data.selected_purchase_orders.length; i++) {
    ids.push(data.selected_purchase_orders[i].id);
  }
  if (data.excel_detailSearch != null)
    switch (data.excel_detailSearch.id) {
      case 1:
        purchase_order_boxAPI
          .getPurchaseOrderBoxExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_box.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_box.value.exportCSV();
          });
        break;
      case 2:
        purchase_order_embroideryAPI
          .getPurchaseOrderEmbroideryExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_embroidery.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_embroidery.value.exportCSV();
          });
        break;
      case 3:
        purchase_order_jmaopAPI
          .getPurchaseOrderJmaopExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_jmaop.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_jmaop.value.exportCSV();
          });
        break;
      case 4:
        purchase_order_smoothAPI
          .getPurchaseOrderSmoothExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_smooth.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_smooth.value.exportCSV();
          });
        break;
      case 5:
        purchase_order_threadAPI
          .getPurchaseOrderThreadExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_thread.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_thread.value.exportCSV();
          });
        break;
      case 6:
        purchase_order_fabricAPI
          .getPurchaseOrderFabricExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_fabric.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_fabric.value.exportCSV();
          });
        break;
      case 7:
        purchase_order_collarAPI
          .getPurchaseOrderCollarExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_collar.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            dt_collar.value.exportCSV();
          });
        break;
      case 8:
        purchase_order_otherAPI
          .getPurchaseOrderOtherExcel(ids)
          .then((res) => {
            if (res.data.error_code == 0) {
              let tmp = res.data.data.datas;
              data.excel_other.data = tmp;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(() => {
            console.log(data.excel_other.data);
            dt_other.value.exportCSV();
          });
        break;
    }
}
function ExcelDetailOpen() {
  data.excel_detail_open = true;
}
function Excel() {
  purchase_orderAPI
    .getPurchaseOrderList(
      data.purchase_orderFilters.item_id.value,
      data.purchase_orderFilters.order_customer_no.value,
      data.purchase_orderFilters.customized_order_id.value,
      data.purchase_orderFilters.customer_id.value,
      data.purchase_orderFilters.status.value,
      data.purchase_orderFilters.predict_delivery_date_begin.value,
      data.purchase_orderFilters.predict_delivery_date_end.value,
      data.purchase_orderFilters.shipping_date_begin.value,
      data.purchase_orderFilters.shipping_date_end.value,
      1,
      500
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        let tmp = res.data.data.datas;
        for (let i = 0; i < tmp.length; i++) {
          tmp[i].predict_delivery_date = dateFormate(
            tmp[i].predict_delivery_date
          );
          tmp[i].shipping_date = dateFormate(tmp[i].shipping_date);
          tmp[i].agent_name =
            tmp[i].agents != null ? tmp[i].agents[0].name : "";
          // tmp[i].order_count_total =
          //   Number(tmp[i].order_count_xs) +
          //   Number(tmp[i].order_count_s) +
          //   Number(tmp[i].order_count_m) +
          //   Number(tmp[i].order_count_l) +
          //   Number(tmp[i].order_count_xl) +
          //   Number(tmp[i].order_count_2l) +
          //   Number(tmp[i].order_count_3l);
          tmp[i].order_count = "";
          tmp[i].order_total_count = 0;
          if (tmp[i].purchase_size_items != null)
            for (let j = 0; j < tmp[i].purchase_size_items.length; j++) {
              tmp[i].order_count =
                tmp[i].order_count +
                tmp[i].purchase_size_items[j].name +
                ":" +
                tmp[i].purchase_size_items[j].detail.target_count +
                " ";
              tmp[i].order_total_count =
                tmp[i].order_total_count +
                tmp[i].purchase_size_items[j].detail.target_count;
            }

          tmp[i].status =
            tmp[i].status == 1
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .NewPurchaseOrderStatus
              : tmp[i].status == 2
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .LineUpPurchaseOrderStatus
              : tmp[i].status == 3
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .CompletePurchaseOrderStatus
              : i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .CancelPurchaseOrderStatus;
        }
        data.excel.data = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(() => {
      dt.value.exportCSV();
    });
}
function getProductGroupCustomerSelectList(id, type, product_group_id) {
  product_groupAPI.getProductGroupCustomerSelectList(id).then((res) => {
    if (res.data.error_code == 0) {
      data.selectProductGroupList = res.data.data.datas;
      if (type == 1) {
        for (let i = 0; i < data.selectProductGroupList.length; i++) {
          if (data.selectProductGroupList[i].id == product_group_id) {
            data.product_groupEditSearch = data.selectProductGroupList[i];
            break;
          }
        }
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCmtFactorySelectList() {
  cmt_factoryAPI.getCmtFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCmtFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getAgentSelectList() {
  agentAPI.getAgentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectAgentList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getItemSelectList() {
  itemAPI.getItemSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectItemList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCustomerSelectList() {
  customerAPI.getCustomerSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCustomerList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getPurchaseOrderList() {
  CheckUser();
  purchase_orderAPI
    .getPurchaseOrderList(
      data.purchase_orderFilters.item_id.value,
      data.purchase_orderFilters.order_customer_no.value,
      data.purchase_orderFilters.customized_order_id.value,
      data.purchase_orderFilters.customer_id.value,
      data.purchase_orderFilters.status.value,
      data.purchase_orderFilters.predict_delivery_date_begin.value,
      data.purchase_orderFilters.predict_delivery_date_end.value,
      data.purchase_orderFilters.shipping_date_begin.value,
      data.purchase_orderFilters.shipping_date_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.purchase_orders.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.purchase_orderLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.purchase_orderLoading = false;
      }
    });
}

function saveEditPurchaseOrder() {
  let delete_agents = [];
  console.log(data.purchase_order);
  if (data.purchase_order.new_agent_ids == "") {
    if (data.purchase_order.agents != null)
      delete_agents = [data.purchase_order.agents[0].id];
  } else {
    if (data.purchase_order.agents != null) {
      if (
        data.purchase_order.new_agent_ids.length > 0 &&
        data.purchase_order.agents.length > 0
      ) {
        if (
          data.purchase_order.new_agent_ids[0] !=
          data.purchase_order.agents[0].id
        ) {
          delete_agents = [data.purchase_order.agents[0].id];
        }
      }
    }
  }
  purchase_orderAPI
    .editPurchaseOrder(
      data.purchase_order.id,
      parseInt(data.purchase_order.item_id),
      data.purchase_order.order_customer_no,
      data.purchase_order.customized_order_id,
      data.purchase_order.ie,
      data.purchase_order.order_price.toString(),
      data.purchase_order.new_agent_ids == ""
        ? []
        : data.purchase_order.new_agent_ids,
      delete_agents,
      data.purchase_order.order_total_price.toString(),
      data.purchase_order.predict_delivery_date.toISOString(),
      data.purchase_order.shipping_date,
      data.purchase_order.shipping_count.toString(),
      data.purchase_order.customer_id,
      data.purchase_order.color,
      1,
      data.purchase_order.remark,
      data.purchase_order.description,
      data.purchase_order.invoice,
      data.purchase_order.commission_rate.toString(),
      data.purchase_order.image,
      data.purchase_order.cmt_factory_id,
      data.purchase_order.product_group_id,
      store.state.user.id,
      // data.purchase_order.delete_purchase_size_item_ids != null
      //   ? data.purchase_order.new_purchase_size_item_relations
      //   : [],
      data.purchase_order.new_purchase_size_item_relations,
      data.purchase_order.delete_purchase_size_item_ids
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrder").EditSuccess,
          life: 3000,
        });

        data.purchase_orderLoading = true;
        getPurchaseOrderList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveFinishPurchaseOrder() {
  purchase_orderAPI
    .finishPurchaseOrderStatus(
      data.purchase_order.id,
      data.finish_purchase_order.invoice,
      data.finish_purchase_order.shipping_date.toISOString(),
      data.finish_purchase_order.shipping_count.toString(),
      3,
      store.state.user.id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrder").EditSuccess,
          life: 3000,
        });

        data.purchase_orderLoading = true;
        getPurchaseOrderList();
        data.finishDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveAddPurchaseOrder() {
  console.log(data.add_purchase_order.predict_delivery_date);
  purchase_orderAPI
    .addPurchaseOrder(
      parseInt(data.add_purchase_order.item_id),
      data.add_purchase_order.order_customer_no,
      data.add_purchase_order.customized_order_id,
      data.add_purchase_order.ie,
      data.add_purchase_order.order_price.toString(),
      data.add_purchase_order.new_agent_ids,
      data.add_purchase_order.order_total_price.toString(),
      data.add_purchase_order.predict_delivery_date != ""
        ? data.add_purchase_order.predict_delivery_date.toISOString()
        : "",
      data.add_purchase_order.shipping_date != ""
        ? data.add_purchase_order.shipping_date.toISOString()
        : "",
      data.add_purchase_order.shipping_count.toString(),
      data.add_purchase_order.customer_id,
      data.add_purchase_order.color,
      1,
      data.add_purchase_order.remark,
      data.add_purchase_order.description,
      data.add_purchase_order.invoice,
      data.add_purchase_order.commission_rate.toString(),
      data.add_purchase_order.image,
      data.add_purchase_order.cmt_factory_id,
      data.add_purchase_order.product_group_id,
      store.state.user.id,
      data.add_purchase_order.new_purchase_size_item_relations
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrder").AddSuccess,
          life: 3000,
        });

        data.purchase_orderLoading = true;
        getPurchaseOrderList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addPurchaseOrder() {
  data.addDialog = true;
  data.itemAddSearch = "";
  data.customerAddSearch = "";
  data.cmt_factoryAddSearch = "";
  data.agentAddSearch = "";
  data.product_groupAddSearch = "";
  data.selectProductGroupList = [];
  data.add_size_type_id = null;
  data.add_purchase_order = {
    item_id: "",
    order_customer_no: "",
    customized_order_id: "",
    ie: "",
    order_price: 0,
    new_agent_ids: [],
    order_total_price: 0,
    predict_delivery_date: "",
    shipping_date: "", //最後
    shipping_count: 0, //最後
    customer_id: "",
    color: "",
    status: "",
    remark: "",
    description: "",
    invoice: "", //最後
    commission_rate: 0,
    total_commission: 0,
    image: null,
    cmt_factory_id: "",
    product_group_id: "",
    employer_id: "",
    new_purchase_size_item_relations: [],
  };
}
function finishPurchaseOrder(purchase_order) {
  data.purchase_order = { ...purchase_order };
  data.finishDialog = true;
}
function cancelPurchaseOrder(purchase_order) {
  data.cancelDialog = true;
  data.purchase_order = { ...purchase_order };
}
function saveCancelPurchaseOrder() {
  purchase_orderAPI
    .cancelPurchaseOrderStatus(data.purchase_order.id, 4, store.state.user.id)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrder").EditSuccess,
          life: 3000,
        });

        data.purchase_orderLoading = true;
        getPurchaseOrderList();
        data.cancelDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

async function editPurchaseOrder(purchase_order) {
  CheckUser();
  for (let i = 0; i < data.selectItemList.length; i++) {
    if (data.selectItemList[i].id == purchase_order.item_id) {
      data.itemEditSearch = data.selectItemList[i];
      break;
    }
  }
  for (let i = 0; i < data.selectCustomerList.length; i++) {
    if (data.selectCustomerList[i].id == purchase_order.customer_id) {
      data.customerEditSearch = data.selectCustomerList[i];
      getProductGroupCustomerSelectList(
        purchase_order.customer_id,
        1,
        purchase_order.product_group_id
      );
      break;
    }
  }
  for (let i = 0; i < data.selectCmtFactoryList.length; i++) {
    if (data.selectCmtFactoryList[i].id == purchase_order.cmt_factory_id) {
      data.cmt_factoryEditSearch = data.selectCmtFactoryList[i];
      break;
    }
  }
  if (purchase_order.agents != null) {
    for (let i = 0; i < data.selectAgentList.length; i++) {
      if (purchase_order.agents.length != 0) {
        if (data.selectAgentList[i].id == purchase_order.agents[0].id) {
          data.agentEditSearch = data.selectAgentList[i];
          break;
        }
      }
    }
  }

  //   purchase_order.predict_delivery_date = dateFormate(
  //     purchase_order.predict_delivery_date
  //   );
  purchase_order.predict_delivery_date = new Date(
    purchase_order.predict_delivery_date
  );
  purchase_order.shipping_date =
    purchase_order.shipping_date == ""
      ? ""
      : new Date(purchase_order.shipping_date);

  data.purchase_order = { ...purchase_order };
  data.purchase_order.new_agent_ids = [];
  data.purchase_order.order_price = parseFloat(purchase_order.order_price);

  data.purchase_order.purchase_size_items =
    data.purchase_order.purchase_size_items == null
      ? []
      : data.purchase_order.purchase_size_items;
  data.edit_size_type_id = "";
  data.purchase_order.order_total_price = parseFloat(
    purchase_order.order_total_price
  );

  store.commit("set_order_id", purchase_order.id);
  store.commit("set_order_status", purchase_order.status);
  data.editDialog = true;
}
function Next(purchase_order) {
  CheckUser();
  purchase_orderAPI
    .editPurchaseOrderStatus(store.state.order_id, 2, store.state.user.id)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrder").Success,
          life: 3000,
        });

        data.purchase_orderrLoading = true;
        getPurchaseOrderList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(purchase_order) {
  data.purchase_order = purchase_order;
  data.deleteDialog = true;
}
function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}
function deletePurchaseOrder() {
  purchase_orderAPI
    .deletePurchaseOrder([data.purchase_order.id])
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrder").DeleteSuccess,
          life: 3000,
        });

        data.purchase_orderrLoading = true;
        getPurchaseOrderList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedPurchaseOrder() {
  let selectDelete = [];
  for (let i = 0; i < data.selected_purchase_orders.length; i++) {
    selectDelete.push(data.selected_purchase_orders[i].id);
  }
  purchase_orderAPI.deletePurchaseOrder(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("PurchaseOrder").DeleteSuccess,
        life: 3000,
      });

      data.purchase_orderLoading = true;
      data.selected_purchase_orders = [];
      getPurchaseOrderList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
function onUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_purchase_order.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function onEditUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.purchase_order.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
watch(
  () => data.page,
  () => {
    data.purchase_orderLoading = true;
    getPurchaseOrderList();
  }
);
watch(
  () => data.statusSearch,
  () => {
    data.purchase_orderLoading = true;
    data.purchase_orderFilters.status.value =
      data.statusSearch == null ? "" : data.statusSearch.value;
    getPurchaseOrderList();
  }
);
watch(
  () => data.itemSearch,
  () => {
    data.purchase_orderLoading = true;
    data.purchase_orderFilters.item_id.value =
      data.itemSearch == null ? "" : data.itemSearch.id;
    getPurchaseOrderList();
  }
);
watch(
  () => data.customerSearch,
  () => {
    data.purchase_orderLoading = true;
    data.purchase_orderFilters.customer_id.value =
      data.customerSearch == null ? "" : data.customerSearch.id;
    getPurchaseOrderList();
  }
);
watch(
  () => data.purchase_orderFilters.status.value,
  () => {
    data.purchase_orderLoading = true;
    getPurchaseOrderList();
  }
);
watch(
  () => data.predict_delivery_date,
  () => {
    data.purchase_orderLoading = true;
    if (data.predict_delivery_date != null) {
      data.purchase_orderFilters.predict_delivery_date_begin.value =
        data.predict_delivery_date[0] != null
          ? data.predict_delivery_date[0].toISOString()
          : "";
      data.purchase_orderFilters.predict_delivery_date_end.value =
        data.predict_delivery_date[1] != null
          ? data.predict_delivery_date[1].toISOString()
          : "";
    } else {
      data.purchase_orderFilters.predict_delivery_date_begin.value = "";
      data.purchase_orderFilters.predict_delivery_date_end.value = "";
    }

    getPurchaseOrderList();
  }
);
watch(
  () => data.shipping_date,
  () => {
    data.purchase_orderLoading = true;
    if (data.shipping_date != null) {
      data.purchase_orderFilters.shipping_date_begin.value =
        data.shipping_date[0] != null
          ? data.shipping_date[0].toISOString()
          : "";
      data.purchase_orderFilters.shipping_date_end.value =
        data.shipping_date[1] != null
          ? data.shipping_date[1].toISOString()
          : "";
    } else {
      data.purchase_orderFilters.shipping_date_begin.value = "";
      data.purchase_orderFilters.shipping_date_end.value = "";
    }

    getPurchaseOrderList();
  }
);
watch(
  () => data.product_groupAddSearch,
  () => {
    data.add_purchase_order.product_group_id =
      data.product_groupAddSearch == null ? "" : data.product_groupAddSearch.id;
  }
);
watch(
  () => data.itemAddSearch,
  () => {
    data.add_purchase_order.item_id =
      data.itemAddSearch == null ? "" : data.itemAddSearch.id;
  }
);
watch(
  () => data.agentAddSearch,
  () => {
    data.add_purchase_order.new_agent_ids =
      data.agentAddSearch == null ? "" : [data.agentAddSearch.id];
  }
);

watch(
  () => data.customerAddSearch,
  () => {
    data.add_purchase_order.customer_id =
      data.customerAddSearch == null ? "" : data.customerAddSearch.id;
    data.product_groupAddSearch = "";
    data.add_purchase_order.product_group_id = 0;
    getProductGroupCustomerSelectList(
      data.customerAddSearch == null ? 0 : data.customerAddSearch.id,
      0,
      0
    );
  }
);

watch(
  () => data.cmt_factoryAddSearch,
  () => {
    data.add_purchase_order.cmt_factory_id =
      data.cmt_factoryAddSearch == null ? "" : data.cmt_factoryAddSearch.id;
  }
);

watch(
  () => data.add_purchase_order.order_price,
  () => {
    let tmp_count = 0;
    console.log(data.add_purchase_order);
    for (
      let i = 0;
      i < data.add_purchase_order.new_purchase_size_item_relations.length;
      i++
    ) {
      tmp_count =
        tmp_count +
        data.add_purchase_order.new_purchase_size_item_relations[i]
          .target_count;
    }
    data.add_purchase_order.order_total_price =
      tmp_count * data.add_purchase_order.order_price;
  }
);

watch(
  () => data.add_purchase_order.new_purchase_size_item_relations,
  () => {
    let tmp_count = 0;
    for (
      let i = 0;
      i < data.add_purchase_order.new_purchase_size_item_relations.length;
      i++
    ) {
      tmp_count =
        tmp_count +
        data.add_purchase_order.new_purchase_size_item_relations[i]
          .target_count;
    }
    data.add_purchase_order.order_total_price =
      tmp_count * data.add_purchase_order.order_price;
  },
  { deep: true }
);

watch(
  () => data.product_groupEditSearch,
  () => {
    data.purchase_order.product_group_id =
      data.product_groupEditSearch == null
        ? ""
        : data.product_groupEditSearch.id;
  }
);
watch(
  () => data.itemEditSearch,
  () => {
    data.purchase_order.item_id =
      data.itemEditSearch == null ? "" : data.itemEditSearch.id;
  }
);
watch(
  () => data.customerEditSearch,
  () => {
    data.purchase_order.customer_id =
      data.customerEditSearch == null ? "" : data.customerEditSearch.id;
    data.product_groupEditSearch = "";
    data.purchase_order.product_group_id = 0;
    getProductGroupCustomerSelectList(
      data.customerEditSearch == null ? 0 : data.customerEditSearch.id,
      0,
      0
    );
  }
);

watch(
  () => data.cmt_factoryEditSearch,
  () => {
    data.purchase_order.cmt_factory_id =
      data.cmt_factoryEditSearch == null ? "" : data.cmt_factoryEditSearch.id;
  }
);

watch(
  () => data.agentEditSearch,
  () => {
    data.purchase_order.new_agent_ids =
      data.agentEditSearch == null ? "" : [data.agentEditSearch.id];
  }
);

watch(
  () => data.purchase_order.order_price,
  () => {
    if (data.purchase_order.new_purchase_size_item_relations != null) {
      let tmp_count = 0;
      for (
        let i = 0;
        i < data.purchase_order.new_purchase_size_item_relations.length;
        i++
      ) {
        tmp_count =
          tmp_count +
          data.purchase_order.new_purchase_size_item_relations[i].target_count;
      }
      data.purchase_order.order_total_price =
        tmp_count * data.purchase_order.order_price;
    } else {
      let tmp_count = 0;
      for (let i = 0; i < data.purchase_order.purchase_size_items.length; i++) {
        tmp_count =
          tmp_count +
          data.purchase_order.purchase_size_items[i].detail.target_count;
      }

      data.purchase_order.order_total_price =
        tmp_count * data.purchase_order.order_price;
    }
  }
);

watch(
  () => data.purchase_order.new_purchase_size_item_relations,
  () => {
    if (data.purchase_order.new_purchase_size_item_relations != null) {
      let tmp_count = 0;
      for (
        let i = 0;
        i < data.purchase_order.new_purchase_size_item_relations.length;
        i++
      ) {
        tmp_count =
          tmp_count +
          data.purchase_order.new_purchase_size_item_relations[i].target_count;
      }
      data.purchase_order.order_total_price =
        tmp_count * data.purchase_order.order_price;
    }
  },
  { deep: true }
);

watch(
  () => data.purchase_order.commission_rate,
  () => {
    data.purchase_order.total_commission =
      (data.purchase_order.commission_rate *
        data.purchase_order.order_total_price) /
      100;
  }
);

watch(
  () => data.purchase_order.order_total_price,
  () => {
    data.purchase_order.total_commission =
      (data.purchase_order.commission_rate *
        data.purchase_order.order_total_price) /
      100;
  }
);

watch(
  () => data.add_purchase_order.commission_rate,
  () => {
    data.add_purchase_order.total_commission =
      (data.add_purchase_order.commission_rate *
        data.add_purchase_order.order_total_price) /
      100;
  }
);

watch(
  () => data.add_purchase_order.order_total_price,
  () => {
    data.add_purchase_order.total_commission =
      (data.add_purchase_order.commission_rate *
        data.add_purchase_order.order_total_price) /
      100;
  }
);
watch(
  () => data.purchase_orderFilters.order_customer_no.value,
  () => {
    data.purchase_orderLoading = true;
    getPurchaseOrderList();
  }
);
watch(
  () => data.purchase_orderFilters.customized_order_id.value,
  () => {
    data.purchase_orderLoading = true;
    getPurchaseOrderList();
  }
);
watch(
  () => data.add_size_type_id,
  () => {
    data.add_purchase_order.new_purchase_size_item_relations = [];
    if (data.add_size_type_id != null) {
      getSizeItemSelectList("add");
    }
  }
);
watch(
  () => data.edit_size_type_id,
  () => {
    data.purchase_order.new_purchase_size_item_relations = [];
    if (data.edit_size_type_id != null) {
      getSizeItemSelectList("edit");
    }
  }
);
function getSizeItemSelectList(check) {
  size_itemAPI
    .getSizeItemSelectList(
      "",
      check == "add"
        ? data.add_size_type_id != "" &&
          data.add_size_type_id != null &&
          data.add_size_type_id != undefined
          ? data.add_size_type_id.id
          : 0
        : data.edit_size_type_id != "" &&
          data.edit_size_type_id != null &&
          data.edit_size_type_id != undefined
        ? data.edit_size_type_id.id
        : 0
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        if (check == "add") {
          for (let i = 0; i < res.data.data.datas.length; i++) {
            data.add_purchase_order.new_purchase_size_item_relations.push({
              pruchase_size_item_id: res.data.data.datas[i].id,
              pruchase_size_item_name: res.data.data.datas[i].name,
              // purchase_order_id:
              target_count: 0,
              finish_count: 0,
            });
          }
        } else if (check == "edit" && data.edit_size_type_id != "") {
          for (let i = 0; i < res.data.data.datas.length; i++) {
            data.purchase_order.new_purchase_size_item_relations.push({
              pruchase_size_item_id: res.data.data.datas[i].id,
              pruchase_size_item_name: res.data.data.datas[i].name,
              // purchase_order_id:
              target_count: 0,
              finish_count: 0,
            });
          }
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function EditChangeSize() {
  data.purchase_order.delete_purchase_size_item_ids = [];
  for (let i = 0; i < data.purchase_order.purchase_size_items.length; i++) {
    data.purchase_order.delete_purchase_size_item_ids.push(
      data.purchase_order.purchase_size_items[i].id
    );
  }
  data.purchase_order.purchase_size_items = [];
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :resizableColumns="true"
      columnResizeMode="fit"
      :lazy="true"
      :value="data.purchase_orders.data"
      :loading="data.purchase_orderLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.purchase_orderFilters"
      v-model:selection="data.selected_purchase_orders"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("PurchaseOrder").PurchaseOrderTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.purchase_orderFilters[
                        i18n.$t('PurchaseOrder').PurchaseOrderTable
                          .CustomizedOrderID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable
                        .CustomizedOrderID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.purchase_orderFilters[
                        i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerPo
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerPo
                        .Show
                    "
                  />
                </span>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.customerSearch"
                    :options="data.selectCustomerList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerName
                        .Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.itemSearch"
                    :options="data.selectItemList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable.ItemID.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.statusSearch"
                    :options="[
                      {
                        name: i18n.$t('PurchaseOrder').PurchaseOrderTable
                          .NewPurchaseOrderStatus,
                        value: 1,
                      },
                      {
                        name: i18n.$t('PurchaseOrder').PurchaseOrderTable
                          .LineUpPurchaseOrderStatus,
                        value: 2,
                      },
                      {
                        name: i18n.$t('PurchaseOrder').PurchaseOrderTable
                          .CompletePurchaseOrderStatus,
                        value: 3,
                      },
                    ]"
                    optionLabel="name"
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable.Status.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{
                            slotProps.value.value == 1
                              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                                  .NewPurchaseOrderStatus
                              : slotProps.value.value == 2
                              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                                  .LineUpPurchaseOrderStatus
                              : i18n.$t("PurchaseOrder").PurchaseOrderTable
                                  .CompletePurchaseOrderStatus
                          }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.predict_delivery_date"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable
                        .PredictDeliveryDate.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.shipping_date"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate
                        .Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('PurchaseOrder').PurchaseOrderTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addPurchaseOrder"
            />
            <Button
              :label="i18n.$t('PurchaseOrder').PurchaseOrderTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger p-mr-2"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selected_purchase_orders ||
                !data.selected_purchase_orders.length
              "
            />
            <Button
              icon="pi pi-book"
              class="p-button-info p-mr-2"
              label="Excel"
              @click="Excel"
            />
            <Button
              icon="pi pi-book"
              class="p-button-info"
              :label="i18n.$t('PurchaseOrder').ExcelDetail"
              @click="ExcelDetailOpen"
              :disabled="
                !data.selected_purchase_orders ||
                !data.selected_purchase_orders.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <!-- <Column
        class="p-col-1"
        :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.ID.Field"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.ID.Show"
      ></Column> -->
      <Column
        class="p-col-2"
        headerStyle="width: 25em"
        bodyStyle="white-space:nowrap;overflow:scroll;"
        :field="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomizedOrderID.Field
        "
        :header="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomizedOrderID.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space:nowrap;overflow:scroll;"
        :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerPo.Field"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerPo.Show"
      ></Column>
      <Column
        class="p-col-2"
        headerStyle="width: 25em"
        :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.IE.Field"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.IE.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Color.Field"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Color.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.CMTFactoryName.Field
        "
        :header="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.CMTFactoryName.Show
        "
      ></Column>
      <Column
        class="p-col-1"
        style="white-space: nowrap; overflow: scroll"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderCount.Show"
      >
        <template #body="slotProps">
          <Chip
            v-for="item in slotProps.data.purchase_size_items"
            :key="item.id"
            class="p-mr-1"
          >
            {{ item.name }}:{{ item.detail.target_count }}
          </Chip>
        </template>
      </Column>
      <Column
        class="p-col-1"
        :header="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalCount.Show
        "
      >
        <template #body="slotProps">
          {{
            parseFloat(slotProps.data.order_total_price) /
            parseFloat(slotProps.data.order_price)
          }}
        </template>
      </Column>

      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalPrice.Field
        "
        :header="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalPrice.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('PurchaseOrder').PurchaseOrderTable.PredictDeliveryDate.Show
        "
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.predict_delivery_date) }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Image.Show"
      >
        <template #body="slotProps">
          <img class="product-image" :src="slotProps.data.image_url" />
        </template>
      </Column>
      <!-- <Column
        class="p-col-2"
        :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate.Field"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate.Show"
      ></Column> -->
      <Column
        class="p-col-2"
        :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Status.Field"
        :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .NewPurchaseOrderStatus
              : slotProps.data.status == 2
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .LineUpPurchaseOrderStatus
              : slotProps.data.status == 3
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .CompletePurchaseOrderStatus
              : i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .CancelPurchaseOrderStatus
          }}
        </template>
      </Column>

      <Column class="p-col-2" style="min-width: 140px">
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.status == 2"
            icon="pi pi-check-circle"
            class="p-button-rounded p-button-danger p-mr-2"
            @click="finishPurchaseOrder(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == 1"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPurchaseOrder(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status != 1"
            icon="pi pi-search"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPurchaseOrder(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == 1"
            icon="pi pi-times-circle"
            class="p-button-rounded p-button-danger p-mr-2"
            @click="cancelPurchaseOrder(slotProps.data)"
          />
          <!-- <Button
            v-if="slotProps.data.status == 1"
            icon="pi pi-play"
            class="p-button-rounded p-button-danger p-mr-1"
            @click="Next(slotProps.data)"
          /> -->
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            v-if="slotProps.data.status == 1"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrder').AddPurchaseOrder.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{
            i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomerPo.Show
          }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order.order_customer_no"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">
          <span style="color: red">*</span
          >{{
            i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomizedOrderID.Show
          }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order.customized_order_id"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">
          {{ i18n.$t("PurchaseOrder").PurchaseOrderTable.IE.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order.ie"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("PurchaseOrder").PurchaseOrderTable.ItemID.Show }}</label
        >
        <Dropdown
          v-model="data.itemAddSearch"
          :options="data.selectItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('PurchaseOrder').PurchaseOrderTable.ItemID.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{
            i18n.$t("PurchaseOrder").PurchaseOrderTable.CMTFactoryName.Show
          }}</label
        >
        <Dropdown
          v-model="data.cmt_factoryAddSearch"
          :options="data.selectCmtFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('PurchaseOrder').PurchaseOrderTable.CMTFactoryName.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.Agents.Show
        }}</label>
        <Dropdown
          v-model="data.agentAddSearch"
          :options="data.selectAgentList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('PurchaseOrder').PurchaseOrderTable.Agents.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.OrderPrice.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order.order_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="iteam">{{
          i18n.$t("SizeItem").SizeItemTable.PurchaseSizeType.Show
        }}</label>
        <Dropdown
          v-model="data.add_size_type_id"
          :options="data.selectSizeTypeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>

      <div
        :key="index"
        v-for="(item, index) in data.add_purchase_order
          .new_purchase_size_item_relations"
        class="p-field"
      >
        <label for="name">{{ item.pruchase_size_item_name }}</label>
        <InputNumber v-model="item.target_count" required="true" />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.OrderTotalPrice.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order.order_total_price"
          readonly
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span>
          {{
            i18n.$t("PurchaseOrder").PurchaseOrderTable.PredictDeliveryDate.Show
          }}</label
        >
        <Calendar
          id="range"
          v-model="data.add_purchase_order.predict_delivery_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('PurchaseOrder').PurchaseOrderTable.PredictDeliveryDate.Show
          "
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{
            i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomerName.Show
          }}</label
        >
        <Dropdown
          v-model="data.customerAddSearch"
          :options="data.selectCustomerList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerName.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.ProductGroupName.Show
        }}</label>
        <Dropdown
          v-model="data.product_groupAddSearch"
          :options="data.selectProductGroupList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('PurchaseOrder').PurchaseOrderTable.ProductGroupName.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.Color.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order.color"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.Remark.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_purchase_order.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_purchase_order.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.CommissionRate.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order.commission_rate"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          suffix=" %"
          required="true"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.TotalCommission.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order.total_commission"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
          readonly="true"
        />
      </div>

      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          @uploader="onUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddPurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '800px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrder').EditPurchaseOrder.Title"
      :modal="true"
      class="p-fluid"
    >
      <TabView>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Order">
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomerPo.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.purchase_order.order_customer_no"
              :readonly="data.purchase_order.status != 1"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomizedOrderID.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.purchase_order.customized_order_id"
              :readonly="data.purchase_order.status != 1"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.IE.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.purchase_order.ie"
              :readonly="data.purchase_order.status != 1"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.ItemID.Show
            }}</label>
            <Dropdown
              v-model="data.itemEditSearch"
              :options="data.selectItemList"
              optionLabel="name"
              :readonly="data.purchase_order.status != 1"
              :filter="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.ItemID.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.CMTFactoryName.Show
            }}</label>
            <Dropdown
              v-model="data.cmt_factoryEditSearch"
              :options="data.selectCmtFactoryList"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.CMTFactoryName.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.Agents.Show
            }}</label>
            <Dropdown
              v-model="data.agentEditSearch"
              :options="data.selectAgentList"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.Agents.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.OrderPrice.Show
            }}</label>
            <InputNumber
              v-model="data.purchase_order.order_price"
              :readonly="data.purchase_order.status != 1"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>

          <div
            v-if="data.purchase_order.purchase_size_items.length != 0"
            class="p-field"
          >
            <label for="iteam"
              >{{ i18n.$t("SizeItem").SizeItemTable.PurchaseSizeType.Show
              }}<Button
                icon="pi pi-trash"
                class="p-button p-component p-button-icon-only p-button-rounded p-button-danger"
                @click="EditChangeSize"
              /> </label
            ><br />
            <Chip
              v-for="item in data.purchase_order.purchase_size_items"
              :key="item.id"
              class="p-mr-1"
            >
              {{ item.name }}:{{ item.detail.target_count }}
            </Chip>
          </div>

          <div
            v-if="data.purchase_order.purchase_size_items.length == 0"
            class="p-field"
          >
            <label for="iteam">{{
              i18n.$t("SizeItem").SizeItemTable.PurchaseSizeType.Show
            }}</label>
            <Dropdown
              v-model="data.edit_size_type_id"
              :options="data.selectSizeTypeList"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template> </Dropdown
            ><br />
            <div
              :key="index"
              v-for="(item, index) in data.purchase_order
                .new_purchase_size_item_relations"
              class="p-field"
            >
              <label for="name">{{ item.pruchase_size_item_name }}</label>
              <InputNumber v-model="item.target_count" required="true" />
            </div>
          </div>

          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.OrderTotalPrice.Show
            }}</label>
            <InputNumber
              v-model="data.purchase_order.order_total_price"
              readonly
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.PredictDeliveryDate
                .Show
            }}</label>
            <Calendar
              id="range"
              v-model="data.purchase_order.predict_delivery_date"
              :readonly="data.purchase_order.status != 1"
              :manualInput="false"
              :showIcon="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.PredictDeliveryDate
                  .Show
              "
              :showButtonBar="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomerName.Show
            }}</label>
            <Dropdown
              v-model="data.customerEditSearch"
              :options="data.selectCustomerList"
              :readonly="data.purchase_order.status != 1"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerName.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.CustomerName.Show
            }}</label>
            <Dropdown
              v-model="data.product_groupEditSearch"
              :options="data.selectProductGroupList"
              :readonly="data.purchase_order.status != 1"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.ProductGroupName
                  .Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.Color.Show
            }}</label>
            <InputText
              id="name"
              :readonly="data.purchase_order.status != 1"
              v-model.trim="data.purchase_order.color"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.Remark.Show
            }}</label>
            <Textarea
              id="description"
              :readonly="data.purchase_order.status != 1"
              v-model="data.purchase_order.remark"
              required="true"
              rows="3"
              cols="20"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.Description.Show
            }}</label>
            <Textarea
              id="description"
              :readonly="data.purchase_order.status != 1"
              v-model="data.purchase_order.description"
              required="true"
              rows="3"
              cols="20"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.CommissionRate.Show
            }}</label>
            <InputNumber
              v-model="data.purchase_order.commission_rate"
              :readonly="data.purchase_order.status != 1"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              suffix=" %"
              required="true"
            />
          </div>
          <div class="p-field" v-if="data.purchase_order.status == 3">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.Invoice.Show
            }}</label>
            <InputText
              v-model.trim="data.purchase_order.invoice"
              required="true"
              readonly
            />
          </div>
          <div class="p-field" v-if="data.purchase_order.status == 3">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.ShippingCount.Show
            }}</label>
            <InputNumber
              v-model="data.purchase_order.shipping_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
              readonly
            />
          </div>
          <div v-if="data.purchase_order.status == 3" class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.ShippingDate.Show
            }}</label>
            <Calendar
              id="range"
              v-model="data.purchase_order.shipping_date"
              :readonly="true"
              :manualInput="false"
              :showIcon="true"
              :placeholder="
                i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate.Show
              "
              :showButtonBar="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("PurchaseOrder").PurchaseOrderTable.TotalCommission.Show
            }}</label>
            <InputNumber
              v-model="data.purchase_order.total_commission"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
              readonly="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("Item").ItemTable.ImageURL.Show
            }}</label>
            <div v-if="data.purchase_order.image_url != ''">
              <img :src="data.purchase_order.image_url" style="width: 100%" />
              <Button
                :label="i18n.$t('Item').ItemTable.Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="data.purchase_order.image_url = ''"
              />
            </div>
            <FileUpload
              v-else
              mode="advanced"
              :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
              :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
              :showUploadButton="false"
              @uploader="onEditUpload"
              :multiple="false"
              :customUpload="true"
              :auto="true"
              accept="image/*"
              :maxFileSize="1000000"
            >
              <template #empty>
                <p>{{ i18n.$t("Item").FileLoad.content }}</p>
              </template>
            </FileUpload>
          </div>
        </TabPanel>

        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Collar">
          <OrderCollar />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Fabric">
          <OrderFabric />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Thread">
          <OrderThread />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Smooth">
          <OrderSmooth />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Jmaop">
          <OrderJmaop />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Embroidery">
          <OrderEmbroidery />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Box">
          <OrderBox />
        </TabPanel>
        <TabPanel :header="i18n.$t('PurchaseOrder').TabView.Other">
          <OrderOther />
        </TabPanel>
      </TabView>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Save"
          icon="pi pi-check"
          class="p-button-text"
          v-if="data.purchase_order.status == 1"
          @click="saveEditPurchaseOrder"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Next"
          v-if="data.purchase_order.status == 1"
          icon="pi pi-play"
          class="p-button-text"
          @click="Next"
        />
      </template>
    </Dialog>
    <!-- 詳細完成視窗 -->
    <Dialog
      v-model:visible="data.finishDialog"
      :style="{ width: '800px' }"
      :header="i18n.$t('PurchaseOrder').FinishPurchaseOrder.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.Invoice.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.finish_purchase_order.invoice"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.ShippingCount.Show
        }}</label>
        <InputNumber
          v-model="data.finish_purchase_order.shipping_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrder").PurchaseOrderTable.ShippingDate.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.finish_purchase_order.shipping_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate.Show
          "
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.finishDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveFinishPurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrder').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.purchase_order"
          >{{ i18n.$t("PurchaseOrder").Confirm.Content }}
          <b>{{ data.purchase_order.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrder').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("PurchaseOrder").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 單一取消視窗 -->
    <Dialog
      v-model:visible="data.cancelDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrder').CancelDialog.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.purchase_order"
          >{{ i18n.$t("PurchaseOrder").CancelDialog.Content }}
          <b>{{ data.purchase_order.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.cancelDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveCancelPurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 匯出 -->
    <Dialog
      v-model:visible="data.excel_detail_open"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrder').ExcelDetail"
      :modal="true"
    >
      <div class="p-field">
        <Dropdown
          v-model="data.excel_detailSearch"
          :options="data.selectExcelDetailList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('PurchaseOrder').ExcelDetail"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.excel_detail_open = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrder').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="ExcelDetail"
        />
      </template>
    </Dialog>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-2"
      headerStyle="width: 25em"
      :field="
        i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomizedOrderID.Field
      "
      :header="
        i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomizedOrderID.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerPo.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerPo.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerName.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.CustomerName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrder').PurchaseOrderTable.ProductGroupName.Field
      "
      :header="
        i18n.$t('PurchaseOrder').PurchaseOrderTable.ProductGroupName.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.ItemName.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.ItemName.Show"
    ></Column>
    <Column
      class="p-col-2"
      headerStyle="width: 25em"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.IE.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.IE.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Color.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Color.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.CMTFactoryName.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.CMTFactoryName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderPrice.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderPrice.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderCount.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderCount.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalCount.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalCount.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalPrice.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.OrderTotalPrice.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.AgentName.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.AgentName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.CommissionRate.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.CommissionRate.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.TotalCommission.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.TotalCommission.Show"
    ></Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('PurchaseOrder').PurchaseOrderTable.PredictDeliveryDate.Show
      "
      :field="
        i18n.$t('PurchaseOrder').PurchaseOrderTable.PredictDeliveryDate.Field
      "
    >
    </Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.ShippingDate.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Invoice.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Invoice.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Remark.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Remark.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Description.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Description.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrder').PurchaseOrderTable.Status.Field"
      :header="i18n.$t('PurchaseOrder').PurchaseOrderTable.Status.Show"
    >
    </Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_box.data"
    ref="dt_box"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.ID.Field"
      :header="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.CodeNo.Field"
      :header="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.CodeNo.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Size.Field"
      :header="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Size.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Layer.Field"
      :header="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Layer.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Price.Field"
      :header="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Price.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Count.Field"
      :header="i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.Count.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderBox').PurchaseOrderBoxTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_collar.data"
    ref="dt_collar"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.ID.Field"
      :header="i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.CodeNo.Field
      "
      :header="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.CodeNo.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.Spec.Field
      "
      :header="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.Spec.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.Color.Field
      "
      :header="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.Color.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.TotalCount.Field
      "
      :header="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.TotalCount.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderCollar').PurchaseOrderCollarTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_embroidery.data"
    ref="dt_embroidery"
    responsiveLayout="scroll"
  >
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable.ID.Field
      "
      :header="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable.ID.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable.Price
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable.Price
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable.Count
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable.Count
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable
          .TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderEmbroidery').PurchaseOrderEmbroideryTable
          .TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_fabric.data"
    ref="dt_fabric"
    responsiveLayout="scroll"
  >
    <Column
      headerStyle="width:20em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.ID.Field"
      :header="i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.CodeNo.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.CodeNo.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Color.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Color.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Lose.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Lose.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostNo.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostNo.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostWeight
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostWeight
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Price.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Price.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Weight.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Weight.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_jmaop.data"
    ref="dt_jmaop"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ID.Field"
      :header="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Color.Field"
      :header="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Color.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Price.Field"
      :header="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Price.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Count.Field"
      :header="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Count.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ScreenCharge.Field
      "
      :header="
        i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ScreenCharge.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_smooth.data"
    ref="dt_smooth"
    responsiveLayout="scroll"
  >
    <Column
      headerStyle="width:20em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ID.Field"
      :header="i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintPrice.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintCount.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintCount.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintTotalPrice
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintTotalPrice
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoPrice.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoCount.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoCount.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoTotalPrice
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoTotalPrice
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelPrice.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelCount.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelCount.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelTotalPrice
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelTotalPrice
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ScreenCharge
          .Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ScreenCharge
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_thread.data"
    ref="dt_thread"
    responsiveLayout="scroll"
  >
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.ID.Field"
      :header="i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.CodeNo.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.CodeNo.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Color.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Color.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Lose.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Lose.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Count.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Count.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Unknown.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Unknown.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Price.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Price.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalCount.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalCount.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_other.data"
    ref="dt_other"
    responsiveLayout="scroll"
  >
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.ID.Field"
      :header="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.ID.Show"
    ></Column>
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.Name.Field"
      :header="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.Name.Show"
    ></Column>
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.Count.Field"
      :header="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.Count.Show"
    ></Column>
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.Price.Field"
      :header="i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.Price.Show"
    ></Column>
    <Column
      headerStyle="width: 18em"
      class="p-col-2"
      :field="
        i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.TotalPrice.Field
      "
      :header="
        i18n.$t('PurchaseOrderOther').PurchaseOrderOtherTable.TotalPrice.Show
      "
    ></Column>
  </DataTable>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
