<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Station from "../../api/order/station";

import StationHistory from "./StationHistory.vue";

import StationEmbroidery from "./StationEmbroidery.vue";
import StationJmaop from "./StationJmaop.vue";
import StationSmooth from "./StationSmooth.vue";

import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileUpload";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";
let userApi = new User();

let stationAPI = new Station();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  stationLoading: false,
  totalRecords: 0,
  editDialog: false,
  detailDialog: false,
  stations: { data: null },
  station: {
    purchase_order_id: 0,
    name: "",
    station_status: 0,
  },
  page: 1,
  page_num: 10,
  selected_stations: null,
  stationFilters: {
    name: { value: "", matchMode: "contains" },
    station_status: { value: "", matchMode: "contains" },
    purchase_order_customer_no: { value: "", matchMode: "contains" },
    purchase_order_customized_order_id: { value: "", matchMode: "contains" },
  },
  station_type: "",
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getStationList();
  }
});

async function detailStation(station) {
  store.commit("set_station_id", station.id);
  //   console.log(data.stationFilters.purchase_order_id.value);
  store.commit("set_order_id", station.purchase_order_id);
  //   console.log(station.name.includes("smooth") != -1);
  data.station = station;
  data.station_type = "";
  if (station.name.indexOf("embroidery") != -1) {
    data.station_type = "embroidery";
    store.commit(
      "set_station_search_id",
      await station.name.replace("embroidery_", "")
    );
  }
  if (station.name.indexOf("aop") != -1) {
    data.station_type = "aop";
    store.commit(
      "set_station_search_id",
      await station.name.replace("aop_", "")
    );
  }
  if (station.name.indexOf("smooth") != -1) {
    data.station_type = "smooth";
    store.commit(
      "set_station_search_id",
      await station.name.replace("smooth_", "")
    );
  }

  data.detailDialog = true;
}

function getStationList() {
  CheckUser();
  data.stations.data = [];
  stationAPI
    .getStationList(
      data.stationFilters.name.value,
      data.stationFilters.station_status.value,
      data.stationFilters.purchase_order_customer_no.value,
      data.stationFilters.purchase_order_customized_order_id.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.stations.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.stationLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.stationLoading = false;
      }
    });
}

function saveNextStation(station) {
  stationAPI
    .editStation(station.id, station.purchase_order_id, "0", 3)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Station").EditSuccess,
          life: 3000,
        });

        data.stationLoading = true;
        getStationList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveEditStation() {
  stationAPI
    .editStation(
      data.station.id,
      data.station.purchase_order_id,
      data.station.report_count.toString(),
      data.station.station_statu
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Station").EditSuccess,
          life: 3000,
        });

        data.stationLoading = true;
        getStationList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function editStation(station) {
  data.station = { ...station };
  data.editDialog = true;
}

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.stationLoading = true;
    getStationList();
  }
);

watch(
  () => data.stationFilters.purchase_order_customer_no.value,
  () => {
    data.stationLoading = true;
    getStationList();
  }
);

watch(
  () => data.stationFilters.purchase_order_customized_order_id.value,
  () => {
    data.stationLoading = true;
    getStationList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.stations.data"
      :loading="data.stationLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:selection="data.selected_stations"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Station").StationTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stationFilters[
                        i18n.$t('Station').StationTable
                          .PurchaseOrderCustomizedOrderID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('Station').StationTable
                        .PurchaseOrderCustomizedOrderID.Show
                    "
                  />
                </span>
              </p>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stationFilters[
                        i18n.$t('Station').StationTable.PurchaseOrderCustomerNo
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('Station').StationTable.PurchaseOrderCustomerNo
                        .Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
      <!-- <Column class="p-col-1" selectionMode="multiple"></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('Station').StationTable.ID.Field"
        :header="i18n.$t('Station').StationTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('Station').StationTable.PurchaseOrderCustomizedOrderID.Field
        "
        :header="
          i18n.$t('Station').StationTable.PurchaseOrderCustomizedOrderID.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Station').StationTable.PurchaseOrderCustomerNo.Field"
        :header="i18n.$t('Station').StationTable.PurchaseOrderCustomerNo.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Station').StationTable.Name.Field"
        :header="i18n.$t('Station').StationTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Station').StationTable.ReportTotalCount.Field"
        :header="i18n.$t('Station').StationTable.ReportTotalCount.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Station').StationTable.Status.Field"
        :header="i18n.$t('Station').StationTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.station_status == 1
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .NewPurchaseOrderStatus
              : slotProps.data.station_status == 2
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .LineUpPurchaseOrderStatus
              : i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .CompletePurchaseOrderStatus
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        style="min-width: 140px"
        bodyStyle="white-space:nowrap;overflow:scroll;"
      >
        <template #body="slotProps">
          <Button
            icon="pi pi-search"
            class="p-button-rounded p-button-danger p-mr-2"
            @click="detailStation(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.station_status == 2"
            icon="pi pi-send"
            class="p-button-rounded p-button-info p-mr-2"
            @click="editStation(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.station_status == 2"
            icon="pi pi-check-circle"
            class="p-button-rounded p-button-success p-mr-2"
            @click="saveNextStation(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '800px', minWidth: '350px' }"
      :header="i18n.$t('Station').EditStation.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Station").StationTable.ReportTotalCount.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.station.report_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Station').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Station').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditStation"
        />
        <!-- <Button
          :label="i18n.$t('Station').Next"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveNextStation"
        /> -->
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.detailDialog"
      :style="{ width: '800px', minWidth: '350px' }"
      :header="i18n.$t('Station').detailStation.Title"
      :modal="true"
      class="p-fluid"
    >
      <TabView>
        <TabPanel
          v-if="
            data.station.name != 'sew' &&
            data.station.name != 'cut' &&
            data.station.name != 'pack' &&
            data.station.name != 'ship'
          "
          :header="i18n.$t('Station').TabView.Detail"
        >
          <StationEmbroidery v-if="data.station_type == 'embroidery'" />
          <StationJmaop v-if="data.station_type == 'aop'" />
          <StationSmooth v-if="data.station_type == 'smooth'" />
        </TabPanel>
        <TabPanel :header="i18n.$t('Station').TabView.History">
          <StationHistory />
        </TabPanel>
      </TabView>
      <template #footer>
        <Button
          :label="i18n.$t('Station').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.detailDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
