import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrderEmbroidery {
  getPurchaseOrderEmbroideryInfo(id) {
    return axios.get(
      `/api/order/purchase-order-embroidery/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderEmbroideryList(purchase_order_id, is_use, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-embroidery/list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          is_use: is_use,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderEmbroideryListByID(id, purchase_order_id, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-embroidery/list`,
      {
        params: {
          id: id,
          purchase_order_id: purchase_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderEmbroiderySelectList(purchase_order_id) {
    return axios.get(
      `/api/order/purchase-order-embroidery/select-list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
        },
      },
      config
    );
  }
  getPurchaseOrderEmbroideryExcel(purchase_order_ids) {
    return axios.get(
      `/api/order/purchase-order-embroidery/select-list`,
      {
        params: {
          purchase_order_ids: purchase_order_ids,
          is_use: 1,
        },
      },
      config
    );
  }
  batchPurchaseOrderEmbroidery(datas) {
    return axios.post(
      `/api/order/batch/purchase-order-embroidery`,
      {
        datas: datas,
      },
      config
    );
  }
  addPurchaseOrderEmbroidery(
    is_use_ids,
    not_is_use_ids,
    purchase_order_id,
    price,
    count,
    total_price,
    version,
    employer_id,
    employer_email,
    image,
    is_use
  ) {
    return axios.post(
      `/api/order/purchase-order-embroidery`,
      {
        is_use_ids: is_use_ids,
        not_is_use_ids: not_is_use_ids,
        purchase_order_id: purchase_order_id,
        price: price,
        count: count,
        total_price: total_price,
        version: version,
        employer_id: employer_id,
        employer_email: employer_email,
        image: image,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderEmbroidery(id, is_use) {
    return axios.put(
      `/api/order/purchase-order-embroidery/is-use`,
      {
        id: id,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderEmbroideryContent(
    purchase_order_id,
    id,
    price,
    count,
    total_price,
    image
  ) {
    return axios.put(
      `/api/order/purchase-order-embroidery/`,
      {
        purchase_order_id: purchase_order_id,
        id: id,
        price: price,
        count: count,
        total_price: total_price,
        image: image,
      },
      config
    );
  }
  //   deletePurchaseOrderEmbroidery(id) {
  //     return axios.delete(
  //       `/api/order/purchase-order-embroidery`,
  //       {
  //         data: {
  //           ids: id,
  //         },
  //       },
  //       config
  //     );
  //   }
}
