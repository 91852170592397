import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class CmtFactory {
  getCmtFactoryInfo(id) {
    return axios.get(
      `/api/order/cmt-factory/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getCmtFactoryList(id, name, page, page_num) {
    return axios.get(
      `/api/order/cmt-factory/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getCmtFactorySelectList(name) {
    return axios.get(
      `/api/order/cmt-factory/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchCmtFactory(datas) {
    return axios.post(
      `/api/order/batch/cmt-factory`,
      {
        datas: datas,
      },
      config
    );
  }
  addCmtFactory(name, description) {
    return axios.post(
      `/api/order/cmt-factory`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editCmtFactory(id, name, description) {
    return axios.put(
      `/api/order/cmt-factory`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteCmtFactory(id) {
    return axios.delete(
      `/api/order/cmt-factory`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
