import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrderJmaop {
  getPurchaseOrderJmaopInfo(id) {
    return axios.get(
      `/api/order/purchase-order-jmaop/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderJmaopList(purchase_order_id, color, is_use, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-jmaop/list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          color: color,
          is_use: is_use,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderJmaopListByID(id, purchase_order_id, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-jmaop/list`,
      {
        params: {
          id: id,
          purchase_order_id: purchase_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderJmaopSelectList(purchase_order_id) {
    return axios.get(
      `/api/order/purchase-order-jmaop/select-list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
        },
      },
      config
    );
  }
  getPurchaseOrderJmaopExcel(purchase_order_ids) {
    return axios.get(
      `/api/order/purchase-order-jmaop/select-list`,
      {
        params: {
          purchase_order_ids: purchase_order_ids,
          is_use: 1,
        },
      },
      config
    );
  }
  batchPurchaseOrderJmaop(datas) {
    return axios.post(
      `/api/order/batch/purchase-order-jmaop`,
      {
        datas: datas,
      },
      config
    );
  }
  addPurchaseOrderJmaop(
    is_use_ids,
    not_is_use_ids,
    purchase_order_id,
    count,
    price,
    total_price,
    color,
    screen_charge,
    version,
    employer_id,
    employer_email,
    is_use
  ) {
    return axios.post(
      `/api/order/purchase-order-jmaop`,
      {
        is_use_ids: is_use_ids,
        not_is_use_ids: not_is_use_ids,
        purchase_order_id: purchase_order_id,
        count: count,
        price: price,
        total_price: total_price,
        color: color,
        screen_charge: screen_charge,
        version: version,
        employer_id: employer_id,
        employer_email: employer_email,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderJmaop(id, is_use) {
    return axios.put(
      `/api/order/purchase-order-jmaop/is-use`,
      {
        id: id,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderJmaopContent(
    purchase_order_id,
    id,
    count,
    price,
    total_price,
    color,
    screen_charge
  ) {
    return axios.put(
      `/api/order/purchase-order-jmaop/`,
      {
        purchase_order_id: purchase_order_id,
        id: id,
        count: count,
        price: price,
        total_price: total_price,
        color: color,
        screen_charge: screen_charge,
      },
      config
    );
  }
  //   deletePurchaseOrderJmaop(id) {
  //     return axios.delete(
  //       `/api/order/purchase-order-jmaop`,
  //       {
  //         data: {
  //           ids: id,
  //         },
  //       },
  //       config
  //     );
  //   }
}
