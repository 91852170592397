<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Item from "../../api/order/item";
import FileUpload from "primevue/fileUpload";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let itemAPI = new Item();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  add_item: {
    id: "",
    name: "",
    code_no: "",
    ie: "",
    image: null,
    description: "",
  },
  itemLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  items: { data: null },
  item: null,
  page: 1,
  page_num: 10,
  selectedItems: null,
  itemFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    code_no: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getItemList();
  }
});

function getItemList() {
  CheckUser();
  itemAPI
    .getItemList(
      data.itemFilters.id.value,
      data.itemFilters.code_no.value,
      data.itemFilters.name.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.items.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.itemLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.itemLoading = false;
      }
    });
}

function saveEditItem() {
  itemAPI
    .editItem(
      data.item.id,
      data.item.name,
      data.item.code_no,
      data.item.ie,
      data.item.description
      // data.item.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Item").EditSuccess,
          life: 3000,
        });

        data.itemLoading = true;
        getItemList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddItem() {
  itemAPI
    .addItem(
      data.add_item.name,
      data.add_item.code_no,
      data.add_item.ie,
      data.add_item.description
      // data.add_item.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Item").AddSuccess,
          life: 3000,
        });

        data.itemLoading = true;
        getItemList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addItem() {
  data.addDialog = true;
}
function editItem(item) {
  data.item = { ...item };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(item) {
  data.item = item;
  data.deleteDialog = true;
}
function deleteItem() {
  itemAPI.deleteItem([data.item.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Item").DeleteSuccess,
        life: 3000,
      });

      data.itemrLoading = true;
      getItemList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedItem() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedItems.length; i++) {
    selectDelete.push(data.selectedItems[i].id);
  }
  itemAPI.deleteItem(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Item").DeleteSuccess,
        life: 3000,
      });

      data.itemLoading = true;
      data.selectedItems = [];
      getItemList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function onUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_item.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function onEditUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.item.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.itemLoading = true;
    getItemList();
  }
);
watch(
  () => data.itemFilters.name.value,
  () => {
    data.itemLoading = true;
    getItemList();
  }
);
watch(
  () => data.itemFilters.id.value,
  () => {
    data.itemLoading = true;
    getItemList();
  }
);
watch(
  () => data.itemFilters.code_no.value,
  () => {
    data.itemLoading = true;
    getItemList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.items.data"
      :loading="data.itemLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.itemFilters"
      v-model:selection="data.selectedItems"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Item").ItemTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.itemFilters[i18n.$t('Item').ItemTable.ID.Field].value
                    "
                    :placeholder="i18n.$t('Item').ItemTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.itemFilters[i18n.$t('Item').ItemTable.Name.Field]
                        .value
                    "
                    :placeholder="i18n.$t('Item').ItemTable.Name.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.itemFilters[i18n.$t('Item').ItemTable.CodeNo.Field]
                        .value
                    "
                    :placeholder="i18n.$t('Item').ItemTable.CodeNo.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Item').ItemTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addItem"
            />
            <Button
              :label="i18n.$t('Item').ItemTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="!data.selectedItems || !data.selectedItems.length"
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Item').ItemTable.ID.Field"
        :header="i18n.$t('Item').ItemTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Item').ItemTable.Name.Field"
        :header="i18n.$t('Item').ItemTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2 p-d-none"
        :field="i18n.$t('Item').ItemTable.CodeNo.Field"
        :header="i18n.$t('Item').ItemTable.CodeNo.Show"
      ></Column>
      <Column
        class="p-col-2 p-d-none"
        :field="i18n.$t('Item').ItemTable.IE.Field"
        :header="i18n.$t('Item').ItemTable.IE.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Item').ItemTable.Description.Field"
        :header="i18n.$t('Item').ItemTable.Description.Show"
      ></Column>
      <!-- <Column class="p-col-2" :header="i18n.$t('Item').ItemTable.ImageURL.Show">
        <template #body="slotProps">
          <img class="product-image" :src="slotProps.data.image_url" />
        </template>
      </Column> -->
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Item').AddItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-d-none">
        <label for="name">{{ i18n.$t("Item").ItemTable.CodeNo.Show }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_item.code_no"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.Name.Show }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_item.name"
          required="true"
        />
      </div>
      <div class="p-field p-d-none">
        <label for="name">{{ i18n.$t("Item").ItemTable.IE.Show }}</label>
        <InputText id="name" v-model.trim="data.add_item.ie" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Item").ItemTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_item.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <!-- <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          @uploader="onUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div> -->
      <template #footer>
        <Button
          :label="i18n.$t('Item').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Item').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddItem"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Item').EditItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.CodeNo.Show }}</label>
        <InputText id="name" v-model.trim="data.item.code_no" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.Name.Show }}</label>
        <InputText id="name" v-model.trim="data.item.name" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.IE.Show }}</label>
        <InputText id="name" v-model.trim="data.item.ie" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Item").ItemTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.item.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <!-- <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <div v-if="data.item.image_url != ''">
          <img :src="data.item.image_url" style="width: 100%" />
          <Button
            :label="i18n.$t('Item').ItemTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.item.image_url = ''"
          />
        </div>
        <FileUpload
          v-else
          mode="advanced"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          :showUploadButton="false"
          @uploader="onEditUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div> -->
      <template #footer>
        <Button
          :label="i18n.$t('Item').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Item').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditItem"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Item').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.item"
          >{{ i18n.$t("Item").Confirm.Content }} <b>{{ data.item.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Item').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Item').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteItem"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Item').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Item").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Item').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Item').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
