<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import FaverSaver from "file-saver";
// import XLSX from "xlsx";
// import XLSX from "xlsx";
// import ExcelIO from "@grapecity/spread-excelio";
import { QrcodeStream } from "vue3-qrcode-reader";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Material from "../api/material";
import Stock from "../api/stock";
import QRCodeVue3 from "qrcode-vue3";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/user";
import firebase from "firebase";
import Factory from "../api/factory";
import router from "../router";

let factoryAPI = new Factory();
let userApi = new User();
let materialAPI = new Material();
let stockAPI = new Stock();
const store = useStore();
const printDom = ref(null);
function printHTML(dom) {
  var newWindow = window.open("Print", "_blank");
  var docStr = dom.innerHTML;
  newWindow.document.write(docStr);
  newWindow.document.close();
  newWindow.print();
  newWindow.close();
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  in_date_search: "",
  excelTotal: 0,
  download: false,
  excel: [],
  nowDate: "",
  outData: null,
  iteamSearch: "",
  statusSearch: "",
  qrCode: "",
  stockLoading: false,
  totalRecords: 0,
  stocks: { data: null },
  stock: null,
  // messages: [],
  page: 1,
  page_num: 10,
  stockFilters: {
    id: { value: "", matchMode: "contains" },
    iteam: { value: "", matchMode: "contains" },
    english: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    in_date_begin: { value: "", matchMode: "contains" },
    in_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    // getStockList();
    getIteamSelectList();
    getFactorySelectList();
  }
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function excelStock() {
  CheckUser();
  data.excelTotal = 0;
  data.download = false;
  data.excel = [];
  data.nowDate = "";
  stockAPI
    .getStorageSaveList(
      data.stockFilters.iteam.value,
      data.stockFilters.english.value,
      data.stockFilters.factory_id.value,
      data.stockFilters.in_date_begin.value,
      data.stockFilters.in_date_end.value,
      1,
      10000
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // let dataTotal = res.data.data.total_count;
        // console.log(res.data);
        var Today = new Date();
        let filename =
          Today.getFullYear() +
          "/" +
          (Today.getMonth() + 1) +
          "/" +
          Today.getDate();
        data.nowDate = filename;
        data.excel = res.data.data.datas;
        data.excelTotal = res.data.data.total_count;
        data.download = true;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getIteamSelectList() {
  materialAPI.getMaterialSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectIteamList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getStockList() {
  CheckUser();
  stockAPI
    .getStorageSaveList(
      data.stockFilters.iteam.value,
      data.stockFilters.english.value,
      data.stockFilters.factory_id.value,
      data.stockFilters.in_date_begin.value,
      data.stockFilters.in_date_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.stocks.data = res.data.data.datas;
        data.page = res.data.data.page;
        console.log(res.data);
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.stockLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.stockLoading = false;
      }
    });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
  // this.lazyParams = event;
  // this.loadLazyData();
}

watch(
  () => data.page,
  () => {
    data.stockLoading = true;
    getStockList();
  }
);
watch(
  () => data.stockFilters.english.value,
  () => {
    // data.stockLoading = true;
    // getStockList();
  }
);
// watch(
//   () => data.stockFilters.factory.value,
//   () => {
//     data.stockLoading = true;
//     getStockList();
//   }
// );
watch(
  () => data.factorySearch,
  () => {
    // data.stockLoading = true;
    data.stockFilters.factory_id.value =
      data.factorySearch == null ? "" : data.factorySearch.id;
    // getStockList();
  }
);
watch(
  () => data.iteamSearch,
  () => {
    // data.stockLoading = true;
    data.stockFilters.iteam.value =
      data.iteamSearch == null ? "" : data.iteamSearch.id;
    // getStockList();
  }
);
watch(
  () => data.in_date_search,
  () => {
    // data.stockLoading = true;
    if (data.in_date_search != null) {
      data.stockFilters.in_date_begin.value =
        data.in_date_search[0] != null
          ? data.in_date_search[0].toISOString()
          : "";
      data.stockFilters.in_date_end.value =
        data.in_date_search[1] != null
          ? data.in_date_search[1].toISOString()
          : "";
    } else {
      data.stockFilters.in_date_begin.value = "";
      data.stockFilters.in_date_end.value = "";
    }

    // getStockList();
  }
);

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :value="data.stocks.data"
      :loading="data.stockLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      showGridlines
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.stockFilters"
      v-model:selection="data.selectedStock"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Stock").StockTable.Search }}</span>
              </template>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stockFilters[
                        i18n.$t('Stock').StockTable.English.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Stock').StockTable.English.Show"
                  />
                </span>
                <!-- <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stockFilters[
                        i18n.$t('Stock').StockTable.Factory.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
                  />
                </span> -->
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.factorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.iteamSearch"
                    :options="data.selectIteamList"
                    optionLabel="id"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').AddStock.Iteam"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.id }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.id }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.in_date_search"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="i18n.$t('Stock').StockTable.Date"
                    :showButtonBar="true"
                  />
                </span>
              </div>
              <div class="p-col-12" style="text-align: right">
                <span
                  class="p-component p-button p-button-info"
                  @click="
                    {
                      data.stockLoading = true;
                      getStockList();
                    }
                  "
                  >{{ i18n.$t("Stock").StockTable.SearchClick }}</span
                >
              </div>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Stock').StockTable.Excel"
              icon="pi pi-book"
              class="p-button-success p-mr-2"
              @click="excelStock"
            />
          </div>
        </div>
      </template>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.English.Field"
        :header="i18n.$t('Stock').StockTable.English.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.Factory.Field"
        :header="i18n.$t('Stock').StockTable.Factory.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.Iteam.Field"
        :header="i18n.$t('Stock').StockTable.Iteam.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.Spec.Field"
        :header="i18n.$t('Stock').StockTable.Spec.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.Category.Field"
        :header="i18n.$t('Stock').StockTable.Category.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.WeightTotal.Field"
        :header="i18n.$t('Stock').StockTable.WeightTotal.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.CountTotal.Field"
        :header="i18n.$t('Stock').StockTable.CountTotal.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Stock').StockTable.SaveStock.Field"
        :header="i18n.$t('Stock').StockTable.SaveStock.Show"
      ></Column>
    </DataTable>
    <Dialog
      v-model:visible="data.download"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').ExcelStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="factory">{{ i18n.$t("Stock").ExcelStock.Total }}</label>
        {{ data.excelTotal }}
        <vue3-json-excel
          class="button"
          :header="'Stock'"
          :fields="{
            Name_EN: 'english',
            Factory: 'factory_name',
            ID: 'iteam',
            Category: 'category',
            Spec: 'spec',
            Total_Weight: 'weight',
            Total_Qty: 'count',
            Safety_Stock: 'save_stock',
          }"
          :json-data="data.excel"
          :name="data.nowDate"
        >
          {{ i18n.$t("Stock").ExcelStock.Download }}
        </vue3-json-excel>
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.download = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 8px 14px;
  border: 0px solid #258f3e;
  border-radius: 17px;
  background: #3ce964;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3ce964),
    to(#258f3e)
  );
  background: -moz-linear-gradient(top, #3ce964, #258f3e);
  background: linear-gradient(to bottom, #3ce964, #258f3e);
  -webkit-box-shadow: #37d75c 0px 0px 0px 0px;
  -moz-box-shadow: #37d75c 0px 0px 0px 0px;
  box-shadow: #37d75c 0px 0px 0px 0px;
  text-shadow: #175a27 0px 1px 1px;
  font: normal normal bold 15px arial;
  color: #ffffff;
  text-decoration: none;
}
.button:hover,
.button:focus {
  border: 0px solid #2eb34d;
  background: #48ff78;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#48ff78),
    to(#2cac4a)
  );
  background: -moz-linear-gradient(top, #48ff78, #2cac4a);
  background: linear-gradient(to bottom, #48ff78, #2cac4a);
  color: #ffffff;
  text-decoration: none;
}
.button:active {
  background: #258f3e;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#258f3e),
    to(#258f3e)
  );
  background: -moz-linear-gradient(top, #258f3e, #258f3e);
  background: linear-gradient(to bottom, #258f3e, #258f3e);
}
.button:after {
  content: "\0000a0";
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 -4px -6px 4px;
  position: relative;
  top: 0px;
  left: 0px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABqElEQVRIie3UsWoUYRQF4O+ucQlqkcaQQsRGEBRJkUINYiEhTAKpdix8gV2fQHySjD6Cm06yQTSCWAjBKoiIgnWIIhYhhcj+FpldNqOJk1QWOTDMP/+595x7/mGGE/wDUbdw8V67kVK8gYh0e/Xp436dvrHao/Qb4yJND9fs1mlr1DY4Jk4M/nODLO80slbnwJqs1Wlk+cE8h3wHWd4eC7GOM1iMFDsp0leIFOdT9M8Rq9ilf7fXffLrSAlCgnHMYD1JUwMuRZqyZz6D8b3SIyaAhbw9RTzHdXzCpZL6givYxHyvW2wdywCyVnsyItZxrUK9x9xh4n8YLOSdt+jjTq9bDM80a3UmI7wok8BmSubWVortkd4xvEaj1y1uDvar7+AGplPSHN1cWym2UzKPDWykZH5UHMqe6VJjiKrBDpoRJqpR11aKLdIsaXZvXTmKSBNolhpDVP+mr7CE5SzvPCD92Ddlec/y9r6EkWICy6Xey8MMHuIWloIF4md10r8iNEut73g0Sp0affj84d23y1dnnuECLuIsTte4dtHD/V63+FhrqBMM8Btk+3u+nyYmoAAAAABJRU5ErkJggg==")
    no-repeat left center transparent;
  background-size: 100% 100%;
}
</style>
