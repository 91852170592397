<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PurchaseOrderJmaop from "../../api/order/purchase_order_jmaop";

import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";
let userApi = new User();
let purchase_order_jmaopAPI = new PurchaseOrderJmaop();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  purchase_order_jmaopLoading: false,
  purchase_order_jmaops: { data: null },
  totalRecords: 0,
  editDialog: false,
  detailDialog: false,
  page: 1,
  page_num: 10,
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPurchaseOrderJmaopList();
  }
});

function getPurchaseOrderJmaopList() {
  CheckUser();
  //   console.log(store.state.station_search_id);
  //   console.log(store.state.order_id);
  purchase_order_jmaopAPI
    .getPurchaseOrderJmaopListByID(
      store.state.station_search_id,
      store.state.order_id,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.purchase_order_jmaops.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.purchase_order_jmaopLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.purchase_order_jmaopLoading = false;
      }
    });
}

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.purchase_order_jmaopLoading = true;
    getPurchaseOrderJmaopList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.purchase_order_jmaops.data"
      :loading="data.purchase_order_jmaopLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <Column
        class="p-col-2"
        :field="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ID.Field"
        :header="i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Color.Field
        "
        :header="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Color.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Price.Field
        "
        :header="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Price.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Count.Field
        "
        :header="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.Count.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ScreenCharge
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.ScreenCharge
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.TotalPrice.Field
        "
        :header="
          i18n.$t('PurchaseOrderJmaop').PurchaseOrderJmaopTable.TotalPrice.Show
        "
      ></Column>
    </DataTable>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
