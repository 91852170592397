<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PurchaseOrderSmooth from "../../api/order/purchase_order_smooth";
import FileUpload from "primevue/fileUpload";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let purchase_order_smoothAPI = new PurchaseOrderSmooth();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
const i18n = useI18n();
const toast = useToast();

const data = reactive({
  add_purchase_order_smooth: {
    print_count: 0,
    print_price: 0,
    print_total_price: 0,
    logo_count: 0,
    logo_price: 0,
    logo_total_price: 0,
    label_count: 0,
    label_price: 0,
    label_total_price: 0,
    screen_charge: 0,
    total_price: 0,
    image: null,
  },
  purchase_order_smoothLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  purchase_order_smooths: { data: null },
  purchase_order_smooth: {
    print_count: 0,
    print_price: 0,
    print_total_price: 0,
    logo_count: 0,
    logo_price: 0,
    logo_total_price: 0,
    label_count: 0,
    label_price: 0,
    label_total_price: 0,
    screen_charge: 0,
    total_price: 0,
    image: null,
  },
  page: 1,
  page_num: 10,
  selected_purchase_order_smooths: null,
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPurchaseOrderSmoothList();
  }
});
function onEditUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.purchase_order_smooth.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function onUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_purchase_order_smooth.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function getPurchaseOrderSmoothList() {
  CheckUser();
  purchase_order_smoothAPI
    .getPurchaseOrderSmoothList(
      store.state.order_id,
      true,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.purchase_order_smooths.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.purchase_order_smoothLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.purchase_order_smoothLoading = false;
      }
    });
}

function saveAddPurchaseOrderSmooth() {
  purchase_order_smoothAPI
    .addPurchaseOrderSmooth(
      [],
      [],
      store.state.order_id,
      data.add_purchase_order_smooth.print_count.toString(),
      data.add_purchase_order_smooth.print_price.toString(),
      data.add_purchase_order_smooth.print_total_price.toString(),
      data.add_purchase_order_smooth.logo_count.toString(),
      data.add_purchase_order_smooth.logo_price.toString(),
      data.add_purchase_order_smooth.logo_total_price.toString(),
      data.add_purchase_order_smooth.label_count.toString(),
      data.add_purchase_order_smooth.label_price.toString(),
      data.add_purchase_order_smooth.label_total_price.toString(),
      data.add_purchase_order_smooth.screen_charge.toString(),
      data.add_purchase_order_smooth.total_price.toString(),
      data.add_purchase_order_smooth.version,
      store.state.user.id,
      store.state.user.email,
      data.add_purchase_order_smooth.image,
      true
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderSmooth").AddSuccess,
          life: 3000,
        });

        data.purchase_order_smoothLoading = true;
        getPurchaseOrderSmoothList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addPurchaseOrderSmooth() {
  data.addDialog = true;
  data.add_purchase_order_smooth = {
    print_count: 0,
    print_price: 0,
    print_total_price: 0,
    logo_count: 0,
    logo_price: 0,
    logo_total_price: 0,
    label_count: 0,
    label_price: 0,
    label_total_price: 0,
    screen_charge: 0,
    total_price: 0,
    image: null,
  };
}
function editPurchaseOrderSmooth(purchase_order_smooth) {
  data.purchase_order_smooth = { ...purchase_order_smooth };
  data.editDialog = true;
}

function saveEditPurchaseOrderSmooth() {
  purchase_order_smoothAPI
    .editPurchaseOrderSmoothContent(
      store.state.order_id,
      data.purchase_order_smooth.id,
      data.purchase_order_smooth.print_count.toString(),
      data.purchase_order_smooth.print_price.toString(),
      data.purchase_order_smooth.print_total_price.toString(),
      data.purchase_order_smooth.logo_count.toString(),
      data.purchase_order_smooth.logo_price.toString(),
      data.purchase_order_smooth.logo_total_price.toString(),
      data.purchase_order_smooth.label_count.toString(),
      data.purchase_order_smooth.label_price.toString(),
      data.purchase_order_smooth.label_total_price.toString(),
      data.purchase_order_smooth.screen_charge.toString(),
      data.purchase_order_smooth.total_price.toString(),
      data.purchase_order_smooth.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderSmooth").EditSuccess,
          life: 3000,
        });

        data.purchase_order_smoothLoading = true;
        getPurchaseOrderSmoothList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}

function confirmDelete(purchase_order_smooth) {
  data.purchase_order_smooth = purchase_order_smooth;
  data.deleteDialog = true;
}

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}

function deletePurchaseOrderSmooth() {
  purchase_order_smoothAPI
    .editPurchaseOrderSmooth(data.purchase_order_smooth.id, false)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderSmooth").DeleteSuccess,
          life: 3000,
        });

        data.purchase_order_smoothLoading = true;
        getPurchaseOrderSmoothList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedPurchaseOrderSmooth() {
  let selectDelete = [];
  for (let i = 0; i < data.selected_purchase_order_smooths.length; i++) {
    selectDelete.push(data.selected_purchase_order_smooths[i].id);
  }
  purchase_order_smoothAPI
    .editPurchaseOrderSmooth(store.state.order_id, [], selectDelete)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderSmooth").DeleteSuccess,
          life: 3000,
        });

        data.purchase_order_smoothLoading = true;
        data.selected_purchase_order_smooths = [];
        getPurchaseOrderSmoothList();
        data.deleteSelectedDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    data.purchase_order_smoothLoading = true;
    getPurchaseOrderSmoothList();
  }
);
watch(
  () => data.add_purchase_order_smooth.print_count,
  () => {
    data.add_purchase_order_smooth.print_total_price =
      data.add_purchase_order_smooth.print_price *
      data.add_purchase_order_smooth.print_count;
  }
);
watch(
  () => data.add_purchase_order_smooth.print_price,
  () => {
    data.add_purchase_order_smooth.print_total_price =
      data.add_purchase_order_smooth.print_price *
      data.add_purchase_order_smooth.print_count;
  }
);

watch(
  () => data.add_purchase_order_smooth.logo_count,
  () => {
    data.add_purchase_order_smooth.logo_total_price =
      data.add_purchase_order_smooth.logo_price *
      data.add_purchase_order_smooth.logo_count;
  }
);
watch(
  () => data.add_purchase_order_smooth.logo_price,
  () => {
    data.add_purchase_order_smooth.logo_total_price =
      data.add_purchase_order_smooth.logo_price *
      data.add_purchase_order_smooth.logo_count;
  }
);

watch(
  () => data.add_purchase_order_smooth.label_count,
  () => {
    data.add_purchase_order_smooth.label_total_price =
      data.add_purchase_order_smooth.label_price *
      data.add_purchase_order_smooth.label_count;
  }
);
watch(
  () => data.add_purchase_order_smooth.label_price,
  () => {
    data.add_purchase_order_smooth.label_total_price =
      data.add_purchase_order_smooth.label_price *
      data.add_purchase_order_smooth.label_count;
  }
);

watch(
  () => data.add_purchase_order_smooth.logo_total_price,
  () => {
    data.add_purchase_order_smooth.total_price =
      data.add_purchase_order_smooth.logo_total_price +
      data.add_purchase_order_smooth.print_total_price +
      data.add_purchase_order_smooth.label_total_price +
      data.add_purchase_order_smooth.screen_charge;
  }
);
watch(
  () => data.add_purchase_order_smooth.print_total_price,
  () => {
    data.add_purchase_order_smooth.total_price =
      data.add_purchase_order_smooth.logo_total_price +
      data.add_purchase_order_smooth.print_total_price +
      data.add_purchase_order_smooth.label_total_price +
      data.add_purchase_order_smooth.screen_charge;
  }
);
watch(
  () => data.add_purchase_order_smooth.label_total_price,
  () => {
    data.add_purchase_order_smooth.total_price =
      data.add_purchase_order_smooth.logo_total_price +
      data.add_purchase_order_smooth.print_total_price +
      data.add_purchase_order_smooth.label_total_price +
      data.add_purchase_order_smooth.screen_charge;
  }
);

watch(
  () => data.add_purchase_order_smooth.screen_charge,
  () => {
    data.add_purchase_order_smooth.total_price =
      data.add_purchase_order_smooth.logo_total_price +
      data.add_purchase_order_smooth.print_total_price +
      data.add_purchase_order_smooth.label_total_price +
      data.add_purchase_order_smooth.screen_charge;
  }
);

watch(
  () => data.purchase_order_smooth.print_count,
  () => {
    data.purchase_order_smooth.print_total_price =
      data.purchase_order_smooth.print_price *
      data.purchase_order_smooth.print_count;
  }
);
watch(
  () => data.purchase_order_smooth.print_price,
  () => {
    data.purchase_order_smooth.print_total_price =
      data.purchase_order_smooth.print_price *
      data.purchase_order_smooth.print_count;
  }
);

watch(
  () => data.purchase_order_smooth.logo_count,
  () => {
    data.purchase_order_smooth.logo_total_price =
      data.purchase_order_smooth.logo_price *
      data.purchase_order_smooth.logo_count;
  }
);
watch(
  () => data.purchase_order_smooth.logo_price,
  () => {
    data.purchase_order_smooth.logo_total_price =
      data.purchase_order_smooth.logo_price *
      data.purchase_order_smooth.logo_count;
  }
);

watch(
  () => data.purchase_order_smooth.label_count,
  () => {
    data.purchase_order_smooth.label_total_price =
      data.purchase_order_smooth.label_price *
      data.purchase_order_smooth.label_count;
  }
);
watch(
  () => data.purchase_order_smooth.label_price,
  () => {
    data.purchase_order_smooth.label_total_price =
      data.purchase_order_smooth.label_price *
      data.purchase_order_smooth.label_count;
  }
);

watch(
  () => data.purchase_order_smooth.logo_total_price,
  () => {
    data.purchase_order_smooth.total_price =
      data.purchase_order_smooth.logo_total_price +
      data.purchase_order_smooth.print_total_price +
      data.purchase_order_smooth.label_total_price +
      data.purchase_order_smooth.screen_charge;
  }
);
watch(
  () => data.purchase_order_smooth.print_total_price,
  () => {
    data.purchase_order_smooth.total_price =
      data.purchase_order_smooth.logo_total_price +
      data.purchase_order_smooth.print_total_price +
      data.purchase_order_smooth.label_total_price +
      data.purchase_order_smooth.screen_charge;
  }
);
watch(
  () => data.purchase_order_smooth.label_total_price,
  () => {
    data.purchase_order_smooth.total_price =
      data.purchase_order_smooth.logo_total_price +
      data.purchase_order_smooth.print_total_price +
      data.purchase_order_smooth.label_total_price +
      data.purchase_order_smooth.screen_charge;
  }
);

watch(
  () => data.purchase_order_smooth.screen_charge,
  () => {
    data.purchase_order_smooth.total_price =
      data.purchase_order_smooth.logo_total_price +
      data.purchase_order_smooth.print_total_price +
      data.purchase_order_smooth.label_total_price +
      data.purchase_order_smooth.screen_charge;
  }
);
</script>

<template>
  <Toast />
  <div>
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.purchase_order_smooths.data"
      :loading="data.purchase_order_smoothLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.purchase_order_smoothFilters"
      v-model:selection="data.selected_purchase_order_smooths"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <div
            v-if="store.state.order_status == 1"
            class="p-col-12 p-d-flex p-jc-end"
          >
            <Button
              :label="
                i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.Add
              "
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addPurchaseOrderSmooth"
            />
            <Button
              :label="
                i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.Delete
              "
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selected_purchase_order_smooths ||
                !data.selected_purchase_order_smooths.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        headerStyle="width:20em"
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ID.Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ID.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintPrice
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintCount
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.PrintCount
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable
            .PrintTotalPrice.Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable
            .PrintTotalPrice.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoPrice.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoCount
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoCount.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoTotalPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LogoTotalPrice
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelPrice
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelCount
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.LabelCount
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable
            .LabelTotalPrice.Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable
            .LabelTotalPrice.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ScreenCharge
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.ScreenCharge
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.TotalPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.TotalPrice
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('PurchaseOrderSmooth').PurchaseOrderSmoothTable.Image.Show
        "
      >
        <template #body="slotProps">
          <img class="product-image" :src="slotProps.data.image_url" />
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            v-if="store.state.order_status == 1"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPurchaseOrderSmooth(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            v-if="store.state.order_status == 1"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrderSmooth').AddPurchaseOrderSmooth.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.PrintPrice
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_smooth.print_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.PrintCount
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_smooth.print_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable
            .PrintTotalPrice.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_smooth.print_total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LogoPrice.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_smooth.logo_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LogoCount.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_smooth.logo_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LogoTotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_smooth.logo_total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LabelPrice
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_smooth.label_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LabelCount
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_smooth.label_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable
            .LabelTotalPrice.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_smooth.label_total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.ScreenCharge
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_smooth.screen_charge"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.TotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_smooth.total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          @uploader="onUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddPurchaseOrderSmooth"
        />
      </template>
    </Dialog>
    <!-- 修改視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrderSmooth').EditPurchaseOrderSmooth.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.PrintPrice
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_smooth.print_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.PrintCount
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_smooth.print_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable
            .PrintTotalPrice.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_smooth.print_total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LogoPrice.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_smooth.logo_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LogoCount.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_smooth.logo_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LogoTotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_smooth.logo_total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LabelPrice
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_smooth.label_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.LabelCount
            .Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_smooth.label_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable
            .LabelTotalPrice.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_smooth.label_total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.ScreenCharge
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_smooth.screen_charge"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderSmooth").PurchaseOrderSmoothTable.TotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_smooth.total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <div v-if="data.purchase_order_smooth.image_url != ''">
          <img
            :src="data.purchase_order_smooth.image_url"
            style="width: 100%"
          />
          <Button
            :label="i18n.$t('Item').ItemTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.purchase_order_smooth.image_url = ''"
          />
        </div>
        <FileUpload
          v-else
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          @uploader="onEditUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditPurchaseOrderSmooth"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrderSmooth').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.purchase_order_smooth"
          >{{ i18n.$t("PurchaseOrderSmooth").Confirm.Content }}
          <b>{{ data.purchase_order_smooth.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePurchaseOrderSmooth"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrderSmooth').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("PurchaseOrderSmooth").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderSmooth').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPurchaseOrderSmooth"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
