import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Factory {
  getFactoryInfo(id) {
    return axios.get(
      `/api/factory/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getFactoryList(id, name, remark, page, page_num) {
    return axios.get(
      `/api/factory/list`,
      {
        params: {
          id: id,
          name: name,
          remark: remark,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getFactorySelectList(name) {
    return axios.get(
      `/api/factory/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchFactory(datas) {
    return axios.post(
      `/api/batch/factory`,
      {
        datas: datas,
      },
      config
    );
  }
  addFactory(name, remark) {
    return axios.post(
      `/api/factory`,
      {
        name: name,
        remark: remark,
      },
      config
    );
  }
  editFactory(id, name, remark) {
    return axios.put(
      `/api/factory`,
      {
        id: id,
        name: name,
        remark: remark,
      },
      config
    );
  }
  deleteFactory(id) {
    return axios.delete(
      `/api/factory`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
