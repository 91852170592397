<script setup>
import Dropdown from "primevue/dropdown";
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import PrintPurchase from "../components/PrintPurchase.vue";
import { printHTML } from "../tool/print";
const Purchase = ref(null);
import QRCodeVue3 from "qrcode-vue3";
import * as XLSX from "xlsx/xlsx.mjs";
import Steps from "primevue/steps";
/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
XLSX.set_fs(fs);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);
// import Designer from "../components/Designer.vue";
import axios from "axios";
// import GC from "@grapecity/spread-sheets";

import Calendar from "primevue/calendar";
// import ExcelIO from "@grapecity/spread-excelio";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import WarehousePurchaseOrder from "../api/warehouse_purchase_order";
import WarehouseSupplier from "../api/warehouse_supplier";
import Warehouse from "../api/warehouse";
import WarehousePurchaseOrderContent from "../api/warehouse_purchase_order_content";
import WarehousePurchaseOrderContentHistory from "../api/warehouse_purchase_order_content_history";
import Material from "../api/material";
import Factory from "../api/factory";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/user";
import firebase from "firebase";
import router from "../router";
import africa_logo from "../assets/africa_logo.jpg";
let materialAPI = new Material();
let userApi = new User();
let warehouse_purchase_orderAPI = new WarehousePurchaseOrder();
let warehouse_purchase_order_contentAPI = new WarehousePurchaseOrderContent();
let warehouse_purchase_order_content_historyAPI =
  new WarehousePurchaseOrderContentHistory();

let warehouse_supplierAPI = new WarehouseSupplier();
let warehouseAPI = new Warehouse();

let factoryAPI = new Factory();
const store = useStore();

function readFile(e) {
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 0,
    });
    console.log(json);
    let importExcel = [];
    for (let i = 0; i < json.length; i++) {
      importExcel.push({
        exact_id: json[i].id != undefined ? String(json[i].id) : "",
        name: json[i].name != undefined ? json[i].name : "",
        category: json[i].category != undefined ? json[i].category : "",
        spec: json[i].spec != undefined ? json[i].spec : "",
        description:
          json[i].description != undefined ? json[i].description : "",
        define: json[i].define != undefined ? json[i].define : "",
        save_stock: json[i].save_stock != undefined ? json[i].save_stock : "",
      });
    }

    materialAPI.batchMaterial(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").AddSuccess,
          life: 3000,
        });

        data.materialLoading = true;
        getMaterialList();
        data.addExcelDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

async function saveExcelMaterial() {
  let spread = designer.getWorkbook();
  //   console.log(JSON.stringify(spread.toJSON()));
  let content = spread.toJSON();
  let detail = content.sheets.import.data.dataTable;
  let importExcel = [];
  detail = await Object.values(detail);

  for (let i = 1; i < detail.length; i++) {
    var tmp = Object.values(detail[i]);
    console.log(tmp.length);
    if (tmp.length == 7)
      importExcel.push({
        exact_id: String(tmp[0].value),
        name: String(tmp[1].value),
        category: String(tmp[2].value),
        spec: String(tmp[3].value),
        description: String(tmp[4].value),
        define: String(tmp[5].value),
        save_stock: String(tmp[6].value),
      });
  }

  materialAPI.batchMaterial(importExcel).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").AddSuccess,
        life: 3000,
      });

      data.materialLoading = true;
      getMaterialList();
      data.addExcelDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });

  //   console.log(spread.toJSON());
}

let designer = undefined;

let designerInitialized = (wb) => {
  designer = wb;

  let spread = designer.getWorkbook();
};

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  expandedRows: [],
  items: [
    {
      label: i18n.$t("WarehousePurchaseOrder").Order.Order.Label,
      to: i18n.$t("WarehousePurchaseOrder").Order.Order.To,
    },
    {
      label: i18n.$t("WarehousePurchaseOrder").Order.Content.Label,
      to: i18n.$t("WarehousePurchaseOrder").Order.Content.To,
    },
  ],
  add_warehouse_purchase_order: {
    id: "",
    name: "",
    remark: "",
    description: "",
    warehouse_supplier_id: "",
    factory_id: "",
  },
  warehouse_purchase_orderLoading: false,
  totalRecords: 0,
  addExcelDialog: false,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  warehouse_purchase_orders: { data: null },
  warehouse_purchase_order: null,
  // messages: [],
  page: 1,
  page_num: 10,
  selectedWarehousePurchaseOrders: null,
  warehouse_purchase_orderFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    warehouse_supplier_id: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
    finished_date_begin: { value: "", matchMode: "contains" },
    finished_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getWarehousePurchaseOrderList();
    getFactorySelectList();
    getWarehouseSupplierSelectList();
    getMaterialSelectList();
    store.commit("set_logo", africa_logo);
  }
});

async function PrintPayment(payment, print_application) {
  data.warehouse_purchase_order = payment;
  getWarehousePurchaseOrderContentList(false);
  // warehouse_purchase_order_content
  setTimeout(async () => {
    // for (let i = 0; i < data.payment_contents.length; i++) {
    //   if (
    //     payment.department.indexOf(data.payment_contents[i].department_name) ==
    //     -1
    //   ) {
    //     payment.department.push(data.payment_contents[i].department_name);
    //   }
    // }
    console.log(data.warehouse_purchase_order_content);
    await store.commit("set_payment", payment);
    await store.commit(
      "set_payment_content",
      data.warehouse_purchase_order_content
    );
    printHTML(print_application);
  }, 300);
}
function getWarehouseSelectList() {
  warehouseAPI
    .getWarehouseSelectList("", data.warehouse_purchase_order.factory_id)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectWarehouseList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function getMaterialSelectList() {
  materialAPI.getMaterialSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectMaterialList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getWarehouseSupplierSelectList() {
  warehouse_supplierAPI.getWarehouseSupplierSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectWarehouseSupplierList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getWarehousePurchaseOrderList() {
  CheckUser();
  warehouse_purchase_orderAPI
    .getWarehousePurchaseOrderList(
      data.warehouse_purchase_orderFilters.id.value,
      data.warehouse_purchase_orderFilters.name.value,
      data.warehouse_purchase_orderFilters.status.value,
      data.warehouse_purchase_orderFilters.warehouse_supplier_id.value,
      data.warehouse_purchase_orderFilters.factory_id.value,
      data.warehouse_purchase_orderFilters.created_at_begin.value,
      data.warehouse_purchase_orderFilters.created_at_end.value,
      data.warehouse_purchase_orderFilters.finished_date_begin.value,
      data.warehouse_purchase_orderFilters.finished_date_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.warehouse_purchase_orders.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.warehouse_purchase_orderLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.warehouse_purchase_orderLoading = false;
      }
    });
}
function saveEditWarehousePurchaseOrder() {
  warehouse_purchase_orderAPI
    .editWarehousePurchaseOrder(
      data.warehouse_purchase_order.id,
      data.warehouse_purchase_order.name,
      data.warehouse_purchase_order.remark,
      data.warehouse_purchase_order.description,
      data.warehouse_purchase_order.warehouse_supplier_id,
      data.warehouse_purchase_order.factory_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").EditSuccess,
          life: 3000,
        });

        data.warehouse_purchase_orderLoading = true;
        getWarehousePurchaseOrderList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveAddWarehousePurchaseOrder() {
  warehouse_purchase_orderAPI
    .addWarehousePurchaseOrder(
      data.add_warehouse_purchase_order.name,
      data.add_warehouse_purchase_order.remark,
      data.add_warehouse_purchase_order.description,
      data.add_warehouse_purchase_order.warehouse_supplier_id,
      data.add_warehouse_purchase_order.factory_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").AddSuccess,
          life: 3000,
        });

        data.warehouse_purchase_orderLoading = true;
        getWarehousePurchaseOrderList();
        data.add_warehouse_purchase_order.id = res.data.data.id;
        data.warehouse_purchase_order_content = [];
        router.push("/warehouse-order/content");
        // data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function excelWarehousePurchaseOrder() {
  data.addExcelDialog = true;
}
function addWarehousePurchaseOrder() {
  data.addDialog = true;
  data.factoryAddSearch = "";
  data.warehouse_supplierAddSearch = "";
  data.add_warehouse_purchase_order = {
    id: "",
    name: "",
    remark: "",
    description: "",
    warehouse_supplier_id: 0,
    factory_id: 0,
  };
  data.MaterialAddSearch = "";
  data.add_warehouse_purchase_order_content = {
    material_id: 0,
    remark: "",
    unit_dollar: 0,
    total_count: 0,
    taxed: "",
  };
  router.push("/warehouse-order/order");
}

function editWarehousePurchaseOrderDetail(warehouse_purchase_order) {
  data.warehouse_purchase_order = { ...warehouse_purchase_order };
  data.detailDialog = true;
  getWarehousePurchaseOrderContentList(false);
}

function editWarehousePurchaseOrder(warehouse_purchase_order) {
  CheckUser();
  data.warehouse_purchase_order = { ...warehouse_purchase_order };
  data.editDialog = true;
  data.add_warehouse_purchase_order_content = {
    material_id: 0,
    remark: "",
    unit_dollar: 0,
    total_count: 0,
    taxed: "",
  };
  for (let i = 0; i < data.selectFactoryList.length; i++) {
    if (data.selectFactoryList[i].id == warehouse_purchase_order.factory_id) {
      data.factoryEditSearch = data.selectFactoryList[i];
      break;
    }
  }
  for (let i = 0; i < data.selectWarehouseSupplierList.length; i++) {
    if (
      data.selectWarehouseSupplierList[i].id ==
      warehouse_purchase_order.warehouse_supplier_id
    ) {
      data.warehouse_supplierEditSearch = data.selectWarehouseSupplierList[i];
      break;
    }
  }
  router.push("/warehouse-order/order");
  getWarehousePurchaseOrderContentList(false);
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(warehouse_purchase_order) {
  data.warehouse_purchase_order = warehouse_purchase_order;
  data.deleteDialog = true;
}
function deleteWarehousePurchaseOrder() {
  warehouse_purchase_orderAPI
    .deleteWarehousePurchaseOrder([data.warehouse_purchase_order.id])
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").DeleteSuccess,
          life: 3000,
        });

        data.warehouse_purchase_orderLoading = true;
        getWarehousePurchaseOrderList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedWarehousePurchaseOrder() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedWarehousePurchaseOrders.length; i++) {
    selectDelete.push(data.selectedWarehousePurchaseOrders[i].id);
  }
  warehouse_purchase_orderAPI
    .deleteWarehousePurchaseOrder(selectDelete)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").DeleteSuccess,
          life: 3000,
        });

        data.warehouse_purchase_orderLoading = true;
        data.selectedWarehousePurchaseOrders = [];
        getWarehousePurchaseOrderList();
        data.deleteSelectedDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
  // this.lazyParams = event;
  // this.loadLazyData();
}
function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}
watch(
  () => data.page,
  () => {
    data.warehouse_purchase_orderLoading = true;
    getWarehousePurchaseOrderList();
  }
);
watch(
  () => data.warehouse_purchase_orderFilters.name.value,
  () => {
    data.warehouse_purchase_orderLoading = true;
    getWarehousePurchaseOrderList();
  }
);
watch(
  () => data.warehouse_purchase_orderFilters.id.value,
  () => {
    data.warehouse_purchase_orderLoading = true;
    getWarehousePurchaseOrderList();
  }
);

watch(
  () => data.factoryEditSearch,
  () => {
    data.warehouse_purchase_order.factory_id =
      data.factoryEditSearch == null ? "" : data.factoryEditSearch.id;
  }
);
watch(
  () => data.warehouse_supplierEditSearch,
  () => {
    data.warehouse_purchase_order.warehouse_supplier_id =
      data.warehouse_supplierEditSearch == null
        ? ""
        : data.warehouse_supplierEditSearch.id;
  }
);

watch(
  () => data.factoryAddSearch,
  () => {
    data.add_warehouse_purchase_order.factory_id =
      data.factoryAddSearch == null ? "" : data.factoryAddSearch.id;
  }
);
watch(
  () => data.warehouse_supplierAddSearch,
  () => {
    data.add_warehouse_purchase_order.warehouse_supplier_id =
      data.warehouse_supplierAddSearch == null
        ? ""
        : data.warehouse_supplierAddSearch.id;
  }
);

watch(
  () => data.factorySearch,
  () => {
    data.warehouse_purchase_orderFilters.factory_id.value =
      data.factorySearch == null ? "" : data.factorySearch.id;
    data.warehouse_purchase_orderLoading = true;
    getWarehousePurchaseOrderList();
  }
);
watch(
  () => data.warehouse_supplierSearch,
  () => {
    data.warehouse_purchase_orderFilters.warehouse_supplier_id.value =
      data.warehouse_supplierSearch == null
        ? ""
        : data.warehouse_supplierSearch.id;
    data.warehouse_purchase_orderLoading = true;
    getWarehousePurchaseOrderList();
  }
);

watch(
  () => data.MaterialAddSearch,
  () => {
    data.add_warehouse_purchase_order_content.material_id =
      data.MaterialAddSearch == null ? "" : data.MaterialAddSearch.id;
  }
);
watch(
  () => data.WarehouseAddSearch,
  () => {
    data.history_content.warehouse_id =
      data.WarehouseAddSearch == null ? "" : data.WarehouseAddSearch.id;
  }
);

function SaveDelete(warehouse_purchase_order_content, check) {
  warehouse_purchase_order_contentAPI
    .deleteWarehousePurchaseOrderContent(
      [warehouse_purchase_order_content.id],
      warehouse_purchase_order_content.warehouse_purchase_order_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("WarehousePurchaseOrder").DeleteSuccess,
          life: 3000,
        });
        getWarehousePurchaseOrderContentList(check);
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function getWarehousePurchaseOrderContentList(check) {
  warehouse_purchase_order_contentAPI
    .getWarehousePurchaseOrderContentList(
      check
        ? data.add_warehouse_purchase_order.id
        : data.warehouse_purchase_order.id,
      1,
      999
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.warehouse_purchase_order_content = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function SaveWarehousePurchaseOrderContent(check) {
  warehouse_purchase_order_contentAPI
    .addWarehousePurchaseOrderContent(
      check
        ? data.add_warehouse_purchase_order.id
        : data.warehouse_purchase_order.id,
      String(data.add_warehouse_purchase_order_content.material_id),
      String(data.add_warehouse_purchase_order_content.remark),
      String(data.add_warehouse_purchase_order_content.unit_dollar),
      String(data.add_warehouse_purchase_order_content.total_count),
      String(data.add_warehouse_purchase_order_content.taxed)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.payment_contents=res.data.
        data.TaxedAddSearch = "";
        data.MaterialAddSearch = "";
        data.add_warehouse_purchase_order_content = {
          material_id: 0,
          remark: "",
          unit_dollar: 0,
          total_count: 0,
          taxed: "",
        };
        getWarehousePurchaseOrderContentList(check);
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("WarehousePurchaseOrder").AddSuccess,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveEditWarehousePurchaseOrderContent(content) {
  warehouse_purchase_order_contentAPI
    .editWarehousePurchaseOrderContent(
      data.warehouse_purchase_order.id,
      content.id,
      String(content.material_id),
      String(content.remark),
      String(content.unit_dollar),
      String(content.total_count),
      String(content.taxed)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.payment_contents=res.data.
        getWarehousePurchaseOrderContentList(false);
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("WarehousePurchaseOrder").EditSuccess,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function onRowExpand(event) {
  warehouse_purchase_order_content_historyAPI
    .getWarehousePurchaseOrderContentHistoryList(event.data.id, 1, 999)
    .then((res) => {
      if (res.data.error_code == 0) {
        event.data.history = res.data.data.datas;
        console.log(event.data);
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function editWarehousePurchaseOrderContentStatus(content) {
  data.content = content;
  data.confirmContentDialog = true;
}

function saveEditWarehousePurchaseOrderContentStatus() {
  warehouse_purchase_order_contentAPI
    .editWarehousePurchaseOrderContentStatus(
      data.warehouse_purchase_order.id,
      data.content.id,
      2
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.payment_contents=res.data.
        getWarehousePurchaseOrderContentList(false);
        data.confirmContentDialog = false;
        data.expandedRows = [];
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("WarehousePurchaseOrder").EditSuccess,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function addWarehousePurchaseOrderContentHistory(content) {
  data.historyDialog = true;
  data.content = content;
  data.WarehouseAddSearch = "";
  data.history_content = {
    count: 0,
    unit_dollar: content.unit_dollar,
    remark: "",
    warehouse_id: 0,
    storage_chinese: "",
    storage_weight_unit: "",
    total_count: 0,
  };
  getWarehouseSelectList();
}
function saveAddWarehousePurchaseOrderContentHistory() {
  if (data.history_content.total_count == 0) {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Please fill Count",
      life: 3000,
    });
  } else {
    if (data.history_content.total_count == 1) {
      warehouse_purchase_order_content_historyAPI
        .addWarehousePurchaseOrderContentHistory(
          data.content.id,
          data.history_content.count,
          parseFloat(data.history_content.unit_dollar),
          data.history_content.remark,
          data.history_content.warehouse_id,
          data.history_content.storage_chinese,
          data.history_content.storage_weight_unit
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.expandedRows = [];
            data.print_total_stock = [];
            data.historyDialog = false;
            data.printTotalDialog = true;
            let warehouse_name = "";
            for (let i = 0; i < data.selectWarehouseList.length; i++) {
              if (
                data.selectWarehouseList[i].id ==
                res.data.data.storage_data.warehouse_id
              ) {
                warehouse_name = data.selectWarehouseList[i].name;
              }
            }
            let factory_name = "";
            for (let i = 0; i < data.selectFactoryList.length; i++) {
              if (
                data.selectFactoryList[i].id ==
                res.data.data.storage_data.factory_id
              ) {
                factory_name = data.selectFactoryList[i].name;
              }
            }
            let warehouse_supplier_name = "";
            for (let i = 0; i < data.selectWarehouseSupplierList.length; i++) {
              if (
                data.selectWarehouseSupplierList[i].id ==
                res.data.data.storage_data.warehouse_supplier_id
              ) {
                warehouse_supplier_name =
                  data.selectWarehouseSupplierList[i].name;
              }
            }
            let english_name = "";
            for (let i = 0; i < data.selectMaterialList.length; i++) {
              if (
                data.selectMaterialList[i].id ==
                res.data.data.storage_data.iteam
              ) {
                english_name = data.selectMaterialList[i].name;
              }
            }
            let tmp = [];
            tmp.push({
              id: res.data.data.storage_data.id,
              factory_id: res.data.data.storage_data.factory_id,
              warehouse_id: res.data.data.storage_data.warehouse_id,

              warehouse_supplier_name: warehouse_supplier_name,
              factory_name: factory_name,
              warehouse_name: warehouse_name,

              iteam: res.data.data.storage_data.iteam,
              english: english_name,

              chinese: res.data.data.storage_data.chinese,

              weight: res.data.data.storage_data.weight,
              weight_unit: res.data.data.storage_data.weight_unit,

              count: res.data.data.storage_data.count,
              in_date: res.data.data.storage_data.in_date,
              out_date: res.data.data.storage_data.out_date,
              status: 2,
              remark: res.data.data.storage_data.remark,

              unit_dollar: String(res.data.data.storage_data.unit_dollar),
            });

            //   for (let i = 0; i < res.data.data.storage_data.length; i++) {
            //   tmp.push({
            //     id: res.data.data.datas[i].id,
            //     factory_id: res.data.data.storage_data[i].factory_id,
            //     warehouse_id: res.data.data.storage_data[i].warehouse_id,

            //     factory_name: factory_name,
            //     warehouse_name: warehouse_name,

            //     iteam: res.data.data.datas[i].iteam,

            //     chinese: res.data.data.storage_data[i].chinese,

            //     english: res.data.data.datas[i].english,

            //     weight: res.data.data.storage_data[i].weight,
            //     weight_unit: res.data.data.storage_data[i].weight_unit,

            //     count: res.data.data.storage_data[i].count,
            //     in_date: res.data.data.storage_data[i].in_date,
            //     out_date: res.data.data.storage_data[i].out_date,
            //     status: 2,
            //     remark: res.data.data.storage_data[i].remark,

            //     warehouse_supplier_name: warehouse_supplier_name,

            //     unit_dollar: String(res.data.data.storage_data[i].unit_dollar),
            //   });
            // }
            data.print_total_stock = tmp;
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("WarehousePurchaseOrder").EditSuccess,
              life: 3000,
            });
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
    } else {
      let tmp_data = [];
      for (let i = 0; i < data.history_content.total_count; i++) {
        tmp_data.push({
          warehouse_purchase_order_content_id: data.content.id,
          count: data.history_content.count,
          unit_dollar: parseFloat(data.history_content.unit_dollar),
          remark: data.history_content.remark,
          warehouse_id: data.history_content.warehouse_id,
          storage_chinese: data.history_content.storage_chinese,
          storage_weight_unit: data.history_content.storage_weight_unit,
        });
      }
      warehouse_purchase_order_content_historyAPI
        .batchWarehousePurchaseOrderContentHistory(tmp_data)
        .then((res) => {
          if (res.data.error_code == 0) {
            console.log(res);
            data.expandedRows = [];
            data.print_total_stock = [];
            data.historyDialog = false;
            data.printTotalDialog = true;
            let warehouse_name = "";
            let factory_name = "";
            let warehouse_supplier_name = "";
            let english_name = "";
            if (res.data.data.storage_datas.length != 0) {
              for (let i = 0; i < data.selectWarehouseList.length; i++) {
                if (
                  data.selectWarehouseList[i].id ==
                  res.data.data.storage_datas[0].warehouse_id
                ) {
                  warehouse_name = data.selectWarehouseList[i].name;
                }
              }

              for (let i = 0; i < data.selectFactoryList.length; i++) {
                if (
                  data.selectFactoryList[i].id ==
                  res.data.data.storage_datas[0].factory_id
                ) {
                  factory_name = data.selectFactoryList[i].name;
                }
              }

              for (
                let i = 0;
                i < data.selectWarehouseSupplierList.length;
                i++
              ) {
                if (
                  data.selectWarehouseSupplierList[i].id ==
                  res.data.data.storage_datas[0].warehouse_supplier_id
                ) {
                  warehouse_supplier_name =
                    data.selectWarehouseSupplierList[i].name;
                }
              }

              for (let i = 0; i < data.selectMaterialList.length; i++) {
                if (
                  data.selectMaterialList[i].id ==
                  res.data.data.storage_datas[0].iteam
                ) {
                  english_name = data.selectMaterialList[i].name;
                }
              }
            }

            let tmp = [];
            for (let j = 0; j < res.data.data.storage_datas.length; j++) {
              tmp.push({
                id: res.data.data.storage_datas[j].id,
                factory_id: res.data.data.storage_datas[j].factory_id,
                warehouse_id: res.data.data.storage_datas[j].warehouse_id,

                warehouse_supplier_name: warehouse_supplier_name,
                factory_name: factory_name,
                warehouse_name: warehouse_name,

                iteam: res.data.data.storage_datas[j].iteam,
                english: english_name,

                chinese: res.data.data.storage_datas[j].chinese,

                weight: res.data.data.storage_datas[j].weight,
                weight_unit: res.data.data.storage_datas[j].weight_unit,

                count: res.data.data.storage_datas[j].count,
                in_date: res.data.data.storage_datas[j].in_date,
                out_date: res.data.data.storage_datas[j].out_date,
                status: 2,
                remark: res.data.data.storage_datas[j].remark,

                unit_dollar: String(res.data.data.storage_datas[j].unit_dollar),
              });
            }

            data.print_total_stock = tmp;
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("WarehousePurchaseOrder").EditSuccess,
              life: 3000,
            });
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
    }
  }
}

function EditWarehousePurchaseOrderStatus(order) {
  data.confirmOrderDialog = true;
  data.warehouse_purchase_order = order;
}
function CancelWarehousePurchaseOrderStatus(order) {
  data.cancelOrderDialog = true;
  data.warehouse_purchase_order = order;
}

function saveEditWarehousePurchaseOrderStatus() {
  warehouse_purchase_orderAPI
    .editWarehousePurchaseOrderStatus(
      data.warehouse_purchase_order.id,
      data.cancelOrderDialog ? 4 : data.warehouse_purchase_order.status + 1
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.payment_contents=res.data.
        getWarehousePurchaseOrderList(false);
        data.confirmOrderDialog = false;
        data.cancelOrderDialog = false;
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("WarehousePurchaseOrder").EditSuccess,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
watch(
  () => data.statusSearch,
  () => {
    data.warehouse_purchase_orderLoading = true;
    data.warehouse_purchase_orderFilters.status.value =
      data.statusSearch == null ? "" : data.statusSearch.value;
    getWarehousePurchaseOrderList();
  }
);
watch(
  () => data.in_date_search,
  () => {
    data.warehouse_purchase_orderLoading = true;

    if (data.in_date_search != null) {
      data.warehouse_purchase_orderFilters.created_at_begin.value =
        data.in_date_search[0] != null
          ? data.in_date_search[0].toISOString()
          : "";
      data.warehouse_purchase_orderFilters.created_at_end.value =
        data.in_date_search[1] != null
          ? data.in_date_search[1].toISOString()
          : "";
    } else {
      data.warehouse_purchase_orderFilters.created_at_begin.value = "";
      data.warehouse_purchase_orderFilters.created_at_end.value = "";
    }
    getWarehousePurchaseOrderList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :value="data.warehouse_purchase_orders.data"
      :loading="data.warehouse_purchase_orderLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.warehouse_purchase_orderFilters"
      v-model:selection="data.selectedWarehousePurchaseOrders"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Material").MaterialTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.warehouse_purchase_orderFilters[
                        i18n.$t('WarehousePurchaseOrder')
                          .WarehousePurchaseOrderTable.ID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('WarehousePurchaseOrder')
                        .WarehousePurchaseOrderTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.warehouse_purchase_orderFilters[
                        i18n.$t('WarehousePurchaseOrder')
                          .WarehousePurchaseOrderTable.Name.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('WarehousePurchaseOrder')
                        .WarehousePurchaseOrderTable.Name.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <Dropdown
                    v-model="data.factorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('WarehousePurchaseOrder')
                        .WarehousePurchaseOrderTable.FactoryName.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <Dropdown
                    v-model="data.warehouse_supplierSearch"
                    :options="data.selectWarehouseSupplierList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('WarehousePurchaseOrder')
                        .WarehousePurchaseOrderTable.WarehouseSupplierName.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.statusSearch"
                    :options="[
                      {
                        name: i18n.$t('WarehousePurchaseOrder').Status
                          .NewWarehousePurchaseOrderStatus,
                        value: 1,
                      },
                      {
                        name: i18n.$t('WarehousePurchaseOrder').Status
                          .LineUpWarehousePurchaseOrderStatus,
                        value: 2,
                      },
                      {
                        name: i18n.$t('WarehousePurchaseOrder').Status
                          .CompleteWarehousePurchaseOrderStatu,
                        value: 3,
                      },
                      {
                        name: i18n.$t('WarehousePurchaseOrder').Status
                          .CancelWarehousePurchaseOrderStatus,
                        value: 4,
                      },
                    ]"
                    optionLabel="name"
                    :placeholder="
                      i18n.$t('WarehousePurchaseOrder')
                        .WarehousePurchaseOrderTable.Status.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{ slotProps.value.name }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.in_date_search"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('WarehousePurchaseOrder')
                        .WarehousePurchaseOrderTable.CreatedAt.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <!-- <label class="button-up p-mr-2">
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="readFile"
              />
              <i class="pi pi-plus"></i>
              {{ i18n.$t("Material").MaterialTable.Excel }}
            </label> -->
            <Button
              :label="i18n.$t('Material').MaterialTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addWarehousePurchaseOrder"
            />
            <Button
              :label="i18n.$t('Material').MaterialTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedWarehousePurchaseOrders ||
                !data.selectedWarehousePurchaseOrders.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable.ID.Show
        "
      >
        <template #body="slotProps">
          {{ "S" + slotProps.data.id }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable.Name
            .Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable.Name
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .WarehouseSupplierName.Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .WarehouseSupplierName.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .FactoryName.Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .FactoryName.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .TotalCount.Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .TotalCount.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .TotalDollar.Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .TotalDollar.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable.Remark
            .Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable.Remark
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .Description.Field
        "
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .Description.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable.Status
            .Show
        "
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("WarehousePurchaseOrder").Status
                  .NewWarehousePurchaseOrderStatus
              : slotProps.data.status == 2
              ? i18n.$t("WarehousePurchaseOrder").Status
                  .LineUpWarehousePurchaseOrderStatus
              : slotProps.data.status == 3
              ? i18n.$t("WarehousePurchaseOrder").Status
                  .CompleteWarehousePurchaseOrderStatu
              : slotProps.data.status == 4
              ? i18n.$t("WarehousePurchaseOrder").Status
                  .CancelWarehousePurchaseOrderStatus
              : i18n.$t("WarehousePurchaseOrder").Status.Unknow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .FinishedDate.Show
        "
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.finished_date) }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
            .CreatedAt.Show
        "
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.created_at) }}
        </template>
      </Column>

      <!-- <Column
        class="p-col-6"
        style="overflow: scroll"
        :header="i18n.$t('Supplier').MaterialTable.Description.Show"
        :field="i18n.$t('Supplier').MaterialTable.Description.Field"
      >
      </Column>
       bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
       -->
      <Column class="p-col-2">
        <template #body="slotProps">
          <div style="width: 85px">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-1 p-button-sm"
              @click="editWarehousePurchaseOrder(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.status != 1"
              icon="pi pi-search"
              class="p-button-rounded p-button-warning p-mr-1 p-button-sm"
              @click="editWarehousePurchaseOrderDetail(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.status != 3 && slotProps.data.status != 4"
              icon="pi pi-check-circle"
              class="p-button-rounded p-button-info p-mr-1 p-button-sm"
              @click="EditWarehousePurchaseOrderStatus(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.status != 3 && slotProps.data.status != 4"
              icon="pi pi-ban"
              class="p-button-rounded p-button-danger p-mr-1 p-button-sm"
              @click="CancelWarehousePurchaseOrderStatus(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.status != 3 && slotProps.data.status != 4"
              icon="pi pi-trash"
              class="p-button-rounded p-button-help p-mr-1 p-button-sm"
              @click="confirmDelete(slotProps.data)"
            />
            <Button
              icon="pi pi-print"
              class="p-button-rounded p-button-danger p-mr-1 p-button-sm"
              @click="PrintPayment(slotProps.data, Purchase)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <!-- 匯入 -->
    <Dialog
      v-model:visible="data.addExcelDialog"
      :style="{ width: '850px', minWidth: '350px' }"
      :header="i18n.$t('Material').ExcelMaterial.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="id">{{ i18n.$t("Material").ExcelMaterial.Add }}</label>
        <!-- <Designer v-on:designerInitialized="designerInitialized"></Designer> -->
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addExcelDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Excel"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveExcelMaterial"
        />
      </template>
    </Dialog>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '800px', minWidth: '350px' }"
      :header="
        i18n.$t('WarehousePurchaseOrder').AddWarehousePurchaseOrder.Title
      "
      :modal="true"
      class="p-fluid"
    >
      <Steps :model="data.items" :readonly="true"></Steps>
      <div v-if="router.currentRoute.value.path == '/warehouse-order/content'">
        <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
          <div
            class="p-col-3"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.Material.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.UnitDollar.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.TotalCount.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.Taxed.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.Remark.Show
            }}
          </div>
          <div class="p-col-1"></div>
        </div>
        <div
          style="
            height: calc(100vh - 470px);
            overflow-y: scroll;
            overflow-x: hidden;
          "
        >
          <div
            :key="index"
            v-for="(item, index) in data.warehouse_purchase_order_content"
            class="p-grid p-mt-1"
            style="border-bottom: 1px solid"
          >
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-3"
            >
              {{ item.material_id }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              {{ item.unit_dollar }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              {{ item.total_count }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              {{ item.taxed }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              {{ item.remark }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-1"
            >
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-sm"
                @click="SaveDelete(item, true)"
              />
            </div>
          </div>
        </div>
        <div class="p-grid p-mt-1" style="border-top: 1px solid">
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-3"
          >
            <Dropdown
              :disabled="data.add_warehouse_purchase_order.id == 0"
              v-model="data.MaterialAddSearch"
              :options="data.selectMaterialList"
              :filter="true"
              optionLabel="id"
              :placeholder="
                i18n.$t('WarehousePurchaseOrder')
                  .WarehousePurchaseOrderContentTable.Material.Show
              "
              style="width: 100%"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.id }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.id }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputNumber
              :disabled="data.add_warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.unit_dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputNumber
              :disabled="data.add_warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.total_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputNumber
              :disabled="data.add_warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.taxed"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputText
              :disabled="data.add_warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.remark"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-1"
          >
            <Button
              icon="pi pi-plus-circle"
              class="p-button-rounded p-button-info p-button-sm"
              :disabled="data.add_warehouse_purchase_order.id == 0"
              @click="SaveWarehousePurchaseOrderContent(true)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable.Name
              .Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.add_warehouse_purchase_order.name"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable
              .FactoryName.Show
          }}</label>
          <Dropdown
            v-model="data.factoryAddSearch"
            :options="data.selectFactoryList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
                .FactoryName.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable
              .WarehouseSupplierName.Show
          }}</label>
          <Dropdown
            v-model="data.warehouse_supplierAddSearch"
            :options="data.selectWarehouseSupplierList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
                .WarehouseSupplierName.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable.Remark
              .Show
          }}</label>
          <Textarea
            id="Remark"
            v-model="data.add_warehouse_purchase_order.remark"
            required="true"
            rows="3"
            cols="20"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable
              .Description.Show
          }}</label>
          <Textarea
            id="description"
            v-model="data.add_warehouse_purchase_order.description"
            required="true"
            rows="3"
            cols="20"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <!-- <Button
          v-if="router.currentRoute.value.path == '/warehouse-order/content'"
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddWarehousePurchaseOrderContent"
        /> -->
        <Button
          v-if="router.currentRoute.value.path == '/warehouse-order/order'"
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddWarehousePurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 修改視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '800px' }"
      :header="
        i18n.$t('WarehousePurchaseOrder').EditWarehousePurchaseOrder.Title
      "
      :modal="true"
      class="p-fluid"
    >
      <Steps :model="data.items" :readonly="false"></Steps>
      <div v-if="router.currentRoute.value.path == '/warehouse-order/content'">
        <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
          <div
            class="p-col-3"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.Material.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.UnitDollar.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.TotalCount.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.Taxed.Show
            }}
          </div>
          <div
            class="p-col-2"
            style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
          >
            {{
              i18n.$t("WarehousePurchaseOrder")
                .WarehousePurchaseOrderContentTable.Remark.Show
            }}
          </div>
          <div class="p-col-1"></div>
        </div>
        <div
          style="
            height: calc(100vh - 470px);
            overflow-y: scroll;
            overflow-x: hidden;
          "
        >
          <div
            :key="index"
            v-for="(item, index) in data.warehouse_purchase_order_content"
            class="p-grid p-mt-1"
            style="border-bottom: 1px solid"
          >
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-3"
            >
              {{ item.material_id }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              <InputNumber
                v-model="item.unit_dollar"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              <InputNumber
                v-model="item.total_count"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              <InputNumber
                v-model="item.taxed"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              <InputText
                style="width: 90%"
                v-model="item.remark"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-1"
            >
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-sm p-mr-1"
                @click="SaveDelete(item, false)"
              />
              <Button
                icon="pi pi-check"
                class="p-button-rounded p-button-info p-button-sm"
                @click="saveEditWarehousePurchaseOrderContent(item)"
              />
            </div>
          </div>
        </div>
        <div class="p-grid p-mt-1" style="border-top: 1px solid">
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-3"
          >
            <Dropdown
              :disabled="data.warehouse_purchase_order.id == 0"
              v-model="data.MaterialAddSearch"
              :options="data.selectMaterialList"
              :filter="true"
              optionLabel="id"
              :placeholder="
                i18n.$t('WarehousePurchaseOrder')
                  .WarehousePurchaseOrderContentTable.Material.Show
              "
              style="width: 100%"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.id }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.id }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputNumber
              :disabled="data.warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.unit_dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputNumber
              :disabled="data.warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.total_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputNumber
              :disabled="data.warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.taxed"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <InputText
              :disabled="data.warehouse_purchase_order.id == 0"
              style="width: 100%"
              v-model="data.add_warehouse_purchase_order_content.remark"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-1"
          >
            <Button
              icon="pi pi-plus-circle"
              class="p-button-rounded p-button-info p-button-sm"
              :disabled="data.warehouse_purchase_order.id == 0"
              @click="SaveWarehousePurchaseOrderContent(false)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable.Name
              .Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.warehouse_purchase_order.name"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable
              .FactoryName.Show
          }}</label>
          <Dropdown
            v-model="data.factoryEditSearch"
            :options="data.selectFactoryList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
                .FactoryName.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable
              .WarehouseSupplierName.Show
          }}</label>
          <Dropdown
            v-model="data.warehouse_supplierEditSearch"
            :options="data.selectWarehouseSupplierList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderTable
                .WarehouseSupplierName.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable.Remark
              .Show
          }}</label>
          <Textarea
            id="Remark"
            v-model="data.warehouse_purchase_order.remark"
            required="true"
            rows="3"
            cols="20"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder").WarehousePurchaseOrderTable
              .Description.Show
          }}</label>
          <Textarea
            id="description"
            v-model="data.warehouse_purchase_order.description"
            required="true"
            rows="3"
            cols="20"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          v-if="router.currentRoute.value.path == '/warehouse-order/order'"
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditWarehousePurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.warehouse_purchase_order"
          >{{ i18n.$t("Material").Confirm.Content }}
          <b>{{ data.warehouse_purchase_order.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteWarehousePurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Material").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedWarehousePurchaseOrder"
        />
      </template>
    </Dialog>
    <!-- 內容完成視窗 -->
    <Dialog
      v-model:visible="data.confirmContentDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.content">
          {{ i18n.$t("WarehousePurchaseOrder").Confirm.Content }}?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.confirmContentDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditWarehousePurchaseOrderContentStatus"
        />
      </template>
    </Dialog>
    <!-- 訂單下一步視窗 -->
    <Dialog
      v-model:visible="data.confirmOrderDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('WarehousePurchaseOrder').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.warehouse_purchase_order">
          {{ i18n.$t("WarehousePurchaseOrder").Confirm.Next }}?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.confirmOrderDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditWarehousePurchaseOrderStatus"
        />
      </template>
    </Dialog>
    <!-- 訂單取消視窗 -->
    <Dialog
      v-model:visible="data.cancelOrderDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('WarehousePurchaseOrder').Confirm.CancelTitle"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.warehouse_purchase_order">
          {{ i18n.$t("WarehousePurchaseOrder").Confirm.Cancel }}?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.cancelOrderDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditWarehousePurchaseOrderStatus"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.detailDialog"
      :style="{ width: '600px', minWidth: '350px' }"
      :header="
        i18n.$t('WarehousePurchaseOrder').DetailWarehousePurchaseOrder.Title
      "
      :modal="true"
      class="p-fluid"
    >
      <DataTable
        :lazy="true"
        :value="data.warehouse_purchase_order_content"
        dataKey="id"
        responsiveLayout="scroll"
        :scrollable="true"
        :rows="999"
        v-model:selection="data.selectedWarehousePurchaseOrders"
        v-model:expandedRows="data.expandedRows"
        @rowExpand="onRowExpand"
      >
        <Column :expander="true" headerStyle="width: 1rem" />
        <Column
          class="p-col-1"
          :field="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Material.Field
          "
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Material.Show
          "
        >
        </Column>
        <Column
          class="p-col-1"
          :field="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .UnitDollar.Field
          "
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .UnitDollar.Show
          "
        ></Column>
        <Column
          class="p-col-1"
          :field="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .TotalCount.Field
          "
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .TotalCount.Show
          "
        ></Column>
        <Column
          class="p-col-1"
          :field="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Taxed.Field
          "
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Taxed.Show
          "
        ></Column>
        <Column
          class="p-col-1"
          :field="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .TotalDollar.Field
          "
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .TotalDollar.Show
          "
        ></Column>
        <Column
          class="p-col-1"
          :field="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Remark.Field
          "
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Remark.Show
          "
        ></Column>
        <Column
          class="p-col-1"
          :header="
            i18n.$t('WarehousePurchaseOrder').WarehousePurchaseOrderContentTable
              .Status.Show
          "
          bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        >
          <template #body="slotProps">
            {{
              slotProps.data.status == 1
                ? i18n.$t("WarehousePurchaseOrder").Status
                    .NewWarehousePurchaseOrderStatus
                : slotProps.data.status == 2
                ? i18n.$t("WarehousePurchaseOrder").Status
                    .CompleteWarehousePurchaseOrderStatu
                : i18n.$t("WarehousePurchaseOrder").Status.Unknow
            }}
          </template>
        </Column>
        <!-- bodyStyle="white-space: nowrap; width: 150px; overflow: scroll" -->
        <Column class="p-col-2" bodyStyle="padding: 0">
          <template #body="slotProps">
            <div style="width: 80px">
              <Button
                v-if="
                  slotProps.data.status == 1 &&
                  data.warehouse_purchase_order.status != 3 &&
                  data.warehouse_purchase_order.status != 4
                "
                icon="pi pi-reply"
                class="p-button-rounded p-button-info p-mr-1 p-button-sm"
                @click="addWarehousePurchaseOrderContentHistory(slotProps.data)"
              />
              <Button
                v-if="
                  slotProps.data.status == 1 &&
                  data.warehouse_purchase_order.status != 3 &&
                  data.warehouse_purchase_order.status != 4
                "
                icon="pi pi-check-circle"
                class="p-button-rounded p-button-danger p-button-sm"
                @click="editWarehousePurchaseOrderContentStatus(slotProps.data)"
              />
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="orders-subtable" style="width: 90%; max-width: 600px">
            <DataTable
              :lazy="true"
              :value="slotProps.data.history"
              dataKey="id"
              responsiveLayout="scroll"
              :scrollable="true"
              :rows="999"
            >
              <Column
                class="p-col-1"
                :field="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.WarehouseName
                    .Field
                "
                :header="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.WarehouseName
                    .Show
                "
              ></Column>
              <Column
                class="p-col-1"
                :field="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.Count.Field
                "
                :header="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.Count.Show
                "
              ></Column>
              <Column
                class="p-col-1"
                :field="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.UnitDollar.Field
                "
                :header="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.UnitDollar.Show
                "
              ></Column>
              <Column
                class="p-col-1"
                :field="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.Remark.Field
                "
                :header="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.Remark.Show
                "
              ></Column>
              <Column
                class="p-col-1"
                :header="
                  i18n.$t('WarehousePurchaseOrder')
                    .WarehousePurchaseOrderContentHistoryTable.CreatedAt.Show
                "
              >
                <template #body="slotProps">
                  {{ dateFormate(slotProps.data.created_at) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.detailDialog = false"
        />
      </template>
    </Dialog>
    <!-- 收貨視窗 -->
    <Dialog
      v-model:visible="data.historyDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('WarehousePurchaseOrder').Confirm.InStockTitle"
      :modal="true"
    >
      <div class="confirmation-content">
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder")
              .WarehousePurchaseOrderContentHistoryTable.WarehouseName.Show
          }}</label>
          <br />
          <Dropdown
            v-model="data.WarehouseAddSearch"
            :options="data.selectWarehouseList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('WarehousePurchaseOrder')
                .WarehousePurchaseOrderContentHistoryTable.WarehouseName.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder")
              .WarehousePurchaseOrderContentHistoryTable.Count.Show
          }}</label>
          <InputNumber
            style="width: 100%"
            v-model="data.history_content.count"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder")
              .WarehousePurchaseOrderContentHistoryTable.Total_Count.Show
          }}</label>
          <InputNumber
            style="width: 100%"
            v-model="data.history_content.total_count"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            required="true"
          />
        </div>

        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder")
              .WarehousePurchaseOrderContentHistoryTable.UnitDollar.Show
          }}</label>
          <InputNumber
            style="width: 100%"
            v-model="data.history_content.unit_dollar"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("Stock").StockTable.Chinese.Show
          }}</label>
          <InputText
            style="width: 100%"
            v-model.trim="data.history_content.storage_chinese"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("Stock").StockTable.WeightUnit.Show
          }}</label>
          <InputText
            style="width: 100%"
            v-model.trim="data.history_content.storage_weight_unit"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("WarehousePurchaseOrder")
              .WarehousePurchaseOrderContentHistoryTable.Remark.Show
          }}</label>
          <br />
          <Textarea
            id="description"
            style="width: 100%"
            v-model="data.history_content.remark"
            required="true"
            rows="3"
            cols="40"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.historyDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddWarehousePurchaseOrderContentHistory"
        />
      </template>
    </Dialog>
    <!-- 列印視窗 -->
    <Dialog
      v-model:visible="data.printTotalDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').PrintStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div ref="printDom">
        <div
          class="p-mt-1"
          style="
            width: 400px;
            border: 1px solid;
            border-radius: 20px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
          "
          v-for="(item, index) in data.print_total_stock"
        >
          <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <label for="factory"
              >{{ i18n.$t("Stock").PrintStock.Factory }}:{{
                item.factory_name
              }}</label
            ><br />
            <label for="factory"
              >{{ i18n.$t("Stock").AddStock.Supplier }}:{{
                item.warehouse_supplier_name
              }}</label
            ><br />
            <label for="factory"
              >{{ i18n.$t("Stock").PrintStock.Warehouse }}:{{
                item.warehouse_name
              }}</label
            ><br />
            <label for="iteam"
              >{{ i18n.$t("Stock").PrintStock.Iteam }}:{{ item.iteam }}</label
            ><br />
            <label for="chinese"
              >{{ i18n.$t("Stock").PrintStock.Chinese }}:{{
                item.chinese
              }}</label
            ><br />
            <label for="english"
              >{{ i18n.$t("Stock").PrintStock.English }}:{{
                item.english
              }}</label
            ><br />
            <label for="weight"
              >{{ i18n.$t("Stock").AddStock.Weight }}:{{ item.weight }}</label
            ><br />
            <label for="weight_unit"
              >{{ i18n.$t("Stock").StockTable.WeightUnit.Show }}:{{
                item.weight_unit
              }}</label
            ><br />
            <label for="count"
              >{{ i18n.$t("Stock").AddStock.Count }}:{{ item.count }}</label
            ><br />
            <label for="unit_dollar"
              >{{ i18n.$t("Stock").AddStock.Dollar }}:{{
                item.unit_dollar
              }}</label
            ><br />
            <label for="in_date"
              >{{ i18n.$t("Stock").AddStock.In_Date }}:{{
                item.in_date.substring(0, 10)
              }}</label
            >
          </div>
          <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <QRCodeVue3
              :width="160"
              :height="160"
              :qrOptions="{
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'H',
              }"
              :dotsOptions="{
                type: 'classy',
                color: '#26249a',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#26249a' },
                    { offset: 1, color: '#26249a' },
                  ],
                },
              }"
              :value="
                JSON.stringify({
                  id: item.id,
                })
              "
            />
            JSON:{{ JSON.stringify({ id: item.id }) }}
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.printTotalDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').PrintStock.Print"
          icon="pi pi-check"
          class="p-button-text"
          @click="printHTML(printDom)"
        />
      </template>
    </Dialog>
  </div>
  <div class="p-d-none">
    <div ref="Purchase">
      <PrintPurchase />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
