<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import StationHistory from "../../api/order/station_report_history";

import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";
let userApi = new User();
let station_report_historyAPI = new StationHistory();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  station_report_historyLoading: false,
  station_report_historys: { data: null },
  totalRecords: 0,
  editDialog: false,
  detailDialog: false,
  page: 1,
  page_num: 10,
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getStationHistoryList();
  }
});

function getStationHistoryList() {
  CheckUser();
  station_report_historyAPI
    .getStationHistoryList(store.state.station_id, data.page, data.page_num)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.station_report_historys.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.station_report_historyLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.station_report_historyLoading = false;
      }
    });
}

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.station_report_historyLoading = true;
    getStationHistoryList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.station_report_historys.data"
      :loading="data.station_report_historyLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <!-- <Column class="p-col-1" selectionMode="multiple"></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('StationHistory').StationHistoryTable.ID.Field"
        :header="i18n.$t('StationHistory').StationHistoryTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StationHistory').StationHistoryTable.PurchaseOrderID.Field
        "
        :header="
          i18n.$t('StationHistory').StationHistoryTable.PurchaseOrderID.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('StationHistory').StationHistoryTable.StationID.Field"
        :header="i18n.$t('StationHistory').StationHistoryTable.StationID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('StationHistory').StationHistoryTable.StationName.Field"
        :header="i18n.$t('StationHistory').StationHistoryTable.StationName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('StationHistory').StationHistoryTable.ReportCount.Field"
        :header="i18n.$t('StationHistory').StationHistoryTable.ReportCount.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('StationHistory').StationHistoryTable.CreatedAt.Field"
        :header="i18n.$t('StationHistory').StationHistoryTable.CreatedAt.Show"
        bodyStyle="white-space:nowrap;overflow:scroll;"
      >
        <!-- <template #body="slotProps">
          {{
            dateFormate(slotProps.data.created_at)
          }}
        </template> -->
      </Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StationHistory').StationHistoryTable.EmployerEmail.Field
        "
        :header="
          i18n.$t('StationHistory').StationHistoryTable.EmployerEmail.Show
        "
        bodyStyle="white-space:nowrap;overflow:scroll;"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StationHistory').StationHistoryTable.StationStatus.Field
        "
        :header="
          i18n.$t('StationHistory').StationHistoryTable.StationStatus.Show
        "
      >
        <template #body="slotProps">
          {{
            slotProps.data.station_status == 1
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .NewPurchaseOrderStatus
              : slotProps.data.station_status == 2
              ? i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .LineUpPurchaseOrderStatus
              : i18n.$t("PurchaseOrder").PurchaseOrderTable
                  .CompletePurchaseOrderStatus
          }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
