import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrderFabric {
  getPurchaseOrderFabricInfo(id) {
    return axios.get(
      `/api/order/purchase-order-fabric/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderFabricList(
    purchase_order_id,
    code_no,
    color,
    once_cost_no,
    is_use,
    page,
    page_num
  ) {
    return axios.get(
      `/api/order/purchase-order-fabric/list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          code_no: code_no,
          color: color,
          once_cost_no: once_cost_no,
          is_use: is_use,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderFabricSelectList(purchase_order_id, code_no) {
    return axios.get(
      `/api/order/purchase-order-fabric/select-list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          code_no: code_no,
        },
      },
      config
    );
  }
  getPurchaseOrderFabricExcel(purchase_order_ids) {
    return axios.get(
      `/api/order/purchase-order-fabric/select-list`,
      {
        params: {
          purchase_order_ids: purchase_order_ids,
          is_use: 1,
        },
      },
      config
    );
  }
  batchPurchaseOrderFabric(datas) {
    return axios.post(
      `/api/order/batch/purchase-order-fabric`,
      {
        datas: datas,
      },
      config
    );
  }
  addPurchaseOrderFabric(
    is_use_ids,
    not_is_use_ids,
    purchase_order_id,
    code_no,
    color,
    lose,
    once_cost_no,
    once_cost_weight,
    price,
    weight,
    total_price,
    version,
    employer_id,
    employer_email,
    is_use,
    image
  ) {
    return axios.post(
      `/api/order/purchase-order-fabric`,
      {
        is_use_ids: is_use_ids,
        not_is_use_ids: not_is_use_ids,
        purchase_order_id: purchase_order_id,
        code_no: code_no,
        color: color,
        lose: lose,
        once_cost_no: once_cost_no,
        once_cost_weight: once_cost_weight,
        price: price,
        weight: weight,
        total_price: total_price,
        version: version,
        employer_id: employer_id,
        employer_email: employer_email,
        is_use: is_use,
        image: image,
      },
      config
    );
  }
  editPurchaseOrderFabric(id, is_use) {
    return axios.put(
      `/api/order/purchase-order-fabric/is-use`,
      {
        id: id,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderFabricContent(
    purchase_order_id,
    id,
    code_no,
    color,
    lose,
    once_cost_no,
    once_cost_weight,
    price,
    weight,
    total_price,
    image
  ) {
    return axios.put(
      `/api/order/purchase-order-fabric/`,
      {
        purchase_order_id: purchase_order_id,
        id: id,
        code_no: code_no,
        color: color,
        lose: lose,
        once_cost_no: once_cost_no,
        once_cost_weight: once_cost_weight,
        price: price,
        weight: weight,
        total_price: total_price,
        image: image,
      },
      config
    );
  }
  //   deletePurchaseOrderFabric(id) {
  //     return axios.delete(
  //       `/api/order/purchase-order-fabric`,
  //       {
  //         data: {
  //           ids: id,
  //         },
  //       },
  //       config
  //     );
  //   }
}
