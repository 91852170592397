import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class WarehousePurchaseOrderContent {
  getWarehousePurchaseOrderContentList(
    warehouse_purchase_order_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/warehouse-product-order-content/list`,
      {
        params: {
          warehouse_purchase_order_id: warehouse_purchase_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchWarehousePurchaseOrderContent(datas) {
    return axios.post(
      `/api/warehouse-product-order-content/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addWarehousePurchaseOrderContent(
    warehouse_purchase_order_id,
    material_id,
    remark,
    unit_dollar,
    total_count,
    taxed
  ) {
    return axios.post(
      `/api/warehouse-product-order-content`,
      {
        warehouse_purchase_order_id: warehouse_purchase_order_id,
        material_id: material_id,
        remark: remark,
        unit_dollar: unit_dollar,
        total_count: total_count,
        taxed: taxed,
      },
      config
    );
  }
  editWarehousePurchaseOrderContent(
    warehouse_purchase_order_id,
    id,
    material_id,
    remark,
    unit_dollar,
    total_count,
    taxed
  ) {
    return axios.put(
      `/api/warehouse-product-order-content`,
      {
        id: id,
        warehouse_purchase_order_id: warehouse_purchase_order_id,
        material_id: material_id,
        remark: remark,
        unit_dollar: unit_dollar,
        total_count: total_count,
        taxed: taxed,
      },
      config
    );
  }
  editWarehousePurchaseOrderContentStatus(
    warehouse_purchase_order_id,
    id,
    status
  ) {
    return axios.put(
      `/api/warehouse-product-order-content`,
      {
        id: id,
        warehouse_purchase_order_id: warehouse_purchase_order_id,
        status: status,
      },
      config
    );
  }
  deleteWarehousePurchaseOrderContent(ids, warehouse_purchase_order_id) {
    return axios.delete(
      `/api/warehouse-product-order-content`,
      {
        data: {
          ids: ids,
          warehouse_purchase_order_id: warehouse_purchase_order_id,
        },
      },
      config
    );
  }
}
