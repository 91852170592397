import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Material {
  getMaterialInfo(exact_id) {
    return axios.get(
      `/api/material/`,
      {
        params: {
          exact_id: exact_id,
        },
      },
      config
    );
  }
  getMaterialList(id, name, category, spec, define, page, page_num) {
    return axios.get(
      `/api/material/list`,
      {
        params: {
          id: id,
          name: name,
          category: category,
          spec: spec,
          define: define,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getMaterialSelectList(name) {
    return axios.get(
      `/api/material/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchMaterial(datas) {
    return axios.post(
      `/api/batch/material`,
      {
        datas: datas,
      },
      config
    );
  }
  addMaterial(id, name, category, spec, description, define, save_stock) {
    return axios.post(
      `/api/material`,
      {
        exact_id: id,
        name: name,
        category: category,
        spec: spec,
        description: description,
        define: define,
        save_stock: save_stock,
      },
      config
    );
  }
  editMaterial(id, name, category, spec, description, define, save_stock) {
    return axios.put(
      `/api/material`,
      {
        exact_id: id,
        name: name,
        category: category,
        spec: spec,
        description: description,
        define: define,
        save_stock: save_stock,
      },
      config
    );
  }
  deleteMaterial(id) {
    return axios.delete(
      `/api/material`,
      {
        data: {
          delete_ids: id,
        },
      },
      config
    );
  }
}
