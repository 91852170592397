import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class StationHistory {
  getStationHistoryList(station_id, page, page_num) {
    return axios.get(
      `/api/order/station-report-history/list`,
      {
        params: {
          station_id: station_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
