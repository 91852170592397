<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PurchaseOrderThread from "../../api/order/purchase_order_thread";

import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let purchase_order_threadAPI = new PurchaseOrderThread();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
const i18n = useI18n();
const toast = useToast();

const data = reactive({
  add_purchase_order_thread: {
    code_no: "",
    color: "",
    lose: 0,
    count: 0,
    uc: 0,
    price: 0,
    total_count: 0,
    total_price: 0,
    version: 0,
  },
  purchase_order_threadLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  purchase_order_threads: { data: null },
  purchase_order_thread: {
    code_no: "",
    color: "",
    lose: 0,
    count: 0,
    uc: 0,
    price: 0,
    total_count: 0,
    total_price: 0,
    version: 0,
  },
  page: 1,
  page_num: 10,
  selected_purchase_order_threads: null,
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPurchaseOrderThreadList();
  }
});

function getPurchaseOrderThreadList() {
  CheckUser();
  purchase_order_threadAPI
    .getPurchaseOrderThreadList(
      store.state.order_id,
      "",
      "",
      true,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.purchase_order_threads.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.purchase_order_threadLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.purchase_order_threadLoading = false;
      }
    });
}

function saveAddPurchaseOrderThread() {
  purchase_order_threadAPI
    .addPurchaseOrderThread(
      [],
      [],
      store.state.order_id,
      data.add_purchase_order_thread.code_no,
      data.add_purchase_order_thread.color,
      data.add_purchase_order_thread.count.toString(),
      data.add_purchase_order_thread.lose.toString(),
      data.add_purchase_order_thread.uc.toString(),
      data.add_purchase_order_thread.price.toString(),
      data.add_purchase_order_thread.total_count.toString(),
      data.add_purchase_order_thread.total_price.toString(),
      data.add_purchase_order_thread.version,
      store.state.user.id,
      store.state.user.email,
      true
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderThread").AddSuccess,
          life: 3000,
        });

        data.purchase_order_threadLoading = true;
        getPurchaseOrderThreadList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addPurchaseOrderThread() {
  data.addDialog = true;
  data.add_purchase_order_thread = {
    code_no: "",
    color: "",
    lose: 0,
    count: 0,
    uc: 0,
    price: 0,
    total_count: 0,
    total_price: 0,
    version: 0,
  };
}
function editPurchaseOrderThread(purchase_order_thread) {
  data.purchase_order_thread = { ...purchase_order_thread };
  data.editDialog = true;
}

function saveEditPurchaseOrderThread() {
  purchase_order_threadAPI
    .editPurchaseOrderThreadContent(
      store.state.order_id,
      data.purchase_order_thread.id,
      data.purchase_order_thread.code_no,
      data.purchase_order_thread.color,
      data.purchase_order_thread.count.toString(),
      data.purchase_order_thread.lose.toString(),
      data.purchase_order_thread.uc.toString(),
      data.purchase_order_thread.price.toString(),
      data.purchase_order_thread.total_count.toString(),
      data.purchase_order_thread.total_price.toString()
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderThread").EditSuccess,
          life: 3000,
        });

        data.purchase_order_threadLoading = true;
        getPurchaseOrderThreadList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}

function confirmDelete(purchase_order_thread) {
  data.purchase_order_thread = purchase_order_thread;
  data.deleteDialog = true;
}

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}

function deletePurchaseOrderThread() {
  purchase_order_threadAPI
    .editPurchaseOrderThread(data.purchase_order_thread.id, false)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderThread").DeleteSuccess,
          life: 3000,
        });

        data.purchase_order_threadLoading = true;
        getPurchaseOrderThreadList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedPurchaseOrderThread() {
  let selectDelete = [];
  for (let i = 0; i < data.selected_purchase_order_threads.length; i++) {
    selectDelete.push(data.selected_purchase_order_threads[i].id);
  }
  purchase_order_threadAPI
    .editPurchaseOrderThread(store.state.order_id, [], selectDelete)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderThread").DeleteSuccess,
          life: 3000,
        });

        data.purchase_order_threadLoading = true;
        data.selected_purchase_order_threads = [];
        getPurchaseOrderThreadList();
        data.deleteSelectedDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    data.purchase_order_threadLoading = true;
    getPurchaseOrderThreadList();
  }
);
watch(
  () => data.add_purchase_order_thread.price,
  () => {
    data.add_purchase_order_thread.total_price =
      data.add_purchase_order_thread.price *
      data.add_purchase_order_thread.total_count;
  }
);
// watch(
//   () => data.add_purchase_order_thread.total_count,
//   () => {
//     data.add_purchase_order_thread.total_price =
//       data.add_purchase_order_thread.price *
//       data.add_purchase_order_thread.total_count;
//   }
// );
// 總數＝損耗＊ＵＣ＊數量
watch(
  () => data.add_purchase_order_thread.uc,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.add_purchase_order_thread.total_count =
      data.add_purchase_order_thread.count *
      data.add_purchase_order_thread.lose *
      data.add_purchase_order_thread.uc;

    data.add_purchase_order_thread.total_price =
      data.add_purchase_order_thread.price *
      (data.add_purchase_order_thread.count *
        data.add_purchase_order_thread.lose *
        data.add_purchase_order_thread.uc);
  }
);
watch(
  () => data.add_purchase_order_thread.count,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.add_purchase_order_thread.total_count =
      data.add_purchase_order_thread.count *
      data.add_purchase_order_thread.lose *
      data.add_purchase_order_thread.uc;

    data.add_purchase_order_thread.total_price =
      data.add_purchase_order_thread.price *
      (data.add_purchase_order_thread.count *
        data.add_purchase_order_thread.lose *
        data.add_purchase_order_thread.uc);
  }
);
watch(
  () => data.add_purchase_order_thread.lose,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.add_purchase_order_thread.total_count =
      data.add_purchase_order_thread.count +
      data.add_purchase_order_thread.lose * data.add_purchase_order_thread.uc;

    data.add_purchase_order_thread.total_price =
      data.add_purchase_order_thread.price *
      (data.add_purchase_order_thread.count *
        data.add_purchase_order_thread.lose *
        data.add_purchase_order_thread.uc);
  }
);

watch(
  () => data.purchase_order_thread.price,
  () => {
    data.purchase_order_thread.total_price =
      data.purchase_order_thread.price * data.purchase_order_thread.total_count;
  }
);
// 總數＝損耗＊ＵＣ＊數量
watch(
  () => data.purchase_order_thread.uc,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.purchase_order_thread.total_count =
      data.purchase_order_thread.count *
      data.purchase_order_thread.lose *
      data.purchase_order_thread.uc;

    data.purchase_order_thread.total_price =
      data.purchase_order_thread.price *
      (data.purchase_order_thread.count *
        data.purchase_order_thread.lose *
        data.purchase_order_thread.uc);
  }
);
watch(
  () => data.purchase_order_thread.count,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.purchase_order_thread.total_count =
      data.purchase_order_thread.count *
      data.purchase_order_thread.lose *
      data.purchase_order_thread.uc;

    data.purchase_order_thread.total_price =
      data.purchase_order_thread.price *
      (data.purchase_order_thread.count *
        data.purchase_order_thread.lose *
        data.purchase_order_thread.uc);
  }
);
watch(
  () => data.purchase_order_thread.lose,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.purchase_order_thread.total_count =
      data.purchase_order_thread.count +
      data.purchase_order_thread.lose * data.purchase_order_thread.uc;

    data.purchase_order_thread.total_price =
      data.purchase_order_thread.price *
      (data.purchase_order_thread.count *
        data.purchase_order_thread.lose *
        data.purchase_order_thread.uc);
  }
);
</script>

<template>
  <Toast />
  <div>
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.purchase_order_threads.data"
      :loading="data.purchase_order_threadLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.purchase_order_threadFilters"
      v-model:selection="data.selected_purchase_order_threads"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <div
            v-if="store.state.order_status == 1"
            class="p-col-12 p-d-flex p-jc-end"
          >
            <Button
              :label="
                i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Add
              "
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addPurchaseOrderThread"
            />
            <Button
              :label="
                i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Delete
              "
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selected_purchase_order_threads ||
                !data.selected_purchase_order_threads.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        headerStyle="width: 18em"
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.ID.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.ID.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.CodeNo.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.CodeNo.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Color.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Color.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Lose.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Lose.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Count.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Count.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Unknown.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Unknown.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Price.Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.Price.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalCount
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalCount
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderThread').PurchaseOrderThreadTable.TotalPrice
            .Show
        "
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            v-if="store.state.order_status == 1"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPurchaseOrderThread(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            v-if="store.state.order_status == 1"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrderThread').AddPurchaseOrderThread.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.CodeNo.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order_thread.code_no"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Color.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order_thread.color"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Lose.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_thread.lose"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Unknown.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_purchase_order_thread.uc"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Count.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_thread.count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Price.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_thread.price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.TotalCount
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_thread.total_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly="true"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.TotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_thread.total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderThread').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderThread').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddPurchaseOrderThread"
        />
      </template>
    </Dialog>
    <!-- 修改視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrderThread').EditPurchaseOrderThread.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.CodeNo.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.purchase_order_thread.code_no"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Color.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.purchase_order_thread.color"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Lose.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_thread.lose"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Unknown.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.purchase_order_thread.uc"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Count.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_thread.count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.Price.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_thread.price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.TotalCount
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_thread.total_count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly="true"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderThread").PurchaseOrderThreadTable.TotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_thread.total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly
          required="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderThread').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderThread').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditPurchaseOrderThread"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrderThread').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.purchase_order_thread"
          >{{ i18n.$t("PurchaseOrderThread").Confirm.Content }}
          <b>{{ data.purchase_order_thread.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderThread').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderThread').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePurchaseOrderThread"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrderThread').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("PurchaseOrderThread").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderThread').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderThread').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPurchaseOrderThread"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
