import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Customer {
  getCustomerInfo(id) {
    return axios.get(
      `/api/order/customer/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getCustomerList(id, name, page, page_num) {
    return axios.get(
      `/api/order/customer/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getCustomerSelectList(name) {
    return axios.get(
      `/api/order/customer/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchCustomer(datas) {
    return axios.post(
      `/api/order/batch/customer`,
      {
        datas: datas,
      },
      config
    );
  }
  addCustomer(
    name,
    contact_person,
    term,
    credit_limit,
    new_product_group_ids,
    delete_product_group_ids
  ) {
    return axios.post(
      `/api/order/customer`,
      {
        name: name,
        contact_person: contact_person,
        term: term,
        credit_limit: credit_limit,
        new_product_group_ids: new_product_group_ids,
        delete_product_group_ids: delete_product_group_ids,
      },
      config
    );
  }
  editCustomer(
    id,
    name,
    contact_person,
    term,
    credit_limit,
    new_product_group_ids,
    delete_product_group_ids
  ) {
    return axios.put(
      `/api/order/customer`,
      {
        id: id,
        name: name,
        contact_person: contact_person,
        term: term,
        credit_limit: credit_limit,
        new_product_group_ids: new_product_group_ids,
        delete_product_group_ids: delete_product_group_ids,
      },
      config
    );
  }
  deleteCustomer(id) {
    return axios.delete(
      `/api/order/customer`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
