<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import FaverSaver from "file-saver";
// import XLSX from "xlsx";
// import XLSX from "xlsx";
// import ExcelIO from "@grapecity/spread-excelio";
import { QrcodeStream } from "vue3-qrcode-reader";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Material from "../api/material";
import Stock from "../api/stock";
import QRCodeVue3 from "qrcode-vue3";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/user";
import Factory from "../api/factory";
import Warehouse from "../api/warehouse";
import Transfer from "../api/transfer";
import firebase from "firebase";
import router from "../router";
let factoryAPI = new Factory();
let warehouseAPI = new Warehouse();
let TransferAPI = new Transfer();

let userApi = new User();

const store = useStore();
const printDom = ref(null);
function printHTML(dom) {
  var newWindow = window.open("Print", "_blank");
  var docStr = dom.innerHTML;
  newWindow.document.write(docStr);
  newWindow.document.close();
  newWindow.print();
  newWindow.close();
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  warehouseSearch: "",
  factorySearch: "",
  in_date_search: "",
  excelTotal: 0,
  download: false,
  excel: [],
  nowDate: "",
  outData: null,
  iteamSearch: "",
  statusSearch: "",
  qrCode: "",
  transferLoading: false,
  totalRecords: 0,
  transfers: { data: null },
  transfer: null,
  // messages: [],
  page: 1,
  page_num: 10,
  selectFactoryList: [],
  selectWarehouseList: [],
  transferFilters: {
    source_storage_id: { value: "", matchMode: "contains" },
    source_factory_id: { value: "", matchMode: "contains" },
    target_factory_id: { value: "", matchMode: "contains" },
    source_warehouse_id: { value: "", matchMode: "contains" },
    target_warehouse_id: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    transfer_date_begin: { value: "", matchMode: "contains" },
    transfer_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getTransferList();
    getFactorySelectList();
    getWarehouseSelectList();
  }
});

function getWarehouseSelectList() {
  let factory =
    data.transferFilters.source_factory_id.value == ""
      ? 0
      : data.transferFilters.source_factory_id.value;

  warehouseAPI.getWarehouseSelectList("", factory).then((res) => {
    if (res.data.error_code == 0) {
      data.selectWarehouseList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function excelStock() {
  CheckUser();
  data.excelTotal = 0;
  data.download = false;
  data.excel = [];
  data.nowDate = "";
  TransferAPI.getTransferList(
    data.transferFilters.source_storage_id.value,
    data.transferFilters.source_factory_id.value,
    data.transferFilters.target_factory_id.value,
    data.transferFilters.source_warehouse_id.value,
    data.transferFilters.target_warehouse_id.value,
    data.transferFilters.status.value,
    data.transferFilters.transfer_date_begin.value,
    data.transferFilters.transfer_date_end.value,
    1,
    10000
  ).then((res) => {
    if (res.data.error_code == 0) {
      // let dataTotal = res.data.data.total_count;
      // console.log(res.data);
      var Today = new Date();
      let filename =
        Today.getFullYear() +
        "/" +
        (Today.getMonth() + 1) +
        "/" +
        Today.getDate();
      data.nowDate = filename;
      data.excel = res.data.data.datas;
      data.excelTotal = res.data.data.total_count;
      data.download = true;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getTransferList() {
  CheckUser();

  TransferAPI.getTransferList(
    data.transferFilters.source_storage_id.value,
    data.transferFilters.source_factory_id.value,
    data.transferFilters.target_factory_id.value,
    data.transferFilters.source_warehouse_id.value,
    data.transferFilters.target_warehouse_id.value,
    data.transferFilters.status.value,
    data.transferFilters.transfer_date_begin.value,
    data.transferFilters.transfer_date_end.value,

    data.page,
    data.page_num
  ).then((res) => {
    if (res.data.error_code == 0) {
      data.totalRecords = res.data.data.total_count;
      data.transfers.data = res.data.data.datas;
      data.page = res.data.data.page;

      data.transferLoading = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });

      data.totalRecords = 0;
      data.transferLoading = false;
    }
  });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
  // this.lazyParams = event;
  // this.loadLazyData();
}

watch(
  () => data.page,
  () => {
    data.transferLoading = true;
    getTransferList();
  }
);
watch(
  () => data.transferFilters.source_storage_id.value,
  () => {
    data.transferLoading = true;
    getTransferList();
  }
);

watch(
  () => data.factorySearch,
  () => {
    data.transferLoading = true;
    data.transferFilters.source_factory_id.value =
      data.factorySearch == null ? "" : data.factorySearch.id;
    getTransferList();
    getWarehouseSelectList();
  }
);
watch(
  () => data.warehouseSearch,
  () => {
    data.transferLoading = true;
    data.transferFilters.source_warehouse_id.value =
      data.warehouseSearch == null ? "" : data.warehouseSearch.id;
    getTransferList();
  }
);
watch(
  () => data.statusSearch,
  () => {
    data.transferLoading = true;
    data.transferFilters.status.value =
      data.statusSearch == null ? "" : data.statusSearch.value;
    getTransferList();
  }
);
watch(
  () => data.in_date_search,
  () => {
    data.transferLoading = true;
    if (data.in_date_search != null) {
      data.transferFilters.transfer_date_begin.value =
        data.in_date_search[0] != null
          ? data.in_date_search[0].toISOString()
          : "";
      data.transferFilters.transfer_date_end.value =
        data.in_date_search[1] != null
          ? data.in_date_search[1].toISOString()
          : "";
    } else {
      data.transferFilters.transfer_date_begin.value = "";
      data.transferFilters.transfer_date_end.value = "";
    }

    getTransferList();
  }
);

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      showGridlines
      :value="data.transfers.data"
      :loading="data.transferLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.transferFilters"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Stock").StockTable.Search }}</span>
              </template>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.transferFilters[
                        i18n.$t('Transfer').TransferTable.StockID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('Transfer').TransferTable.StockID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.factorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.warehouseSearch"
                    :options="data.selectWarehouseList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').StockTable.Warehouse.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.statusSearch"
                    :options="[
                      {
                        name: i18n.$t('Transfer').Transfer,
                        value: 1,
                      },
                      {
                        name: i18n.$t('Transfer').Sell,
                        value: 2,
                      },
                    ]"
                    optionLabel="name"
                    :placeholder="i18n.$t('Stock').StockTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{
                            slotProps.value.value == 1
                              ? i18n.$t("Transfer").Transfer
                              : i18n.$t("Transfer").Sell
                          }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.in_date_search"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="i18n.$t('Stock').StockTable.Date"
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Stock').StockTable.Excel"
              icon="pi pi-book"
              class="p-button-success p-mr-2"
              @click="excelStock"
            />
          </div>
        </div>
      </template>
      <Column
        class="p-col-2 p-d-none"
        :field="i18n.$t('Transfer').TransferTable.ID.Field"
        :header="i18n.$t('Transfer').TransferTable.ID.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.StockID.Field"
        :header="i18n.$t('Transfer').TransferTable.StockID.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.StockName.Field"
        :header="i18n.$t('Transfer').TransferTable.StockName.Show"
      ></Column>
      <!-- <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TargetStorageID.Field"
        :header="i18n.$t('Transfer').TransferTable.TargetStorageID.Show"
      ></Column> -->
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TargetStorageName.Field"
        :header="i18n.$t('Transfer').TransferTable.TargetStorageName.Show"
      ></Column>
      <!-- <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.SourceFactoryID.Field"
        :header="i18n.$t('Transfer').TransferTable.SourceFactoryID.Show"
      ></Column> -->
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.SourceFactoryName.Field"
        :header="i18n.$t('Transfer').TransferTable.SourceFactoryName.Show"
      ></Column>

      <!-- <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TargetFactoryID.Field"
        :header="i18n.$t('Transfer').TransferTable.TargetFactoryID.Show"
      ></Column> -->
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TargetFactoryName.Field"
        :header="i18n.$t('Transfer').TransferTable.TargetFactoryName.Show"
      ></Column>
      <!-- <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.SourceWarehouseID.Field"
        :header="i18n.$t('Transfer').TransferTable.SourceWarehouseID.Show"
      ></Column> -->
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.SourceWarehouseName.Field"
        :header="i18n.$t('Transfer').TransferTable.SourceWarehouseName.Show"
      ></Column>
      <!-- <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TargetWarehouseID.Field"
        :header="i18n.$t('Transfer').TransferTable.TargetWarehouseID.Show"
      ></Column> -->
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TargetWarehouseName.Field"
        :header="i18n.$t('Transfer').TransferTable.TargetWarehouseName.Show"
      ></Column>

      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.Status.Field"
        :header="i18n.$t('Transfer').TransferTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Transfer").Transfer
              : i18n.$t("Transfer").Sell
          }}
        </template>
      </Column>

      <Column
        style="width: 120px; overflow: auto"
        :header="i18n.$t('Transfer').TransferTable.Success.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.success == true
              ? i18n.$t("Transfer").TrueText
              : i18n.$t("Transfer").FalseText
          }}
        </template>
      </Column>

      <!-- <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Transfer').TransferTable.TransferDate.Field"
        :header="i18n.$t('Transfer').TransferTable.TransferDate.Show"
      ></Column> -->

      <Column
        style="width: 120px; overflow: auto"
        :header="i18n.$t('Transfer').TransferTable.TransferDate.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.transfer_date) }}
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="data.download"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').ExcelStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="factory">{{ i18n.$t("Stock").ExcelStock.Total }}</label>
        {{ data.excelTotal }}
        <vue3-json-excel
          class="button"
          :header="'TransferLog'"
          :fields="{
            From_Stock_ID: 'source_storage_id',
            From_Stock_Name: 'source_storage_name',
            To_Stock_ID: 'target_storage_id',
            To_Stock_Name: 'target_storage_name',
            From_Factory_ID: 'source_factory_id',
            From_Factory_Name: 'source_factory_name',
            To_Factory_ID: 'target_factory_id',
            To_Factory_Name: 'target_factory_name',
            From_WH_ID: 'source_warehouse_id',
            From_WH_Name: 'source_warehouse_name',
            To_WH_ID: 'target_warehouse_id',
            To_WH_Name: 'target_warehouse_name',
            Type: 'status',
            Status: 'success',
            Date: 'transfer_date',
          }"
          :json-data="data.excel"
          :name="data.nowDate"
        >
          {{ i18n.$t("Stock").ExcelStock.Download }}
        </vue3-json-excel>
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.download = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 8px 14px;
  border: 0px solid #258f3e;
  border-radius: 17px;
  background: #3ce964;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3ce964),
    to(#258f3e)
  );
  background: -moz-linear-gradient(top, #3ce964, #258f3e);
  background: linear-gradient(to bottom, #3ce964, #258f3e);
  -webkit-box-shadow: #37d75c 0px 0px 0px 0px;
  -moz-box-shadow: #37d75c 0px 0px 0px 0px;
  box-shadow: #37d75c 0px 0px 0px 0px;
  text-shadow: #175a27 0px 1px 1px;
  font: normal normal bold 15px arial;
  color: #ffffff;
  text-decoration: none;
}
.button:hover,
.button:focus {
  border: 0px solid #2eb34d;
  background: #48ff78;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#48ff78),
    to(#2cac4a)
  );
  background: -moz-linear-gradient(top, #48ff78, #2cac4a);
  background: linear-gradient(to bottom, #48ff78, #2cac4a);
  color: #ffffff;
  text-decoration: none;
}
.button:active {
  background: #258f3e;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#258f3e),
    to(#258f3e)
  );
  background: -moz-linear-gradient(top, #258f3e, #258f3e);
  background: linear-gradient(to bottom, #258f3e, #258f3e);
}
.button:after {
  content: "\0000a0";
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 -4px -6px 4px;
  position: relative;
  top: 0px;
  left: 0px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABqElEQVRIie3UsWoUYRQF4O+ucQlqkcaQQsRGEBRJkUINYiEhTAKpdix8gV2fQHySjD6Cm06yQTSCWAjBKoiIgnWIIhYhhcj+FpldNqOJk1QWOTDMP/+595x7/mGGE/wDUbdw8V67kVK8gYh0e/Xp436dvrHao/Qb4yJND9fs1mlr1DY4Jk4M/nODLO80slbnwJqs1Wlk+cE8h3wHWd4eC7GOM1iMFDsp0leIFOdT9M8Rq9ilf7fXffLrSAlCgnHMYD1JUwMuRZqyZz6D8b3SIyaAhbw9RTzHdXzCpZL6givYxHyvW2wdywCyVnsyItZxrUK9x9xh4n8YLOSdt+jjTq9bDM80a3UmI7wok8BmSubWVortkd4xvEaj1y1uDvar7+AGplPSHN1cWym2UzKPDWykZH5UHMqe6VJjiKrBDpoRJqpR11aKLdIsaXZvXTmKSBNolhpDVP+mr7CE5SzvPCD92Ddlec/y9r6EkWICy6Xey8MMHuIWloIF4md10r8iNEut73g0Sp0affj84d23y1dnnuECLuIsTte4dtHD/V63+FhrqBMM8Btk+3u+nyYmoAAAAABJRU5ErkJggg==")
    no-repeat left center transparent;
  background-size: 100% 100%;
}
</style>
