import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class WarehousePurchaseOrderContentHistory {
  getWarehousePurchaseOrderContentHistoryInfo(id) {
    return axios.get(
      `/api/warehouse-purchase-order-content-history/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getWarehousePurchaseOrderContentHistoryList(
    warehouse_purchase_order_content_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/warehouse-purchase-order-content-history/list`,
      {
        params: {
          warehouse_purchase_order_content_id:
            warehouse_purchase_order_content_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchWarehousePurchaseOrderContentHistory(datas) {
    return axios.post(
      `/api/warehouse-purchase-order-content-history/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addWarehousePurchaseOrderContentHistory(
    warehouse_purchase_order_content_id,
    count,
    unit_dollar,
    remark,
    warehouse_id,
    storage_chinese,
    storage_weight_unit
  ) {
    return axios.post(
      `/api/warehouse-purchase-order-content-history`,
      {
        warehouse_purchase_order_content_id:
          warehouse_purchase_order_content_id,
        count: count,
        unit_dollar: unit_dollar,
        remark: remark,
        warehouse_id: warehouse_id,
        storage_chinese: storage_chinese,
        storage_weight_unit: storage_weight_unit,
      },
      config
    );
  }
  deleteWarehousePurchaseOrderContentHistory(
    warehouse_purchase_order_content_ids
  ) {
    return axios.delete(
      `/api/warehouse-purchase-order-content-history`,
      {
        data: {
          warehouse_purchase_order_content_ids:
            warehouse_purchase_order_content_ids,
        },
      },
      config
    );
  }
}
