<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import * as XLSX from "xlsx/xlsx.mjs";

/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
XLSX.set_fs(fs);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);
// import Designer from "../components/Designer.vue";
import { ref } from "vue";
import axios from "axios";
// import GC from "@grapecity/spread-sheets";

// import ExcelIO from "@grapecity/spread-excelio";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Warehouse from "../api/warehouse";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/user";
import firebase from "firebase";
import Factory from "../api/factory";
import router from "../router";

let userApi = new User();
let warehouseAPI = new Warehouse();
let factoryAPI = new Factory();
const store = useStore();

function readFile(e) {
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 0,
    });
    console.log(json);
    let importExcel = [];
    for (let i = 0; i < json.length; i++) {
      importExcel.push({
        exact_id: json[i].id != undefined ? String(json[i].id) : "",
        name: json[i].name != undefined ? json[i].name : "",
        category: json[i].category != undefined ? json[i].category : "",
        spec: json[i].spec != undefined ? json[i].spec : "",
        description:
          json[i].description != undefined ? json[i].description : "",
        define: json[i].define != undefined ? json[i].define : "",
        save_stock: json[i].save_stock != undefined ? json[i].save_stock : "",
      });
    }

    materialAPI.batchMaterial(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").AddSuccess,
          life: 3000,
        });

        data.materialLoading = true;
        getMaterialList();
        data.addExcelDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

async function saveExcelMaterial() {
  let spread = designer.getWorkbook();
  //   console.log(JSON.stringify(spread.toJSON()));
  let content = spread.toJSON();
  let detail = content.sheets.import.data.dataTable;
  let importExcel = [];
  detail = await Object.values(detail);

  for (let i = 1; i < detail.length; i++) {
    var tmp = Object.values(detail[i]);
    console.log(tmp.length);
    if (tmp.length == 7)
      importExcel.push({
        exact_id: String(tmp[0].value),
        name: String(tmp[1].value),
        category: String(tmp[2].value),
        spec: String(tmp[3].value),
        description: String(tmp[4].value),
        define: String(tmp[5].value),
        save_stock: String(tmp[6].value),
      });
  }

  materialAPI.batchMaterial(importExcel).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").AddSuccess,
        life: 3000,
      });

      data.materialLoading = true;
      getMaterialList();
      data.addExcelDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });

  //   console.log(spread.toJSON());
}

let designer = undefined;

let designerInitialized = (wb) => {
  designer = wb;

  let spread = designer.getWorkbook();
};

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectFactoryList: [],
  factorySearch: "",
  add_warehouse: {
    factory_id: "",
    name: "",
    remark: "",
  },
  warehouseLoading: false,
  totalRecords: 0,
  addExcelDialog: false,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  warehouses: { data: null },
  warehouse: null,
  // messages: [],
  page: 1,
  page_num: 10,
  selectedWarehouses: null,
  warehouseFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    remark: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getWarehouseList();
    getFactorySelectList();
  }
});

function getWarehouseList() {
  CheckUser();
  warehouseAPI
    .getWarehouseList(
      data.warehouseFilters.id.value,
      data.warehouseFilters.factory_id.value,
      data.warehouseFilters.name.value,
      data.warehouseFilters.remark.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.warehouses.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.warehouseLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.warehouseLoading = false;
      }
    });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function saveEditWarehouse() {
  console.log(data.warehouse.factory);
  warehouseAPI
    .editWarehouse(
      data.warehouse.id,
      data.warehouse.factory.id,
      data.warehouse.name,
      data.warehouse.remark
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").EditSuccess,
          life: 3000,
        });

        data.warehouseLoading = true;
        getWarehouseList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveAddWarehouse() {
  warehouseAPI
    .addWarehouse(
      data.add_warehouse.factory_id.id,
      data.add_warehouse.name,
      data.add_warehouse.remark
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").AddSuccess,
          life: 3000,
        });

        data.warehouseLoading = true;
        getWarehouseList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function excelMaterial() {
  data.addExcelDialog = true;
}
function addWarehouse() {
  data.addDialog = true;
}
function editWarehouse(warehouse) {
  for (let i = 0; i < data.selectFactoryList.length; i++) {
    if (warehouse.factory_id == data.selectFactoryList[i].id) {
      warehouse.factory = data.selectFactoryList[i];
    }
  }

  data.warehouse = { ...warehouse };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(warehouse) {
  data.warehouse = warehouse;
  data.deleteDialog = true;
}
function deleteWarehouse() {
  warehouseAPI.deleteWarehouse([data.warehouse.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").DeleteSuccess,
        life: 3000,
      });

      data.warehouseLoading = true;
      getWarehouseList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedWarehouse() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedWarehouses.length; i++) {
    selectDelete.push(data.selectedWarehouses[i].id);
  }
  warehouseAPI.deleteWarehouse(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").DeleteSuccess,
        life: 3000,
      });

      data.warehouseLoading = true;
      data.selectedWarehouses = [];
      getWarehouseList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
  // this.lazyParams = event;
  // this.loadLazyData();
}
watch(
  () => data.factorySearch,
  () => {
    data.warehouseLoading = true;
    data.warehouseFilters.factory_id.value =
      data.factorySearch == null ? "" : data.factorySearch.id;
    getWarehouseList();
  }
);
watch(
  () => data.page,
  () => {
    data.warehouseLoading = true;
    getWarehouseList();
  }
);
watch(
  () => data.warehouseFilters.name.value,
  () => {
    data.warehouseLoading = true;
    getWarehouseList();
  }
);
watch(
  () => data.warehouseFilters.id.value,
  () => {
    data.warehouseLoading = true;
    getWarehouseList();
  }
);
watch(
  () => data.warehouseFilters.remark.value,
  () => {
    data.warehouseLoading = true;
    getWarehouseList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :value="data.warehouses.data"
      :loading="data.warehouseLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.warehouseFilters"
      v-model:selection="data.selectedWarehouses"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Material").MaterialTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.warehouseFilters[
                        i18n.$t('Warehouse').WarehouseTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Warehouse').WarehouseTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.warehouseFilters[
                        i18n.$t('Warehouse').WarehouseTable.Name.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Warehouse').WarehouseTable.Name.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.factorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1 p-d-none">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.warehouseFilters[
                        i18n.$t('Warehouse').WarehouseTable.Remark.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('Warehouse').WarehouseTable.Remark.Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <!-- <label class="button-up p-mr-2">
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="readFile"
              />
              <i class="pi pi-plus"></i>
              {{ i18n.$t("Material").MaterialTable.Excel }}
            </label> -->
            <Button
              :label="i18n.$t('Material').MaterialTable.Excel"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-d-none"
              @click="excelMaterial"
            />
            <Button
              :label="i18n.$t('Material').MaterialTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addWarehouse"
            />
            <Button
              :label="i18n.$t('Material').MaterialTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedWarehouses || !data.selectedWarehouses.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Warehouse').WarehouseTable.ID.Field"
        :header="i18n.$t('Warehouse').WarehouseTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Warehouse').WarehouseTable.Name.Field"
        :header="i18n.$t('Warehouse').WarehouseTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Warehouse').WarehouseTable.Factory.Field"
        :header="i18n.$t('Warehouse').WarehouseTable.Factory.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Warehouse').WarehouseTable.FactoryName.Field"
        :header="i18n.$t('Warehouse').WarehouseTable.FactoryName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Warehouse').WarehouseTable.Remark.Field"
        :header="i18n.$t('Warehouse').WarehouseTable.Remark.Show"
      ></Column>

      <!-- <Column
        class="p-col-6"
        style="overflow: scroll"
        :header="i18n.$t('Supplier').MaterialTable.Description.Show"
        :field="i18n.$t('Supplier').MaterialTable.Description.Field"
      >
      </Column> -->
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editWarehouse(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 匯入 -->
    <Dialog
      v-model:visible="data.addExcelDialog"
      :style="{ width: '850px', minWidth: '350px' }"
      :header="i18n.$t('Material').ExcelMaterial.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="id">{{ i18n.$t("Material").ExcelMaterial.Add }}</label>
        <!-- <Designer v-on:designerInitialized="designerInitialized"></Designer> -->
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addExcelDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Excel"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveExcelMaterial"
        />
      </template>
    </Dialog>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Warehouse').AddWarehouse.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Warehouse").WarehouseTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_warehouse.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="factory_id">{{
          i18n.$t("Warehouse").WarehouseTable.Factory.Show
        }}</label>
        <Dropdown
          v-model="data.add_warehouse.factory_id"
          :options="data.selectFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Warehouse').WarehouseTable.Factory.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Warehouse").WarehouseTable.Remark.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_warehouse.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddWarehouse"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').EditMaterial.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Warehouse").WarehouseTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.warehouse.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Warehouse").WarehouseTable.Name.Show
        }}</label>
        <Dropdown
          v-model="data.warehouse.factory"
          :options="data.selectFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Warehouse').WarehouseTable.Factory.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("Warehouse").WarehouseTable.Remark.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.warehouse.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditWarehouse"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.warehouse"
          >{{ i18n.$t("Material").Confirm.Content }}
          <b>{{ data.warehouse.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteWarehouse"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Material").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedWarehouse"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
