import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Warehouse {
  getWarehouseInfo(id) {
    return axios.get(
      `/api/warehouse/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getWarehouseList(id, factory_id, name, remark, page, page_num) {
    return axios.get(
      `/api/warehouse/list`,
      {
        params: {
          id: id,
          factory_id: factory_id,
          name: name,
          remark: remark,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getWarehouseSelectList(name, factory_id) {
    return axios.get(
      `/api/warehouse/select-list`,
      {
        params: {
          name: name,
          factory_id: factory_id,
        },
      },
      config
    );
  }
  batchWarehouse(datas) {
    return axios.post(
      `/api/batch/warehouse`,
      {
        datas: datas,
      },
      config
    );
  }
  addWarehouse(factory_id, name, remark) {
    return axios.post(
      `/api/warehouse`,
      {
        factory_id: factory_id,
        name: name,
        remark: remark,
      },
      config
    );
  }
  editWarehouse(id, factory_id, name, remark) {
    return axios.put(
      `/api/warehouse`,
      {
        id: id,
        factory_id: factory_id,
        name: name,
        remark: remark,
      },
      config
    );
  }
  deleteWarehouse(id) {
    return axios.delete(
      `/api/warehouse`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
