import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrderCollar {
  getPurchaseOrderCollarInfo(id) {
    return axios.get(
      `/api/order/purchase-order-collar/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderCollarList(
    purchase_order_id,
    order_no,
    color,
    is_use,
    page,
    page_num
  ) {
    return axios.get(
      `/api/order/purchase-order-collar/list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          order_no: order_no,
          color: color,
          is_use: is_use,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderCollarSelectList(purchase_order_id, order_no) {
    return axios.get(
      `/api/order/purchase-order-collar/select-list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          order_no: order_no,
        },
      },
      config
    );
  }
  getPurchaseOrderCollarExcel(purchase_order_ids) {
    return axios.get(
      `/api/order/purchase-order-collar/select-list`,
      {
        params: {
          purchase_order_ids: purchase_order_ids,
          is_use: 1,
        },
      },
      config
    );
  }
  batchPurchaseOrderCollar(datas) {
    return axios.post(
      `/api/order/batch/purchase-order-collar`,
      {
        datas: datas,
      },
      config
    );
  }
  addPurchaseOrderCollar(
    is_use_ids,
    not_is_use_ids,
    purchase_order_id,
    order_no,
    spec,
    color,
    price,
    total_count,
    total_price,
    version,
    employer_id,
    employer_email,
    is_use
  ) {
    return axios.post(
      `/api/order/purchase-order-collar`,
      {
        is_use_ids: is_use_ids,
        not_is_use_ids: not_is_use_ids,
        purchase_order_id: purchase_order_id,
        order_no: order_no,
        spec: spec,
        color: color,
        price: price,
        total_count: total_count,
        total_price: total_price,
        version: version,
        employer_id: employer_id,
        employer_email: employer_email,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderCollar(id, is_use) {
    return axios.put(
      `/api/order/purchase-order-collar/is-use`,
      {
        id: id,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderCollarContent(
    purchase_order_id,
    id,
    order_no,
    spec,
    color,
    price,
    total_count,
    total_price
  ) {
    return axios.put(
      `/api/order/purchase-order-collar/`,
      {
        purchase_order_id: purchase_order_id,
        id: id,
        order_no: order_no,
        spec: spec,
        color: color,
        price: price,
        total_count: total_count,
        total_price: total_price,
      },
      config
    );
  }
  //   deletePurchaseOrderCollar(id) {
  //     return axios.delete(
  //       `/api/order/purchase-order-collar`,
  //       {
  //         data: {
  //           ids: id,
  //         },
  //       },
  //       config
  //     );
  //   }
}
