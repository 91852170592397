import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrder {
  getPurchaseOrderInfo(id) {
    return axios.get(
      `/api/order/purchase-order`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderList(
    item_id,
    order_customer_no,
    customized_order_id,
    customer_id,
    status,
    predict_delivery_date_begin,
    predict_delivery_date_end,
    shipping_date_begin,
    shipping_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/order/purchase-order/list`,
      {
        params: {
          item_id: item_id,
          order_customer_no: order_customer_no,
          customized_order_id: customized_order_id,
          customer_id: customer_id,
          status: status,
          predict_delivery_date_begin: predict_delivery_date_begin,
          predict_delivery_date_end: predict_delivery_date_end,
          shipping_date_begin: shipping_date_begin,
          shipping_date_end: shipping_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  addPurchaseOrder(
    item_id,
    order_customer_no,
    customized_order_id,
    ie,
    order_price,
    new_agent_ids,
    order_total_price, //生成
    predict_delivery_date,
    shipping_date, //最後
    shipping_count, //最後
    customer_id,
    color,
    status,
    remark, //備註
    description, //描述
    invoice, //最後
    commission_rate, //佣金
    image,
    cmt_factory_id,
    product_group_id,
    employer_id,
    new_purchase_size_item_relations
  ) {
    return axios.post(
      `/api/order/purchase-order`,
      {
        item_id: item_id,
        order_customer_no: order_customer_no,
        customized_order_id: customized_order_id,
        ie: ie,
        order_price: order_price,
        new_agent_ids: new_agent_ids,
        order_total_price: order_total_price,
        predict_delivery_date: predict_delivery_date,
        shipping_date: shipping_date,
        shipping_count: shipping_count,
        customer_id: customer_id,
        color: color,
        status: status,
        remark: remark,
        description: description,
        invoice: invoice,
        commission_rate: commission_rate,
        image: image,
        cmt_factory_id: cmt_factory_id,
        product_group_id: product_group_id,
        employer_id: employer_id,
        new_purchase_size_item_relations: new_purchase_size_item_relations,
      },
      config
    );
  }
  editPurchaseOrder(
    id,
    item_id,
    order_customer_no,
    customized_order_id,
    ie,
    order_price,
    new_agent_ids,
    delete_agent_ids,
    order_total_price,
    predict_delivery_date,
    shipping_date,
    shipping_count,
    customer_id,
    color,
    status,
    remark,
    description,
    invoice,
    commission_rate,
    image,
    cmt_factory_id,
    product_group_id,
    employer_id,
    new_purchase_size_item_relations,
    delete_purchase_size_item_ids
  ) {
    return axios.put(
      `/api/order/purchase-order/`,
      {
        id: id,
        item_id: item_id,
        order_customer_no: order_customer_no,
        customized_order_id: customized_order_id,
        ie: ie,
        order_price: order_price,
        new_agent_ids: new_agent_ids,
        delete_agent_ids: delete_agent_ids,
        order_total_price: order_total_price,
        predict_delivery_date: predict_delivery_date,
        shipping_date: shipping_date,
        shipping_count: shipping_count,
        customer_id: customer_id,
        color: color,
        status: status,
        remark: remark,
        description: description,
        invoice: invoice,
        commission_rate: commission_rate,
        image: image,
        cmt_factory_id: cmt_factory_id,
        product_group_id: product_group_id,
        employer_id: employer_id,
        new_purchase_size_item_relations: new_purchase_size_item_relations,
        delete_purchase_size_item_ids: delete_purchase_size_item_ids,
      },
      config
    );
  }
  editPurchaseOrderStatus(id, status, employer_id) {
    return axios.put(
      `/api/order/purchase-order`,
      {
        id: id,
        status: status,
        employer_id: employer_id,
      },
      config
    );
  }
  finishPurchaseOrderStatus(
    id,
    invoice,
    shipping_date,
    shipping_count,
    status,
    employer_id
  ) {
    return axios.put(
      `/api/order/purchase-order`,
      {
        id: id,
        invoice: invoice,
        shipping_date: shipping_date,
        shipping_count: shipping_count,
        status: status,
        employer_id: employer_id,
      },
      config
    );
  }
  cancelPurchaseOrderStatus(id, status, employer_id) {
    return axios.put(
      `/api/order/purchase-order`,
      {
        id: id,
        status: status,
        employer_id: employer_id,
      },
      config
    );
  }
  deletePurchaseOrder(id) {
    return axios.delete(
      `/api/order/purchase-order`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
