<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  userLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  users: { data: null },
  user: null,
  page: 1,
  page_num: 10,
  selectedUsers: null,
  userFilters: {
    id: { value: "", matchMode: "contains" },
    email: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getUserList();
  }
});

function getUserList() {
  CheckUser();
  userApi
    .getUserList(
      data.userFilters.id.value,
      data.userFilters.email.value,
      data.userFilters.status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.users.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.userLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.userLoading = false;
      }
    });
}

function saveEditUser(user) {
  userApi.editUser(user.id, user.status == 1 ? 2 : 1).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("User").EditSuccess,
        life: 3000,
      });

      data.userLoading = true;
      getUserList();
      data.editDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.userLoading = true;
    getUserList();
  }
);
watch(
  () => data.userFilters.id.value,
  () => {
    data.userLoading = true;
    getUserList();
  }
);
watch(
  () => data.userFilters.email.value,
  () => {
    data.userLoading = true;
    getUserList();
  }
);
watch(
  () => data.userFilters.status.value,
  () => {
    data.userLoading = true;
    getUserList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :value="data.users.data"
      :loading="data.userLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.userFilters"
      v-model:selection="data.selectedUsers"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("User").UserTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.userFilters[i18n.$t('User').UserTable.ID.Field].value
                    "
                    :placeholder="i18n.$t('User').UserTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.userFilters[i18n.$t('User').UserTable.Email.Field]
                        .value
                    "
                    :placeholder="i18n.$t('User').UserTable.Email.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
      <!-- <Column class="p-col-1" selectionMode="multiple"></Column> -->
      <Column
        headerStyle="width: 18em"
        class="p-col-1"
        :field="i18n.$t('User').UserTable.ID.Field"
        :header="i18n.$t('User').UserTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('User').UserTable.Email.Field"
        :header="i18n.$t('User').UserTable.Email.Show"
        bodyStyle="white-space:nowrap;overflow:scroll;"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('User').UserTable.Status.Field"
        :header="i18n.$t('User').UserTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 2
              ? i18n.$t("User").UserTable.On
              : i18n.$t("User").UserTable.Off
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space:nowrap;overflow:scroll;min-width:120px;"
      >
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            :label="
              slotProps.data.status == 1
                ? i18n.$t('User').UserTable.On
                : i18n.$t('User').UserTable.Off
            "
            :class="
              slotProps.data.status == 1
                ? 'p-button-rounded p-button-success p-mr-2'
                : 'p-button-rounded p-button-danger p-mr-2'
            "
            @click="saveEditUser(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
