import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Station {
  getStationInfo(id) {
    return axios.get(
      `/api/order/station`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getStationList(
    name,
    station_status,
    purchase_order_customer_no,
    purchase_order_customized_order_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/order/station/list`,
      {
        params: {
          name: name,
          station_status: station_status,
          purchase_order_customer_no: purchase_order_customer_no,
          purchase_order_customized_order_id:
            purchase_order_customized_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  editStation(id, purchase_order_id, report_count, station_status) {
    return axios.put(
      `/api/order/station`,
      {
        id: id,
        purchase_order_id: purchase_order_id,
        report_count: report_count,
        station_status: station_status,
      },
      config
    );
  }
}
