import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Stock {
  getStorageInfo(id) {
    return axios.get(
      `/api/storage/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getStorageList(
    iteam,
    chinese,
    english,
    factory_id,
    warehouse_id,
    in_date_begin,
    in_date_end,
    out_date_begin,
    out_date_end,
    status,
    page,
    page_num
  ) {
    return axios.get(
      `/api/storage/list`,
      {
        params: {
          iteam: iteam,
          chinese: chinese,
          english: english,
          factory_id: factory_id,
          warehouse_id: warehouse_id,
          in_date_begin: in_date_begin,
          in_date_end: in_date_end,
          out_date_begin: out_date_begin,
          out_date_end: out_date_end,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getStorageTotalList(
    iteam,
    english,
    status,
    factory_id,
    in_date_begin,
    in_date_end,
    out_date_begin,
    out_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/storage/summary`,
      {
        params: {
          iteam: iteam,
          english: english,
          status: status,
          factory_id: factory_id,
          in_date_begin: in_date_begin,
          in_date_end: in_date_end,
          out_date_begin: out_date_begin,
          out_date_end: out_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getStorageLastTotalList(
    iteam,
    english,
    factory_id,
    in_date_begin,
    in_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/storage/last/summary`,
      {
        params: {
          iteam: iteam,
          english: english,
          factory_id: factory_id,
          in_date_begin: in_date_begin,
          in_date_end: in_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getStorageSelectList(iteam, chinese, english) {
    return axios.get(
      `/api/storage/select-list`,
      {
        params: {
          iteam: iteam,
          chinese: chinese,
          english: english,
        },
      },
      config
    );
  }
  getStorageSaveList(
    iteam,
    english,
    factory_id,
    in_date_begin,
    in_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/storage/lower/save-stock`,
      {
        params: {
          iteam: iteam,
          factory_id: factory_id,
          english: english,
          in_date_begin: in_date_begin,
          in_date_end: in_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }

  addTotalStorage(datas) {
    return axios.post(
      `/api/batch/storage`,
      {
        datas: datas,
      },
      config
    );
  }
  addStorage(
    factory_id,
    warehouse_id,
    iteam,
    chinese,
    english,
    weight,
    weight_unit,
    count,
    in_date,
    out_date,
    status,
    remark,
    warehouse_supplier_id,
    unit_dollar
  ) {
    return axios.post(
      `/api/storage`,
      {
        factory_id: factory_id,
        warehouse_id: warehouse_id,
        iteam: iteam,
        chinese: chinese,
        english: english,
        weight: weight,
        weight_unit: weight_unit,
        count: count,
        in_date: in_date,
        out_date: out_date,
        status: status,
        remark: remark,
        warehouse_supplier_id: warehouse_supplier_id,
        unit_dollar: unit_dollar,
      },
      config
    );
  }
  editStorage(id, out_date, status) {
    return axios.put(
      `/api/storage`,
      {
        id: id,
        out_date: out_date,
        status: status,
      },
      config
    );
  }
  editTotalStorage(ids, out_date, status) {
    return axios.put(
      `/api/batch/out/storage`,
      {
        ids: ids,
        out_date: out_date,
        status: status,
      },
      config
    );
  }
  editStorageCount(id, count, weight, unit_dollar) {
    return axios.put(
      `/api/storage`,
      {
        id: id,
        count: count,
        weight: weight,
        unit_dollar: unit_dollar,
      },
      config
    );
  }
  deleteStorage(id) {
    return axios.delete(
      `/api/storage`,
      {
        data: {
          ids: [id],
        },
      },
      config
    );
  }
}
