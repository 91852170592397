import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class SizeItem {
  getSizeItemInfo(id) {
    return axios.get(
      `/api/order/purchase-size-item/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSizeItemList(id, name, purchase_size_type_id, page, page_num) {
    return axios.get(
      `/api/order/purchase-size-item/list`,
      {
        params: {
          id: id,
          name: name,
          purchase_size_type_id: purchase_size_type_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getSizeItemSelectList(name, purchase_size_type_id) {
    return axios.get(
      `/api/order/purchase-size-item/select-list`,
      {
        params: {
          name: name,
          purchase_size_type_id: purchase_size_type_id,
        },
      },
      config
    );
  }
  batchSizeItem(datas) {
    return axios.post(
      `/api/order/purchase-size-item/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addSizeItem(name, description, remark, purchase_size_type_id) {
    return axios.post(
      `/api/order/purchase-size-item/`,
      {
        name: name,
        description: description,
        remark: remark,
        purchase_size_type_id: purchase_size_type_id,
      },
      config
    );
  }
  editSizeItem(id, name, description, remark, purchase_size_type_id) {
    return axios.put(
      `/api/order/purchase-size-item/`,
      {
        id: id,
        name: name,
        description: description,
        remark: remark,
        purchase_size_type_id: purchase_size_type_id,
      },
      config
    );
  }
  deleteSizeItem(id) {
    return axios.delete(
      `/api/order/purchase-size-item/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
