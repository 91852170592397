import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Item {
  getItemInfo(id) {
    return axios.get(
      `/api/order/item/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getItemList(id, code_no, name, page, page_num) {
    return axios.get(
      `/api/order/item/list`,
      {
        params: {
          id: id,
          code_no: code_no,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getItemSelectList(name) {
    return axios.get(
      `/api/order/item/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchItem(datas) {
    return axios.post(
      `/api/order/batch/item`,
      {
        datas: datas,
      },
      config
    );
  }
  addItem(name, code_no, ie, description) {
    return axios.post(
      `/api/order/item`,
      {
        name: name,
        code_no: code_no,
        ie: ie,
        description: description,
      },
      config
    );
  }
  editItem(id, name, code_no, ie, description) {
    return axios.put(
      `/api/order/item`,
      {
        id: id,
        name: name,
        code_no: code_no,
        ie: ie,
        description: description,
      },
      config
    );
  }
  deleteItem(id) {
    return axios.delete(
      `/api/order/item`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
