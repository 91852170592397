import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrderSmooth {
  getPurchaseOrderSmoothInfo(id) {
    return axios.get(
      `/api/order/purchase-order-smooth/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderSmoothList(purchase_order_id, is_use, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-smooth/list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          is_use: is_use,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderSmoothListByID(id, purchase_order_id, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-smooth/list`,
      {
        params: {
          id: id,
          purchase_order_id: purchase_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderSmoothSelectList(purchase_order_id) {
    return axios.get(
      `/api/order/purchase-order-smooth/select-list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
        },
      },
      config
    );
  }
  getPurchaseOrderSmoothExcel(purchase_order_ids) {
    return axios.get(
      `/api/order/purchase-order-smooth/select-list`,
      {
        params: {
          purchase_order_ids: purchase_order_ids,
          is_use: 1,
        },
      },
      config
    );
  }
  batchPurchaseOrderSmooth(datas) {
    return axios.post(
      `/api/order/batch/purchase-order-smooth`,
      {
        datas: datas,
      },
      config
    );
  }
  addPurchaseOrderSmooth(
    is_use_ids,
    not_is_use_ids,
    purchase_order_id,
    print_count,
    print_price,
    print_total_price,
    logo_count,
    logo_price,
    logo_total_price,
    label_count,
    label_price,
    label_total_price,
    screen_charge,
    total_price,
    version,
    employer_id,
    employer_email,
    image,
    is_use
  ) {
    return axios.post(
      `/api/order/purchase-order-smooth`,
      {
        is_use_ids: is_use_ids,
        not_is_use_ids: not_is_use_ids,
        purchase_order_id: purchase_order_id,

        print_count: print_count,
        print_price: print_price,
        print_total_price: print_total_price,
        logo_count: logo_count,
        logo_price: logo_price,
        logo_total_price: logo_total_price,
        label_count: label_count,
        label_price: label_price,
        label_total_price: label_total_price,
        screen_charge: screen_charge,
        total_price: total_price,
        version: version,
        employer_id: employer_id,
        employer_email: employer_email,
        image: image,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderSmooth(id, is_use) {
    return axios.put(
      `/api/order/purchase-order-smooth/is-use`,
      {
        id: id,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderSmoothContent(
    purchase_order_id,
    id,
    print_count,
    print_price,
    print_total_price,
    logo_count,
    logo_price,
    logo_total_price,
    label_count,
    label_price,
    label_total_price,
    screen_charge,
    total_price,
    image
  ) {
    return axios.put(
      `/api/order/purchase-order-smooth/`,
      {
        purchase_order_id: purchase_order_id,
        id: id,
        print_count: print_count,
        print_price: print_price,
        print_total_price: print_total_price,
        logo_count: logo_count,
        logo_price: logo_price,
        logo_total_price: logo_total_price,
        label_count: label_count,
        label_price: label_price,
        label_total_price: label_total_price,
        screen_charge: screen_charge,
        total_price: total_price,
        image: image,
      },
      config
    );
  }
  //   deletePurchaseOrderSmooth(id) {
  //     return axios.delete(
  //       `/api/order/purchase-order-smooth`,
  //       {
  //         data: {
  //           ids: id,
  //         },
  //       },
  //       config
  //     );
  //   }
}
