<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PurchaseOrderFabric from "../../api/order/purchase_order_fabric";
import Cloth from "../../api/order/cloth";
import FileUpload from "primevue/fileUpload";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let purchase_order_fabricAPI = new PurchaseOrderFabric();

let clothAPI = new Cloth();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
const i18n = useI18n();
const toast = useToast();

const data = reactive({
  SelectCloth: "",
  selectClothList: [],
  add_purchase_order_fabric: {
    code_no: "",
    color: "",
    lose: 0,
    once_cost_no: 0,
    once_cost_weight: 0,
    price: 0,
    weight: 0,
    total_price: 0,
    version: 0,
    image: null,
  },
  purchase_order_fabricLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  purchase_order_fabrics: { data: null },
  purchase_order_fabric: {
    code_no: "",
    color: "",
    lose: 0,
    once_cost_no: 0,
    once_cost_weight: 0,
    price: 0,
    weight: 0,
    total_price: 0,
    version: 0,
    image: null,
  },
  page: 1,
  page_num: 10,
  selected_purchase_order_fabrics: null,
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPurchaseOrderFabricList();
    getClothSelectList();
  }
});

function onUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_purchase_order_fabric.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function onEditUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.purchase_order_fabric.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function getClothSelectList() {
  clothAPI.getClothSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectClothList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getPurchaseOrderFabricList() {
  CheckUser();
  purchase_order_fabricAPI
    .getPurchaseOrderFabricList(
      store.state.order_id,
      "",
      "",
      "",
      true,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.purchase_order_fabrics.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.purchase_order_fabricLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.purchase_order_fabricLoading = false;
      }
    });
}

function saveAddPurchaseOrderFabric() {
  purchase_order_fabricAPI
    .addPurchaseOrderFabric(
      [],
      [],
      store.state.order_id,
      data.add_purchase_order_fabric.code_no,
      data.add_purchase_order_fabric.color,
      data.add_purchase_order_fabric.lose.toString(),
      data.add_purchase_order_fabric.once_cost_no.toString(),
      data.add_purchase_order_fabric.once_cost_weight.toString(),
      data.add_purchase_order_fabric.price.toString(),
      data.add_purchase_order_fabric.weight.toString(),
      data.add_purchase_order_fabric.total_price.toString(),
      data.add_purchase_order_fabric.version,
      store.state.user.id,
      store.state.user.email,
      true,
      data.add_purchase_order_fabric.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderFabric").AddSuccess,
          life: 3000,
        });

        data.purchase_order_fabricLoading = true;
        getPurchaseOrderFabricList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

async function addPurchaseOrderFabric() {
  CheckUser();
  await getClothSelectList();
  data.addDialog = true;
  data.SelectCloth = "";
  data.add_purchase_order_fabric = {
    code_no: "",
    color: "",
    lose: 0,
    once_cost_no: 0,
    once_cost_weight: 0,
    price: 0,
    weight: 0,
    total_price: 0,
    version: 0,
    image: null,
  };
}

async function editPurchaseOrderFabric(purchase_order_fabric) {
  CheckUser();
  await getClothSelectList();
  for (let i = 0; i < data.selectClothList.length; i++) {
    if (data.selectClothList[i].code_no == purchase_order_fabric.code_no) {
      data.SelectEditCloth = data.selectClothList[i];
      break;
    }
  }

  data.purchase_order_fabric = { ...purchase_order_fabric };
  data.editDialog = true;
}

function saveEditPurchaseOrderFabric() {
  purchase_order_fabricAPI
    .editPurchaseOrderFabricContent(
      store.state.order_id,
      data.purchase_order_fabric.id,
      data.purchase_order_fabric.code_no,
      data.purchase_order_fabric.color,
      data.purchase_order_fabric.lose.toString(),
      data.purchase_order_fabric.once_cost_no.toString(),
      data.purchase_order_fabric.once_cost_weight.toString(),
      data.purchase_order_fabric.price.toString(),
      data.purchase_order_fabric.weight.toString(),
      data.purchase_order_fabric.total_price.toString(),
      data.purchase_order_fabric.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderFabric").EditSuccess,
          life: 3000,
        });

        data.purchase_order_fabricLoading = true;
        getPurchaseOrderFabricList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}

function confirmDelete(purchase_order_fabric) {
  data.purchase_order_fabric = purchase_order_fabric;
  data.deleteDialog = true;
}

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}

function deletePurchaseOrderFabric() {
  purchase_order_fabricAPI
    .editPurchaseOrderFabric(data.purchase_order_fabric.id, false)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderFabric").DeleteSuccess,
          life: 3000,
        });

        data.purchase_order_fabricLoading = true;
        getPurchaseOrderFabricList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedPurchaseOrderFabric() {
  let selectDelete = [];
  for (let i = 0; i < data.selected_purchase_order_fabrics.length; i++) {
    selectDelete.push(data.selected_purchase_order_fabrics[i].id);
  }
  purchase_order_fabricAPI
    .editPurchaseOrderFabric(store.state.order_id, [], selectDelete)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PurchaseOrderFabric").DeleteSuccess,
          life: 3000,
        });

        data.purchase_order_fabricLoading = true;
        data.selected_purchase_order_fabrics = [];
        getPurchaseOrderFabricList();
        data.deleteSelectedDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    data.purchase_order_fabricLoading = true;
    getPurchaseOrderFabricList();
  }
);

watch(
  () => data.SelectCloth,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.add_purchase_order_fabric.code_no = data.SelectCloth.code_no;
  }
);

watch(
  () => data.SelectEditCloth,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.purchase_order_fabric.code_no = data.SelectEditCloth.code_no;
  }
);

watch(
  () => data.add_purchase_order_fabric.once_cost_no,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.add_purchase_order_fabric.once_cost_weight =
      data.add_purchase_order_fabric.once_cost_no +
      data.add_purchase_order_fabric.lose;
  }
);
watch(
  () => data.add_purchase_order_fabric.lose,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.add_purchase_order_fabric.once_cost_weight =
      data.add_purchase_order_fabric.once_cost_no +
      data.add_purchase_order_fabric.lose;
  }
);

watch(
  () => data.purchase_order_fabric.once_cost_no,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.purchase_order_fabric.once_cost_weight =
      data.purchase_order_fabric.once_cost_no + data.purchase_order_fabric.lose;
  }
);
watch(
  () => data.purchase_order_fabric.lose,
  () => {
    // console.log(data.SelectCloth.code_no);
    data.purchase_order_fabric.once_cost_weight =
      data.purchase_order_fabric.once_cost_no + data.purchase_order_fabric.lose;
  }
);

// watch(
//   () => data.price,
//   () => {
//     // console.log(data.SelectCloth.code_no);
//     data.add_purchase_order_fabric.total_price =  data.add_purchase_order_fabric.price;
//   }
// );
</script>

<template>
  <Toast />
  <div>
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.purchase_order_fabrics.data"
      :loading="data.purchase_order_fabricLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.purchase_order_fabricFilters"
      v-model:selection="data.selected_purchase_order_fabrics"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <div
            v-if="store.state.order_status == 1"
            class="p-col-12 p-d-flex p-jc-end"
          >
            <Button
              :label="
                i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Add
              "
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addPurchaseOrderFabric"
            />
            <Button
              :label="
                i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Delete
              "
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selected_purchase_order_fabrics ||
                !data.selected_purchase_order_fabrics.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        headerStyle="width:20em"
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.ID.Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.ID.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.CodeNo.Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.CodeNo.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Color.Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Color.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Lose.Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Lose.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostNo
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostNo
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostWeight
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.OnceCostWeight
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Price.Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Price.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Weight.Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Weight.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.TotalPrice
            .Field
        "
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.TotalPrice
            .Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.Image.Show
        "
      >
        <template #body="slotProps">
          <img class="product-image" :src="slotProps.data.image_url" />
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            v-if="store.state.order_status == 1"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPurchaseOrderFabric(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            v-if="store.state.order_status == 1"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrderFabric').AddPurchaseOrderFabric.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="in_date">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.CodeNo.Show
        }}</label>
        <Dropdown
          v-model="data.SelectCloth"
          :options="data.selectClothList"
          optionLabel="code_no"
          :filter="true"
          :placeholder="
            i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.CodeNo.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.code_no }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.code_no }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <!-- <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.CodeNo.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order_fabric.code_no"
          required="true"
        />
      </div> -->
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Color.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_purchase_order_fabric.color"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Lose.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_fabric.lose"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.OnceCostNo
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_fabric.once_cost_no"
          mode="decimal"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.OnceCostWeight
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_fabric.once_cost_weight"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly="true"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Price.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_fabric.price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Weight.Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_fabric.weight"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.TotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.add_purchase_order_fabric.total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          @uploader="onUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddPurchaseOrderFabric"
        />
      </template>
    </Dialog>
    <!-- 修改視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PurchaseOrderFabric').EditPurchaseOrderFabric.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="in_date">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.CodeNo.Show
        }}</label>
        <Dropdown
          v-model="data.SelectEditCloth"
          :options="data.selectClothList"
          optionLabel="code_no"
          :filter="true"
          :placeholder="
            i18n.$t('PurchaseOrderFabric').PurchaseOrderFabricTable.CodeNo.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.code_no }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.code_no }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Color.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.purchase_order_fabric.color"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Lose.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_fabric.lose"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.OnceCostNo
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_fabric.once_cost_no"
          mode="decimal"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.OnceCostWeight
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_fabric.once_cost_weight"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          readonly="true"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Price.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_fabric.price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.Weight.Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_fabric.weight"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PurchaseOrderFabric").PurchaseOrderFabricTable.TotalPrice
            .Show
        }}</label>
        <InputNumber
          v-model="data.purchase_order_fabric.total_price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Item").ItemTable.ImageURL.Show }}</label>
        <div v-if="data.purchase_order_fabric.image_url != ''">
          <img
            :src="data.purchase_order_fabric.image_url"
            style="width: 100%"
          />
          <Button
            :label="i18n.$t('Item').ItemTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.purchase_order_fabric.image_url = ''"
          />
        </div>
        <FileUpload
          v-else
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Item').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Item').FileLoad.cancelLabel"
          @uploader="onEditUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Item").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditPurchaseOrderFabric"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrderFabric').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.purchase_order_fabric"
          >{{ i18n.$t("PurchaseOrderFabric").Confirm.Content }}
          <b>{{ data.purchase_order_fabric.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePurchaseOrderFabric"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PurchaseOrderFabric').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("PurchaseOrderFabric").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('PurchaseOrderFabric').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPurchaseOrderFabric"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
