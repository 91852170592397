<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import * as XLSX from "xlsx/xlsx.mjs";

/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
XLSX.set_fs(fs);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);
// import Designer from "../components/Designer.vue";

import axios from "axios";
// import GC from "@grapecity/spread-sheets";

// import ExcelIO from "@grapecity/spread-excelio";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Material from "../api/material";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/user";
import firebase from "firebase";
import router from "../router";

let userApi = new User();
let materialAPI = new Material();
const store = useStore();
const dt_material = ref();

function readFile(e) {
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 0,
    });
    console.log(json);
    let importExcel = [];
    for (let i = 0; i < json.length; i++) {
      importExcel.push({
        exact_id: json[i].id != undefined ? String(json[i].id) : "",
        name: json[i].name != undefined ? json[i].name : "",
        category: json[i].category != undefined ? json[i].category : "",
        spec: json[i].spec != undefined ? json[i].spec : "",
        description:
          json[i].description != undefined ? json[i].description : "",
        define: json[i].define != undefined ? json[i].define : "",
        save_stock: json[i].save_stock != undefined ? json[i].save_stock : "",
      });
    }

    materialAPI.batchMaterial(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").AddSuccess,
          life: 3000,
        });

        data.materialLoading = true;
        getMaterialList();
        data.addExcelDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

async function saveExcelMaterial() {
  let spread = designer.getWorkbook();
  //   console.log(JSON.stringify(spread.toJSON()));
  let content = spread.toJSON();
  let detail = content.sheets.import.data.dataTable;
  let importExcel = [];
  detail = await Object.values(detail);

  for (let i = 1; i < detail.length; i++) {
    var tmp = Object.values(detail[i]);
    console.log(tmp.length);
    if (tmp.length == 7)
      importExcel.push({
        exact_id: String(tmp[0].value),
        name: String(tmp[1].value),
        category: String(tmp[2].value),
        spec: String(tmp[3].value),
        description: String(tmp[4].value),
        define: String(tmp[5].value),
        save_stock: String(tmp[6].value),
      });
  }

  materialAPI.batchMaterial(importExcel).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").AddSuccess,
        life: 3000,
      });

      data.materialLoading = true;
      getMaterialList();
      data.addExcelDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });

  //   console.log(spread.toJSON());
}

let designer = undefined;

let designerInitialized = (wb) => {
  designer = wb;

  let spread = designer.getWorkbook();
};

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  excel_material: { data: null },
  add_material: {
    id: "",
    name: "",
    category: "",
    spec: "",
    description: "",
    define: "",
    save_stock: 100000,
  },
  materialLoading: false,
  totalRecords: 0,
  addExcelDialog: false,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  materials: { data: null },
  material: null,
  // messages: [],
  page: 1,
  page_num: 10,
  selectedMaterials: null,
  materialFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    category: { value: "", matchMode: "contains" },
    spec: { value: "", matchMode: "contains" },
    define: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getMaterialList();
  }
});

function getMaterialList() {
  CheckUser();
  materialAPI
    .getMaterialList(
      data.materialFilters.id.value,
      data.materialFilters.name.value,
      data.materialFilters.category.value,
      data.materialFilters.spec.value,
      data.materialFilters.define.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.materials.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.materialLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.materialLoading = false;
      }
    });
}
function saveEditMaterial() {
  materialAPI
    .editMaterial(
      data.material.id,
      data.material.name,
      data.material.category,
      data.material.spec,
      data.material.description,
      data.material.define,
      String(data.material.save_stock)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").EditSuccess,
          life: 3000,
        });

        data.materialLoading = true;
        getMaterialList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveAddMaterial() {
  materialAPI
    .addMaterial(
      data.add_material.id,
      data.add_material.name,
      data.add_material.category,
      data.add_material.spec,
      data.add_material.description,
      data.add_material.define,
      String(data.add_material.save_stock)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Material").AddSuccess,
          life: 3000,
        });

        data.materialLoading = true;
        getMaterialList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function excelMaterial() {
  data.addExcelDialog = true;
}
function addMaterial() {
  data.addDialog = true;
}
function editMaterial(material) {
  data.material = { ...material };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(material) {
  data.material = material;
  data.deleteDialog = true;
}
function deleteMaterial() {
  materialAPI.deleteMaterial([data.material.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").DeleteSuccess,
        life: 3000,
      });

      data.materialLoading = true;
      getMaterialList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedMaterial() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedMaterials.length; i++) {
    selectDelete.push(data.selectedMaterials[i].id);
  }
  materialAPI.deleteMaterial(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Material").DeleteSuccess,
        life: 3000,
      });

      data.materialLoading = true;
      data.selectedMaterials = [];
      getMaterialList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
  // this.lazyParams = event;
  // this.loadLazyData();
}
watch(
  () => data.page,
  () => {
    data.materialLoading = true;
    getMaterialList();
  }
);
watch(
  () => data.materialFilters.name.value,
  () => {
    data.materialLoading = true;
    getMaterialList();
  }
);
watch(
  () => data.materialFilters.id.value,
  () => {
    data.materialLoading = true;
    getMaterialList();
  }
);
watch(
  () => data.materialFilters.define.value,
  () => {
    data.materialLoading = true;
    getMaterialList();
  }
);
watch(
  () => data.materialFilters.category.value,
  () => {
    data.materialLoading = true;
    getMaterialList();
  }
);
watch(
  () => data.materialFilters.spec.value,
  () => {
    data.materialLoading = true;
    getMaterialList();
  }
);
function ExportExcel() {
  materialAPI
    .getMaterialList(
      data.materialFilters.id.value,
      data.materialFilters.name.value,
      data.materialFilters.category.value,
      data.materialFilters.spec.value,
      data.materialFilters.define.value,
      1,
      5000
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // data.selectSalaryItemList = res.data.data.datas;
        let tmp = res.data.data.datas;
        // for (let i = 0; i < tmp.length; i++) {
        //   tmp[i].positive_or_negative =
        //     tmp[i].positive_or_negative == 1
        //       ? i18n.$t("SalaryItem").PositiveOrNegative.Negative
        //       : tmp[i].positive_or_negative == 2
        //       ? i18n.$t("SalaryItem").PositiveOrNegative.Positive
        //       : i18n.$t("SalaryItem").PositiveOrNegative.UnKnow;

        //   tmp[i].taxed =
        //     tmp[i].taxed == 1
        //       ? i18n.$t("SalaryItem").Taxed.Taxed
        //       : tmp[i].taxed == 2
        //       ? i18n.$t("SalaryItem").Taxed.NoTaxed
        //       : i18n.$t("SalaryItem").Taxed.UnKnow;
        // }
        data.excel_material.data = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(() => {
      dt_material.value.exportCSV();
    });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :value="data.materials.data"
      :loading="data.materialLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      showGridlines
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.materialFilters"
      v-model:selection="data.selectedMaterials"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Material").MaterialTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.materialFilters[
                        i18n.$t('Material').MaterialTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Material').MaterialTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.materialFilters[
                        i18n.$t('Material').MaterialTable.Name.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Material').MaterialTable.Name.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1 p-d-none">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.materialFilters[
                        i18n.$t('Material').MaterialTable.Category.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('Material').MaterialTable.Category.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1 p-d-none">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.materialFilters[
                        i18n.$t('Material').MaterialTable.Spec.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Material').MaterialTable.Spec.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1 p-d-none">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.materialFilters[
                        i18n.$t('Material').MaterialTable.Define.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Material').MaterialTable.Define.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Material').MaterialTable.ExportExcel"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcel"
            />
            <label class="button-up p-mr-2">
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="readFile"
              />
              <i class="pi pi-plus"></i>
              {{ i18n.$t("Material").MaterialTable.Excel }}
            </label>
            <Button
              :label="i18n.$t('Material').MaterialTable.Excel"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-d-none"
              @click="excelMaterial"
            />
            <Button
              :label="i18n.$t('Material').MaterialTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addMaterial"
            />
            <Button
              v-if="store.state.user.role == 1"
              :label="i18n.$t('Material').MaterialTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedMaterials || !data.selectedMaterials.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.ID.Field"
        :header="i18n.$t('Material').MaterialTable.ID.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.Name.Field"
        :header="i18n.$t('Material').MaterialTable.Name.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.Category.Field"
        :header="i18n.$t('Material').MaterialTable.Category.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.Spec.Field"
        :header="i18n.$t('Material').MaterialTable.Spec.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.Define.Field"
        :header="i18n.$t('Material').MaterialTable.Define.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.SafeStock.Field"
        :header="i18n.$t('Material').MaterialTable.SafeStock.Show"
      ></Column>
      <Column
        style="width: 120px; overflow: auto"
        :field="i18n.$t('Material').MaterialTable.Description.Field"
        :header="i18n.$t('Material').MaterialTable.Description.Show"
      ></Column>
      <Column style="width: 120px; overflow: auto">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editMaterial(slotProps.data)"
          />
          <Button
            v-if="store.state.user.role == 1"
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 匯入 -->
    <Dialog
      v-model:visible="data.addExcelDialog"
      :style="{ width: '850px', minWidth: '350px' }"
      :header="i18n.$t('Material').ExcelMaterial.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="id">{{ i18n.$t("Material").ExcelMaterial.Add }}</label>
        <!-- <Designer v-on:designerInitialized="designerInitialized"></Designer> -->
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addExcelDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Excel"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveExcelMaterial"
        />
      </template>
    </Dialog>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Material').AddMaterial.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="id">{{ i18n.$t("Material").AddMaterial.ID }}</label>
        <InputText
          id="id"
          v-model.trim="data.add_material.id"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Material").AddMaterial.Name }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_material.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="category">{{
          i18n.$t("Material").AddMaterial.Category
        }}</label>
        <InputText
          id="category"
          v-model.trim="data.add_material.category"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="spec">{{ i18n.$t("Material").AddMaterial.Spec }}</label>
        <InputText
          id="spec"
          v-model.trim="data.add_material.spec"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="define">{{ i18n.$t("Material").AddMaterial.Define }}</label>
        <InputText
          id="define"
          v-model.trim="data.add_material.define"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="define">{{
          i18n.$t("Material").AddMaterial.SafeStock
        }}</label>
        <InputNumber
          id="define"
          v-model="data.add_material.save_stock"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Material").AddMaterial.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_material.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddMaterial"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').EditMaterial.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="id">{{ i18n.$t("Material").EditMaterial.ID }}</label>
        <InputText
          id="id"
          v-model.trim="data.material.id"
          required="true"
          readonly
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Material").EditMaterial.Name }}</label>
        <InputText
          id="name"
          v-model.trim="data.material.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="category">{{
          i18n.$t("Material").EditMaterial.Category
        }}</label>
        <InputText
          id="category"
          v-model.trim="data.material.category"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="spec">{{ i18n.$t("Material").EditMaterial.Spec }}</label>
        <InputText
          id="spec"
          v-model.trim="data.material.spec"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="define">{{
          i18n.$t("Material").EditMaterial.Define
        }}</label>
        <InputText
          id="define"
          v-model.trim="data.material.define"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="define">{{
          i18n.$t("Material").EditMaterial.SafeStock
        }}</label>
        <InputNumber
          id="define"
          v-model="data.material.save_stock"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Material").EditMaterial.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.material.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditMaterial"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.material"
          >{{ i18n.$t("Material").Confirm.Content }}
          <b>{{ data.material.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteMaterial"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Material').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Material").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Material').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Material').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedMaterial"
        />
      </template>
    </Dialog>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel_material.data"
    ref="dt_material"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('Material').MaterialTable.ID.Field"
      :header="i18n.$t('Material').MaterialTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Material').MaterialTable.Name.Field"
      :header="i18n.$t('Material').MaterialTable.Name.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Material').MaterialTable.Category.Field"
      :header="i18n.$t('Material').MaterialTable.Category.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Material').MaterialTable.Spec.Field"
      :header="i18n.$t('Material').MaterialTable.Spec.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Material').MaterialTable.Define.Field"
      :header="i18n.$t('Material').MaterialTable.Define.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Material').MaterialTable.SafeStock.Field"
      :header="i18n.$t('Material').MaterialTable.SafeStock.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Material').MaterialTable.Description.Field"
      :header="i18n.$t('Material').MaterialTable.Description.Show"
    ></Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
