<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import SizeItem from "../../api/order/purchase_size_item";
import SizeType from "../../api/order/purchase_size_type";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let size_itemAPI = new SizeItem();
let size_typeAPI = new SizeType();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectSizeTypeList: [],
  add_size_item: {
    id: "",
    name: "",
    description: "",
  },
  size_itemLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  size_items: { data: null },
  size_item: null,
  page: 1,
  page_num: 10,
  selectedSizeItems: null,
  size_itemFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    purchase_size_type_id: { value: 0, matchMode: "contains" },
  },
});

const beforemounted = onBeforeMount(async () => {});

size_typeAPI;

const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getSizeItemList();
    getSizeTypeSelectList();
  }
});

function getSizeTypeSelectList() {
  size_typeAPI.getSizeTypeSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectSizeTypeList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getSizeItemList() {
  CheckUser();
  size_itemAPI
    .getSizeItemList(
      data.size_itemFilters.id.value,
      data.size_itemFilters.name.value,
      data.size_itemFilters.purchase_size_type_id.value != null &&
        data.size_itemFilters.purchase_size_type_id.value != "" &&
        data.size_itemFilters.purchase_size_type_id.value != undefined
        ? data.size_itemFilters.purchase_size_type_id.value.id
        : 0,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.size_items.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.size_itemLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.size_itemLoading = false;
      }
    });
}

function saveEditSizeItem() {
  size_itemAPI
    .editSizeItem(
      data.size_item.id,
      data.size_item.name,
      data.size_item.description,
      data.size_item.remark,
      data.size_item.size_type_id != null &&
        data.size_item.size_type_id != "" &&
        data.size_item.size_type_id != undefined
        ? data.size_item.size_type_id.id
        : 0
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SizeItem").EditSuccess,
          life: 3000,
        });

        data.size_itemLoading = true;
        getSizeItemList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddSizeItem() {
  size_itemAPI
    .addSizeItem(
      data.add_size_item.name,
      data.add_size_item.description,
      data.add_size_item.remark,
      data.add_size_item.size_type_id != null &&
        data.add_size_item.size_type_id != "" &&
        data.add_size_item.size_type_id != undefined
        ? data.add_size_item.size_type_id.id
        : 0
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SizeItem").AddSuccess,
          life: 3000,
        });

        data.size_itemLoading = true;
        getSizeItemList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addSizeItem() {
  data.addDialog = true;
}

function editSizeItem(size_item) {
  data.size_item = { ...size_item };
  for (let i = 0; i < data.selectSizeTypeList.length; i++) {
    if (data.selectSizeTypeList[i].id == size_item.purchase_size_type_id) {
      data.size_item.size_type_id = data.selectSizeTypeList[i];
    }
  }
  data.editDialog = true;
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(size_item) {
  data.size_item = size_item;
  data.deleteDialog = true;
}
function deleteSizeItem() {
  size_itemAPI.deleteSizeItem([data.size_item.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("SizeItem").DeleteSuccess,
        life: 3000,
      });

      data.size_itemLoading = true;
      getSizeItemList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedSizeItem() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedSizeItems.length; i++) {
    selectDelete.push(data.selectedSizeItems[i].id);
  }
  size_itemAPI.deleteSizeItem(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("SizeItem").DeleteSuccess,
        life: 3000,
      });

      data.SizeItemLoading = true;
      data.selectedSizeItems = [];
      getSizeItemList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.size_itemLoading = true;
    getSizeItemList();
  }
);
watch(
  () => data.size_itemFilters.name.value,
  () => {
    data.size_itemLoading = true;
    getSizeItemList();
  }
);
watch(
  () => data.size_itemFilters.id.value,
  () => {
    data.size_itemLoading = true;
    getSizeItemList();
  }
);
watch(
  () => data.size_itemFilters.purchase_size_type_id.value,
  () => {
    data.size_itemLoading = true;
    getSizeItemList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.size_items.data"
      :loading="data.size_itemLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.size_itemFilters"
      v-model:selection="data.selectedSizeItems"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("SizeItem").SizeItemTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.size_itemFilters.purchase_size_type_id.value"
                    :options="data.selectSizeTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.size_itemFilters[
                        i18n.$t('SizeItem').SizeItemTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('SizeItem').SizeItemTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.size_itemFilters[
                        i18n.$t('SizeItem').SizeItemTable.Name.Field
                      ].value
                    "
                    :placeholder="i18n.$t('SizeItem').SizeItemTable.Name.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('SizeItem').SizeItemTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addSizeItem"
            />
            <Button
              :label="i18n.$t('SizeItem').SizeItemTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedSizeItems || !data.selectedSizeItems.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('SizeItem').SizeItemTable.ID.Field"
        :header="i18n.$t('SizeItem').SizeItemTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SizeItem').SizeItemTable.Name.Field"
        :header="i18n.$t('SizeItem').SizeItemTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Field"
        :header="i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SizeItem').SizeItemTable.Description.Field"
        :header="i18n.$t('SizeItem').SizeItemTable.Description.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SizeItem').SizeItemTable.Remark.Field"
        :header="i18n.$t('SizeItem').SizeItemTable.Remark.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editSizeItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('SizeItem').AddSizeItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="iteam">{{
          i18n.$t("SizeItem").SizeItemTable.PurchaseSizeType.Show
        }}</label>
        <Dropdown
          v-model="data.add_size_item.size_type_id"
          :options="data.selectSizeTypeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SizeItem").SizeItemTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_size_item.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SizeItem").SizeItemTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_size_item.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SizeItem").SizeItemTable.Remark.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_size_item.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SizeItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('SizeItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddSizeItem"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SizeItem').EditSizeItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="iteam">{{
          i18n.$t("SizeItem").SizeItemTable.PurchaseSizeType.Show
        }}</label>
        <Dropdown
          v-model="data.size_item.size_type_id"
          :options="data.selectSizeTypeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('SizeItem').SizeItemTable.PurchaseSizeType.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SizeItem").SizeItemTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.size_item.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SizeItem").SizeItemTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.size_item.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SizeItem").SizeItemTable.Remark.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.size_item.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SizeItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('SizeItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditSizeItem"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SizeItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.size_item"
          >{{ i18n.$t("SizeItem").Confirm.Content }}
          <b>{{ data.size_item.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SizeItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('SizeItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSizeItem"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SizeItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("SizeItem").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SizeItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('SizeItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedSizeItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
