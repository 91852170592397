import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Transfer {
  getTransferList(
    source_storage_id,
    source_factory_id,
    target_factory_id,
    source_warehouse_id,
    target_warehouse_id,
    status,
    transfer_date_begin,
    transfer_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/transfer/list`,
      {
        params: {
          source_storage_id: source_storage_id,
          source_factory_id: source_factory_id,
          target_factory_id: target_factory_id,
          source_warehouse_id: source_warehouse_id,
          target_warehouse_id: target_warehouse_id,
          status: status,
          transfer_date_begin: transfer_date_begin,
          transfer_date_end: transfer_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  transferBatch(datas) {
    return axios.post(
      `/api/transfer/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  transfer(
    source_storage_id,
    iteam_id,
    target_factory_id,
    target_warehouse_id,
    status,
    transfer_date
  ) {
    return axios.post(
      `/api/transfer`,
      {
        source_storage_id: source_storage_id,
        iteam_id: iteam_id,
        target_factory_id: target_factory_id,
        target_warehouse_id: target_warehouse_id,
        status: status,
        transfer_date: transfer_date,
      },
      config
    );
  }
}
