import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class WarehousePurchaseOrder {
  getWarehousePurchaseOrderInfo(id) {
    return axios.get(
      `/api/warehouse-purchase-order/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getWarehousePurchaseOrderList(
    id,
    name,
    status,
    warehouse_supplier_id,
    factory_id,
    created_at_begin,
    created_at_end,
    finished_date_begin,
    finished_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/warehouse-purchase-order/list`,
      {
        params: {
          id: id,
          name: name,
          warehouse_supplier_id: warehouse_supplier_id,
          factory_id: factory_id,
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          finished_date_begin: finished_date_begin,
          finished_date_end: finished_date_end,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchWarehousePurchaseOrder(datas) {
    return axios.post(
      `/api/batch/warehouse-purchase-order`,
      {
        datas: datas,
      },
      config
    );
  }
  addWarehousePurchaseOrder(
    name,
    remark,
    description,
    warehouse_supplier_id,
    factory_id
  ) {
    return axios.post(
      `/api/warehouse-purchase-order`,
      {
        name: name,
        remark: remark,
        description: description,
        warehouse_supplier_id: warehouse_supplier_id,
        factory_id: factory_id,
      },
      config
    );
  }
  editWarehousePurchaseOrder(
    id,
    name,
    remark,
    description,
    warehouse_supplier_id,
    factory_id
  ) {
    return axios.put(
      `/api/warehouse-purchase-order`,
      {
        id: id,
        name: name,
        remark: remark,
        description: description,
        warehouse_supplier_id: warehouse_supplier_id,
        factory_id: factory_id,
      },
      config
    );
  }
  editWarehousePurchaseOrderStatus(id, status) {
    return axios.put(
      `/api/warehouse-purchase-order`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  deleteWarehousePurchaseOrder(id) {
    return axios.delete(
      `/api/warehouse-purchase-order`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
