import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ProductGroup {
  getProductGroupInfo(id) {
    return axios.get(
      `/api/order/product-group/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getProductGroupList(id, name, page, page_num) {
    return axios.get(
      `/api/order/product-group/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getProductGroupSelectList(name) {
    return axios.get(
      `/api/order/product-group/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  getProductGroupCustomerSelectList(customer_id) {
    return axios.get(
      `/api/order/product-group/customer/select-list`,
      {
        params: {
          customer_id: customer_id,
        },
      },
      config
    );
  }
  batchProductGroup(datas) {
    return axios.post(
      `/api/order/batch/product-group`,
      {
        datas: datas,
      },
      config
    );
  }
  addProductGroup(name, description) {
    return axios.post(
      `/api/order/product-group`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editProductGroup(id, name, description) {
    return axios.put(
      `/api/order/product-group`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteProductGroup(id) {
    return axios.delete(
      `/api/order/product-group`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
