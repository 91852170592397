import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import Home from "../views/Home.vue";
import Material from "../views/Material.vue";
import Stock from "../views/Stock.vue";
import StockTotal from "../views/StockTotal.vue";
import StockLastTotal from "../views/StockLastTotal.vue";
import StockSave from "../views/StockSave.vue";
import SettingFactory from "../views/SettingFactory.vue";
import SettingWarehouse from "../views/SettingWarehouse.vue";
import TransferLog from "../views/TransferLog.vue";

import Station from "../views/order/Station.vue";
import Agent from "../views/order/Agent.vue";
import Cloth from "../views/order/Cloth.vue";
import CmtFactory from "../views/order/CmtFactory.vue";
import Customer from "../views/order/Customer.vue";
import Item from "../views/order/Item.vue";
import ProductGroup from "../views/order/ProductGroup.vue";
import Supplier from "../views/order/Supplier.vue";
import Order from "../views/order/Order.vue";
import User from "../views/user/User.vue";
import WarehouseSupplier from "../views/WarehouseSupplier.vue";
import WarehousePurchaseOrder from "../views/WarehousePurchaseOrder.vue";

import SizeItem from "../views/order/SizeItem.vue";
import SizeType from "../views/order/SizeType.vue";

const routes = [
  {
    path: "/warehouse-order",
    name: "WarehousePurchaseOrder",
    component: WarehousePurchaseOrder,
    children: [
      {
        path: "order", // url= shop/cart
        component: WarehousePurchaseOrder,
        // meta: { requiresAuth: false },
      },
      {
        path: "content", // url= shop/cart
        component: WarehousePurchaseOrder,
        // meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/size-item",
    name: "SizeItem",
    component: SizeItem,
  },
  {
    path: "/size-type",
    name: "SizeType",
    component: SizeType,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
  },
  {
    path: "/user",
    name: "User",
    component: User,
  },
  {
    path: "/order-station",
    name: "Station",
    component: Station,
  },
  {
    path: "/order-agent",
    name: "Agent",
    component: Agent,
  },
  {
    path: "/order-cloth",
    name: "Cloth",
    component: Cloth,
  },
  {
    path: "/order-cmtFactory",
    name: "CmtFactory",
    component: CmtFactory,
  },
  {
    path: "/order-customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/order-item",
    name: "Item",
    component: Item,
  },
  {
    path: "/order-product-group",
    name: "ProductGroup",
    component: ProductGroup,
  },
  {
    path: "/order-supplier",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/",
    name: "Material",
    component: Material,
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
  },
  {
    path: "/total",
    name: "StockTotal",
    component: StockTotal,
  },
  {
    path: "/last-total",
    name: "StockLastTotal",
    component: StockLastTotal,
  },
  {
    path: "/stock-save",
    name: "StockSave",
    component: StockSave,
  },
  {
    path: "/setting-factory",
    name: "SettingFactory",
    component: SettingFactory,
  },
  {
    path: "/setting-warehouse",
    name: "SettingWarehouse",
    component: SettingWarehouse,
  },
  {
    path: "/setting-supplier",
    name: "WarehouseSupplier",
    component: WarehouseSupplier,
  },
  {
    path: "/transfer-log",
    name: "TransferLog",
    component: TransferLog,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
