<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import FaverSaver from "file-saver";
// import XLSX from "xlsx";
// import XLSX from "xlsx";
// import ExcelIO from "@grapecity/spread-excelio";
import InputNumber from "primevue/inputNumber";
import * as XLSX from "xlsx/xlsx.mjs";
import { QrcodeStream } from "vue3-qrcode-reader";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Material from "../api/material";
import Stock from "../api/stock";
import QRCodeVue3 from "qrcode-vue3";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/user";
import firebase from "firebase";
import Warehouse from "../api/warehouse";
import WarehouseSupplier from "../api/warehouse_supplier";
import Factory from "../api/factory";
import Transfer from "../api/transfer";
import router from "../router";

let transferAPI = new Transfer();
let factoryAPI = new Factory();
let warehouseAPI = new Warehouse();
let warehouse_supplierAPI = new WarehouseSupplier();

let userApi = new User();
let materialAPI = new Material();
let stockAPI = new Stock();
const store = useStore();
const printDom = ref(null);
function printHTML(dom) {
  var newWindow = window.open("Print", "_blank");
  var docStr = dom.innerHTML;
  newWindow.document.write(docStr);
  newWindow.document.close();
  newWindow.print();
  newWindow.close();
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  excelTotal: 0,
  download: false,
  excel: [],
  nowDate: "",
  outData: null,
  iteamSearch: "",
  warehouseSearch: "",
  transferFactorySearch: "",
  transferWarehouseSearch: "",
  factorySearch: "",
  add_stock_supplier: "",
  add_stock_warehouse: "",
  add_stock_factory: "",
  statusSearch: "",
  in_date_search: "",
  out_date_search: "",
  outJson: "",
  out_stock: {
    id: "",
    factory: "",
    iteam: "",
    chinese: "",
    english: "",
    weight: "",
    count: "",
    in_date: "",
    out_date: "",
    status: 1,
    remark: "",
  },
  selectIteamList: [],
  selectFactoryList: [],
  selectWarehouseList: [],
  selectWarehouseAddList: [],
  selectTransferWarehouseList: [],
  qrCode: "",
  print_total_stock: [],
  transfer: {
    iteam: 0,
    source_storage_id: 0,
    target_storage_id: 0,
    source_factory_id: 0,
    target_factory_id: 0,
    source_warehouse_id: 0,
    target_warehouse_id: 0,
    status: {
      name: i18n.$t("Stock").TransferStock.Transfer,
      value: 1,
    },
    transfer_date: "",
  },
  print_stock: {
    factory: "",
    iteam: "",
    chinese: "",
    english: "",
    weight: "",
    count: "",
    in_date: "",
    out_date: "",
    status: 2,
    remark: "",
    warehouse_supplier_id: "",
    unit_dollar: 0,
  },
  add_stock: {
    factory: "",
    iteam: "",
    chinese: "",
    english: "",
    weight: "",
    count: "",
    in_date: "",
    out_date: "",
    status: 2,
    remark: "",
    warehouse_supplier_id: "",
    unit_dollar: 0,
  },
  stockLoading: false,
  totalRecords: 0,
  transferDialog: false,
  addDialog: false,
  printDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  stocks: { data: null },
  stock: null,
  transferStockDetail: null,
  // messages: [],
  page: 1,
  page_num: 100,
  selectedStocks: null,
  stockFilters: {
    id: { value: "", matchMode: "contains" },
    iteam: { value: "", matchMode: "contains" },
    chinese: { value: "", matchMode: "contains" },
    english: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    warehouse_id: { value: "", matchMode: "contains" },
    in_date_begin: { value: "", matchMode: "contains" },
    in_date_end: { value: "", matchMode: "contains" },
    out_date_begin: { value: "", matchMode: "contains" },
    out_date_end: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getStockList();
    getIteamSelectList();
    getFactorySelectList();
    getWarehouseSelectList();
    getWarehouseSupplierSelectList();
  }
  // console.log(dateFormate("2022-06-02T16:00:00.000Z"));
  console.log(dateFormate("2022-06-03T00:00:00+08:00"));
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function readFile(e) {
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 1,
    });
    console.log(json);
    let importExcel = [];
    let factory_id = 0;
    let warehouse_id = 0;
    let iteam_id = "";
    let english = "";

    for (let i = 0; i < json.length; i++) {
      factory_id = 0;
      warehouse_id = 0;
      iteam_id = "";
      english = "";
      //     selectFactoryList: [],
      // selectWarehouseList: [],
      for (let j = 0; j < data.selectFactoryList.length; j++) {
        if (data.selectFactoryList[j].name == json[i].factory) {
          factory_id = data.selectFactoryList[j].id;
          break;
        }
      }
      for (let j = 0; j < data.selectWarehouseList.length; j++) {
        if (data.selectWarehouseList[j].name == json[i].warehouse) {
          warehouse_id = data.selectWarehouseList[j].id;
          break;
        }
      }
      for (let j = 0; j < data.selectIteamList.length; j++) {
        if (data.selectIteamList[j].id == json[i].iteam) {
          iteam_id = data.selectIteamList[j].id;
          english = data.selectIteamList[j].name;
          break;
        }
      }

      importExcel.push({
        factory_id: factory_id,
        warehouse_id: warehouse_id,
        iteam: iteam_id,
        chinese: json[i].chinese != undefined ? json[i].chinese : "",
        english: english,
        weight: json[i].weight != undefined ? String(json[i].weight) : "",
        weight_unit:
          json[i].weight_unit != undefined ? json[i].weight_unit : "",
        count: json[i].count != undefined ? String(json[i].count) : "",
        in_date: new Date(json[i].in_date).toISOString(),
        out_date: "",
        status: 2,
        remark: json[i].remark != undefined ? json[i].remark : "",
        warehouse_supplier_name:
          json[i].warehouse_supplier_name != undefined
            ? json[i].warehouse_supplier_name
            : "",
        unit_dollar:
          json[i].unit_dollar != undefined ? String(json[i].unit_dollar) : "",
      });
    }

    stockAPI.addTotalStorage(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Stock").AddSuccess,
          life: 3000,
        });

        data.stockLoading = true;

        getStockList();
        // data.printTotalDialog = true;
        // let tmp = [];
        // for (let i = 0; i < data.selectedStocks.length; i++) {
        //   tmp.push({
        //     id: data.selectedStocks[i].id,
        //     factory_name: data.selectedStocks[i].factory_name,
        //     warehouse_name: data.selectedStocks[i].warehouse_name,
        //     iteam: data.selectedStocks[i].iteam,
        //     chinese: data.selectedStocks[i].chinese,
        //     english: data.selectedStocks[i].english,
        //     weight: data.selectedStocks[i].weight,
        //     weight_unit: data.selectedStocks[i].weight_unit,
        //     count: data.selectedStocks[i].count,
        //     in_date: data.selectedStocks[i].in_date,
        //     out_date: data.selectedStocks[i].out_date,
        //     status: 2,
        //     remark: data.selectedStocks[i].remark,
        //   });
        // }
        // data.print_total_stock = tmp;
        // data.addExcelDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}
function excelStock() {
  CheckUser();
  data.excelTotal = 0;
  data.download = false;
  data.excel = [];
  data.nowDate = "";
  stockAPI
    .getStorageList(
      data.stockFilters.iteam.value,
      data.stockFilters.chinese.value,
      data.stockFilters.english.value,
      data.stockFilters.factory_id.value,
      data.stockFilters.warehouse_id.value,
      data.stockFilters.in_date_begin.value,
      data.stockFilters.in_date_end.value,
      data.stockFilters.out_date_begin.value,
      data.stockFilters.out_date_end.value,
      data.stockFilters.status.value,
      1,
      10000
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // let dataTotal = res.data.data.total_count;
        // console.log(res.data);
        var Today = new Date();
        let filename =
          Today.getFullYear() +
          "/" +
          (Today.getMonth() + 1) +
          "/" +
          Today.getDate();
        data.nowDate = filename;
        for (let i = 0; i < res.data.data.datas.length; i++) {
          res.data.data.datas[i].status =
            res.data.data.datas[i].status == 1
              ? "出庫"
              : res.data.data.datas[i].status == 2
              ? "入庫"
              : "未知";
          res.data.data.datas[i].unit_dollar =
            parseFloat(res.data.data.datas[i].unit_dollar) *
            parseFloat(res.data.data.datas[i].count);
        }
        data.excel = res.data.data.datas;
        data.excelTotal = res.data.data.total_count;
        data.download = true;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function getWarehouseSupplierSelectList() {
  warehouse_supplierAPI.getWarehouseSupplierSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectWarehouseSupplierAddList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getWarehouseAddSelectList() {
  let factory = data.add_stock.factory_id == "" ? 0 : data.add_stock.factory_id;
  warehouseAPI.getWarehouseSelectList("", factory).then((res) => {
    if (res.data.error_code == 0) {
      data.selectWarehouseAddList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getWarehouseSelectList() {
  let factory =
    data.stockFilters.factory_id.value == ""
      ? 0
      : data.stockFilters.factory_id.value;
  console.log(factory);
  warehouseAPI.getWarehouseSelectList("", factory).then((res) => {
    if (res.data.error_code == 0) {
      data.selectWarehouseList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getIteamSelectList() {
  materialAPI.getMaterialSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectIteamList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getStockList() {
  CheckUser();
  stockAPI
    .getStorageList(
      data.stockFilters.iteam.value,
      data.stockFilters.chinese.value,
      data.stockFilters.english.value,
      data.stockFilters.factory_id.value,
      data.stockFilters.warehouse_id.value,
      data.stockFilters.in_date_begin.value,
      data.stockFilters.in_date_end.value,
      data.stockFilters.out_date_begin.value,
      data.stockFilters.out_date_end.value,
      data.stockFilters.status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.stocks.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.stockLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.stockLoading = false;
      }
    });
}
function saveEditStock() {
  stockAPI
    .editStorageCount(
      data.stock.id,
      data.stock.count,
      data.stock.weight,
      String(data.stock.unit_dollar)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Stock").EditSuccess,
          life: 3000,
        });

        data.stockLoading = true;
        getStockList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function printTotalStock() {
  console.log(data.selectedStocks);
  data.printTotalDialog = true;
  let tmp = [];
  for (let i = 0; i < data.selectedStocks.length; i++) {
    tmp.push({
      id: data.selectedStocks[i].id,
      factory_name: data.selectedStocks[i].factory_name,
      warehouse_name: data.selectedStocks[i].warehouse_name,
      iteam: data.selectedStocks[i].iteam,
      chinese: data.selectedStocks[i].chinese,
      english: data.selectedStocks[i].english,
      weight: data.selectedStocks[i].weight,
      weight_unit: data.selectedStocks[i].weight_unit,
      count: data.selectedStocks[i].count,
      in_date: data.selectedStocks[i].in_date,
      out_date: data.selectedStocks[i].out_date,
      status: 2,
      remark: data.selectedStocks[i].remark,
      warehouse_supplier_name: data.selectedStocks[i].warehouse_supplier_name,
      unit_dollar: data.selectedStocks[i].unit_dollar,
    });
  }
  data.print_total_stock = tmp;
}

function confirmDeleteSelectedTotal() {
  data.deleteSelectedTotalDialog = true;
}

function deleteStockTotal() {
  CheckUser();
  const date = new Date();
  let ids = [];
  console.log(data.selectedStocks);
  for (let i = 0; i < data.selectedStocks.length; i++) {
    if (data.selectedStocks[i].status != 1) ids.push(data.selectedStocks[i].id);
  }

  stockAPI.editTotalStorage(ids, date.toISOString(), 1).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Stock").DeleteSuccess,
        life: 3000,
      });

      data.stockLoading = true;
      data.selectedStocks = [];
      getStockList();
      data.deleteSelectedTotalDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function saveAddTotalStock() {
  const date = new Date();
  let tmpData = [];
  for (let i = 0; i < data.add_stock.count; i++) {
    tmpData.push({
      factory_id:
        data.add_stock.factory_id == "" ? 0 : data.add_stock.factory_id,
      warehouse_id:
        data.add_stock.warehouse_id == "" ? 0 : data.add_stock.warehouse_id,
      iteam:
        data.add_stock.iteam.id == undefined
          ? ""
          : String(data.add_stock.iteam.id),
      chinese: data.add_stock.chinese,
      english: data.add_stock.english,
      weight: data.add_stock.weight,
      weight_unit: data.add_stock.weight_unit,
      count: "1",
      in_date: date.toISOString(),
      out_date: "",
      status: 2,
      remark: data.add_stock.remark,
      warehouse_supplier_id: data.add_stock.warehouse_supplier_id,
      unit_dollar: String(data.add_stock.unit_dollar),
    });
  }
  stockAPI.addTotalStorage(tmpData).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Stock").AddSuccess,
        life: 3000,
      });
      console.log("test");
      console.log(res.data);
      data.printTotalDialog = true;

      //   data.print_total_stock = res.data.data;
      let tmp = [];
      let warehouse_name = "";
      for (let i = 0; i < data.selectWarehouseList.length; i++) {
        if (data.selectWarehouseList[i].id == data.add_stock.warehouse_id) {
          warehouse_name = data.selectWarehouseList[i].name;
        }
      }
      let factory_name = "";
      for (let i = 0; i < data.selectFactoryList.length; i++) {
        if (data.selectFactoryList[i].id == data.add_stock.factory_id) {
          factory_name = data.selectFactoryList[i].name;
        }
      }
      let warehouse_supplier_name = "";
      for (let i = 0; i < data.selectWarehouseSupplierAddList.length; i++) {
        if (
          data.selectWarehouseSupplierAddList[i].id ==
          data.add_stock.warehouse_supplier_id
        ) {
          warehouse_supplier_name = data.selectWarehouseSupplierAddList[i].name;
        }
      }
      for (let i = 0; i < res.data.data.datas.length; i++) {
        tmp.push({
          id: res.data.data.datas[i].id,
          factory_id: res.data.data.datas[i].factory_id,
          warehouse_id: res.data.data.datas[i].warehouse_id,

          factory_name: factory_name,
          warehouse_name: warehouse_name,

          iteam: res.data.data.datas[i].iteam,
          chinese: res.data.data.datas[i].chinese,
          english: res.data.data.datas[i].english,
          weight: res.data.data.datas[i].weight,
          weight_unit: res.data.data.datas[i].weight_unit,
          count: res.data.data.datas[i].count,
          in_date: res.data.data.datas[i].in_date,
          out_date: res.data.data.datas[i].out_date,
          status: 2,
          remark: res.data.data.datas[i].remark,
          warehouse_supplier_name: warehouse_supplier_name,
          unit_dollar: String(res.data.data.datas[i].unit_dollar),
        });
      }
      data.print_total_stock = tmp;
      //需補id
      data.add_stock = {
        factory_id: "",
        warehosue_id: "",
        iteam: "",
        chinese: "",
        english: "",
        weight: "",
        weight_unit: "",
        count: "",
        in_date: "",
        out_date: "",
        status: 2,
        remark: "",
        warehouse_supplier_name: "",
        warehouse_supplier_id: "",
        unit_dollar: 0,
      };
      data.stockLoading = true;
      getStockList();
      data.addTotalDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function saveAddStock() {
  const date = new Date();
  stockAPI
    .addStorage(
      data.add_stock.factory_id == "" ? 0 : data.add_stock.factory_id,
      data.add_stock.warehouse_id == "" ? 0 : data.add_stock.warehouse_id,
      data.add_stock.iteam.id,
      data.add_stock.chinese,
      data.add_stock.english,
      data.add_stock.weight,
      data.add_stock.weight_unit,
      data.add_stock.count,
      date.toISOString(),
      "",
      2,
      data.add_stock.remark,
      data.add_stock.warehouse_supplier_id,
      String(data.add_stock.unit_dollar)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Stock").AddSuccess,
          life: 3000,
        });
        console.log(res.data);
        data.printDialog = true;
        let warehouse_supplier_name = "";
        for (let i = 0; i < data.selectWarehouseSupplierAddList.length; i++) {
          if (
            data.selectWarehouseSupplierAddList[i].id ==
            data.add_stock.warehouse_supplier_id
          ) {
            warehouse_supplier_name =
              data.selectWarehouseSupplierAddList[i].name;
          }
        }
        let warehouse_name = "";
        for (let i = 0; i < data.selectWarehouseList.length; i++) {
          if (data.selectWarehouseList[i].id == data.add_stock.warehouse_id) {
            warehouse_name = data.selectWarehouseList[i].name;
          }
        }
        let factory_name = "";
        for (let i = 0; i < data.selectFactoryList.length; i++) {
          if (data.selectFactoryList[i].id == data.add_stock.factory_id) {
            factory_name = data.selectFactoryList[i].name;
          }
        }
        data.print_stock = {
          id: res.data.data.id,
          factory_name: factory_name,
          warehouse_name: warehouse_name,
          iteam: data.add_stock.iteam.id,
          chinese: data.add_stock.chinese,
          english: data.add_stock.english,
          weight: data.add_stock.weight,
          weight_unit: data.add_stock.weight_unit,
          count: data.add_stock.count,
          in_date: data.add_stock.in_date,
          out_date: data.add_stock.out_date,
          status: 2,
          remark: data.add_stock.remark,
          warehouse_supplier_name: warehouse_supplier_name,
          warehouse_supplier_id: data.add_stock.warehouse_supplier_id,
          unit_dollar: data.add_stock.unit_dollar,
        };
        //需補id
        data.add_stock = {
          factory_id: "",
          warehouse_id: "",
          iteam: "",
          chinese: "",
          english: "",
          weight: "",
          weight_unit: "",
          count: "",
          in_date: "",
          out_date: "",
          status: 2,
          remark: "",
          warehouse_supplier_name: "",
          warehouse_supplier_id: "",
          unit_dollar: 0,
        };
        data.stockLoading = true;
        getStockList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function addTotalStock() {
  var Today = new Date();
  data.addTotalDialog = true;
  data.add_stock_warehouse = "";
  data.add_stock_factory = "";
  data.add_stock_supplier = "";
  data.add_stock = {
    factory_id: "",
    warehouse_id: "",
    iteam: "",
    chinese: "",
    english: "",
    weight: "",
    weight_unit: "",
    count: "",
    in_date:
      Today.getFullYear() +
      "/" +
      (Today.getMonth() + 1) +
      "/" +
      Today.getDate(),
    out_date: "",
    status: 2,
    remark: "",
    warehouse_supplier_name: "",
    warehouse_supplier_id: "",
    unit_dollar: 0,
  };
}

function addStock() {
  var Today = new Date();
  data.add_stock_warehouse = "";
  data.add_stock_factory = "";
  data.add_stock_supplier = "";
  data.addDialog = true;
  data.add_stock = {
    factory_id: "",
    warehouse_id: "",
    iteam: "",
    chinese: "",
    english: "",
    weight: "",
    weight_unit: "",
    count: "",
    in_date:
      Today.getFullYear() +
      "/" +
      (Today.getMonth() + 1) +
      "/" +
      Today.getDate(),
    out_date: "",
    status: 2,
    remark: "",
    warehouse_supplier_name: "",
    warehouse_supplier_id: "",
    unit_dollar: 0,
  };
}

function editStock(stock) {
  data.stock = { ...stock };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.outData = null;
  data.outJson = "";
  data.out_stock = {
    id: "",
    factory_id: "",
    warehouse_id: "",
    iteam: "",
    chinese: "",
    english: "",
    weight: "",
    weight_unit: "",
    count: "",
    in_date: "",
    out_date: "",
    status: 1,
    remark: "",
    warehouse_supplier_name: "",
    warehouse_supplier_id: "",
    unit_dollar: 0,
  };
  data.deleteSelectedDialog = true;
}
function confirmDelete(stock) {
  data.stock = stock;
  data.deleteDialog = true;
}

function transferStock(stock) {
  // console.log(stock);
  data.transfer_batch_check = false;
  let iteam = 0;
  // console.log(data.selectIteamList);
  for (let i = 0; i < data.selectIteamList.length; i++) {
    if (data.selectIteamList[i].id == stock.iteam)
      iteam = data.selectIteamList[i];
  }
  // console.log(iteam);
  CheckUser();
  data.transferDialog = true;
  data.transferStockDetail = stock;
  data.transferWarehouseSearch = null;
  data.transfer = {
    iteam: iteam,
    source_storage_id: stock.id,
    target_storage_id: 0,
    source_factory_id: stock.factory_id,
    target_factory_id: stock.factory_id,
    source_warehouse_id: stock.warehouse_id,
    target_warehouse_id: 0,
    status: {
      name: i18n.$t("Stock").TransferStock.Transfer,
      value: 1,
    },
    transfer_date: new Date(),
  };

  data.transferFactorySearch = {
    name: stock.factory_name,
    value: stock.factory_id,
  };
  getWarehouseTransferSelectList();
}

function transferStockBatch() {
  CheckUser();
  data.transferDialog = true;
  data.transfer_batch_check = true;
  data.transfer = {
    target_factory_id: data.selectedStocks[0].factory_id,
    status: {
      name: i18n.$t("Stock").TransferStock.Transfer,
      value: 1,
    },
    transfer_date: new Date(),
  };
  getWarehouseTransferSelectList();
}

function getWarehouseTransferSelectList() {
  let factory =
    data.transfer.target_factory_id == "" ? 0 : data.transfer.target_factory_id;

  warehouseAPI.getWarehouseSelectList("", factory).then((res) => {
    if (res.data.error_code == 0) {
      data.selectTransferWarehouseList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function saveTransferStockBatch() {
  let tmp_data = [];
  for (let i = 0; i < data.selectedStocks.length; i++) {
    if (
      data.selectedStocks[0].factory_id == data.selectedStocks[i].factory_id
    ) {
      tmp_data.push({
        source_storage_id: data.selectedStocks[i].id,
        iteam_id: data.selectedStocks[i].iteam,
        target_factory_id: data.selectedStocks[i].factory_id,
        target_warehouse_id: data.transfer.target_warehouse_id,
        status: data.transfer.status.value,
        transfer_date: data.transfer.transfer_date.toISOString(),
      });
    }
  }
  transferAPI.transferBatch(tmp_data).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Stock").TransferSuccess,
        life: 3000,
      });
      getStockList();
      data.transferDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function saveTransferStock() {
  // data.transfer = {
  //   source_storage_id: stock.id,
  //   target_storage_id: 0,
  //   source_factory_id: stock.factory_id,
  //   target_factory_id: stock.factory_id,
  //   source_warehouse_id: stock.warehouse_id,
  //   target_warehouse_id: 0,
  //   status: {
  //     name: i18n.$t("Stock").TransferStock.Transfer,
  //     value: 1,
  //   },
  //   transfer_date: new Date(),
  // };
  // console.log(data.transfer);
  transferAPI
    .transfer(
      data.transfer.source_storage_id,
      data.transfer.iteam.id,
      data.transfer.target_factory_id,
      data.transfer.target_warehouse_id,
      data.transfer.status.value,
      data.transfer.transfer_date.toISOString()
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Stock").TransferSuccess,
          life: 3000,
        });
        getStockList();
        console.log(res.data.data.new_storage);
        if (res.data.data.new_storage != null) {
          printStock(res.data.data.new_storage);
        }

        data.transferDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function printStock(stock) {
  data.printDialog = true;
  data.print_stock = {
    id: stock.id,
    factory_name: stock.factory_name,
    warehouse_name: stock.warehouse_name,
    iteam: stock.iteam,
    chinese: stock.chinese,
    english: stock.english,
    weight: stock.weight,
    weight_unit: stock.weight_unit,
    count: stock.count,
    in_date: stock.in_date,
    out_date: stock.out_date,
    status: 2,
    remark: stock.remark,
    warehouse_supplier_name: stock.warehouse_supplier_name,
    warehouse_supplier_id: stock.warehouse_supplier_id,
    unit_dollar: stock.unit_dollar,
  };
}

function deleteStock() {
  stockAPI.deleteStorage(data.stock.id).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Stock").DeleteStockSuccess,
        life: 3000,
      });

      data.stockLoading = true;
      getStockList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedStock() {
  const date = new Date();
  stockAPI.editStorage(data.out_stock.id, date.toISOString(), 1).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Stock").DeleteSuccess,
        life: 3000,
      });

      data.stockLoading = true;
      data.selectedStocks = [];
      getStockList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
  // this.lazyParams = event;
  // this.loadLazyData();
}
watch(
  () => data.add_stock.iteam,
  () => {
    if (data.add_stock.iteam == null) {
      data.add_stock.english = "";
    } else {
      data.add_stock.english = data.add_stock.iteam.name;
    }
  }
);
watch(
  () => data.outJson,
  () => {
    var c = null;
    data.outData = null;
    data.out_stock = {
      id: "",
      factory_name: "",
      iteam: "",
      chinese: "",
      english: "",
      weight: "",
      weight_unit: "",
      count: "",
      in_date: "",
      out_date: "",
      status: 1,
      remark: "",
    };
    try {
      let tmp = JSON.parse(data.outJson);
      stockAPI.getStorageInfo(tmp.id).then((res) => {
        if (res.data.error_code == 0) {
          if (res.data.data.status == 2) {
            var Today = new Date();
            data.outData = res.data.data;
            console.log(res.data.data);
            data.out_stock = {
              id: data.outData.id, //4,
              factory_id: data.outData.factory_id,
              warehouse_id: data.outData.warehouse_id,
              iteam: data.outData.iteam,
              chinese: data.outData.chinese,
              english: data.outData.english,
              weight: data.outData.weight,
              weight_unit: data.outData.weight_unit,
              count: data.outData.count,
              in_date: data.outData.in_date,
              out_date:
                Today.getFullYear() +
                "/" +
                (Today.getMonth() + 1) +
                "/" +
                Today.getDate(),
              status: 1,
              remark: data.outData.remark,
            };
            // deleteSelectedStock();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: i18n.$t("Stock").AlreadyOut,
              life: 3000,
            });
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
    } catch (d) {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: "Wrong Template",
        life: 3000,
      });
    }
  }
);
watch(
  () => data.page,
  () => {
    data.stockLoading = true;
    getStockList();
  }
);
watch(
  () => data.stockFilters.chinese.value,
  () => {
    data.stockLoading = true;
    getStockList();
  }
);
// watch(
//   () => data.stockFilters.factory.value,
//   () => {
//     data.stockLoading = true;
//     getStockList();
//   }
// );
watch(
  () => data.stockFilters.english.value,
  () => {
    data.stockLoading = true;
    getStockList();
  }
);
watch(
  () => data.statusSearch,
  () => {
    data.stockLoading = true;
    data.stockFilters.status.value =
      data.statusSearch == null ? "" : data.statusSearch.value;
    getStockList();
  }
);
watch(
  () => data.factorySearch,
  () => {
    data.stockLoading = true;
    data.warehouseSearch = null;
    data.stockFilters.warehouse_id.valued = "";
    data.stockFilters.factory_id.value =
      data.factorySearch == null ? "" : data.factorySearch.id;
    getStockList();
    getWarehouseSelectList();
  }
);
watch(
  () => data.warehouseSearch,
  () => {
    data.stockLoading = true;
    data.stockFilters.warehouse_id.value =
      data.warehouseSearch == null ? "" : data.warehouseSearch.id;
    getStockList();
    // getWarehouseSelectList();
  }
);
watch(
  () => data.add_stock_warehouse,
  () => {
    data.add_stock.warehouse_id =
      data.add_stock_warehouse == null ? "" : data.add_stock_warehouse.id;
  }
);
watch(
  () => data.add_stock_factory,
  () => {
    data.add_stock_warehouse = null;
    data.add_stock.warehouse_id = "";
    data.add_stock.factory_id =
      data.add_stock_factory == null ? "" : data.add_stock_factory.id;
    getWarehouseAddSelectList();
  }
);

watch(
  () => data.add_stock_supplier,
  () => {
    data.add_stock.warehouse_supplier_id =
      data.add_stock_supplier == null ? "" : data.add_stock_supplier.id;
  }
);

watch(
  () => data.transferFactorySearch,
  () => {
    data.transferWarehouseSearch = null;
    data.transfer.target_warehouse_id = "";
    data.transfer.target_factory_id =
      data.transferFactorySearch == null ? "" : data.transferFactorySearch.id;
    getWarehouseTransferSelectList();
  }
);
watch(
  () => data.transferWarehouseSearch,
  () => {
    data.transfer.target_warehouse_id =
      data.transferWarehouseSearch == null
        ? ""
        : data.transferWarehouseSearch.id;
  }
);

watch(
  () => data.warehosueSearch,
  () => {
    data.stockLoading = true;
    data.stockFilters.warehouse_id.value =
      data.warehosueSearch == null ? "" : data.warehosueSearch.id;
    getStockList();
  }
);
watch(
  () => data.iteamSearch,
  () => {
    data.stockLoading = true;
    data.stockFilters.iteam.value =
      data.iteamSearch == null ? "" : data.iteamSearch.id;
    getStockList();
  }
);
watch(
  () => data.in_date_search,
  () => {
    data.stockLoading = true;
    if (data.in_date_search != null) {
      data.stockFilters.in_date_begin.value =
        data.in_date_search[0] != null
          ? data.in_date_search[0].toISOString()
          : "";
      data.stockFilters.in_date_end.value =
        data.in_date_search[1] != null
          ? data.in_date_search[1].toISOString()
          : "";
    } else {
      data.stockFilters.in_date_begin.value = "";
      data.stockFilters.in_date_end.value = "";
    }

    getStockList();
  }
);
watch(
  () => data.out_date_search,
  () => {
    data.stockLoading = true;
    if (data.out_date_search != null) {
      data.stockFilters.out_date_begin.value =
        data.out_date_search[0] != null
          ? data.out_date_search[0].toISOString()
          : "";
      data.stockFilters.out_date_end.value =
        data.out_date_search[1] != null
          ? data.out_date_search[1].toISOString()
          : "";
    } else {
      data.stockFilters.out_date_begin.value = "";
      data.stockFilters.out_date_end.value = "";
    }

    getStockList();
  }
);

function dateFormate(dateString) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formatted_date;
}
function onDecode(qrCode) {
  data.qrCode = qrCode;
  data.outJson = qrCode;
}
function drawOutline(decodeData, context) {
  var points = [];
  for (var k in decodeData) {
    switch (k) {
      case "topLeftCorner":
        points[0] = decodeData[k];
        break;
      case "topRightCorner":
        points[1] = decodeData[k];
        break;
      case "bottomRightCorner":
        points[2] = decodeData[k];
        break;
      case "bottomLeftCorner":
        points[3] = decodeData[k];
        break;
      default:
        break;
    }
  }
  context.font = "13px Arial";
  context.fillText(data.qrCode, points[0].x, points[0].y);
  context.lineWidth = 5;
  context.strokeStyle = "green";
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (const { x, y } of points) {
    context.lineTo(x, y);
  }
  context.lineTo(points[0].x, points[0].y);
  context.closePath();
  context.stroke();
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :value="data.stocks.data"
      :loading="data.stockLoading"
      dataKey="id"
      responsiveLayout="scroll"
      showGridlines
      scrollDirection="both"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="data.page_num"
      :totalRecords="data.totalRecords"
      v-model:filters="data.stockFilters"
      v-model:selection="data.selectedStocks"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Stock").StockTable.Search }}</span>
              </template>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stockFilters[
                        i18n.$t('Stock').StockTable.Chinese.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Stock').StockTable.Chinese.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stockFilters[
                        i18n.$t('Stock').StockTable.English.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Stock').StockTable.English.Show"
                  />
                </span>
                <!-- <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.stockFilters[
                        i18n.$t('Stock').StockTable.Factory.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
                  />
                </span> -->
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.factorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.warehouseSearch"
                    :options="data.selectWarehouseList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').StockTable.Warehouse.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.iteamSearch"
                    :options="data.selectIteamList"
                    optionLabel="id"
                    :filter="true"
                    :placeholder="i18n.$t('Stock').AddStock.Iteam"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.id }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.id }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.statusSearch"
                    :options="[
                      {
                        name: i18n.$t('Stock').StockTable.Out,
                        value: 1,
                      },
                      {
                        name: i18n.$t('Stock').StockTable.In,
                        value: 2,
                      },
                    ]"
                    optionLabel="name"
                    :placeholder="i18n.$t('Stock').StockTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          {{
                            slotProps.value.value == 1
                              ? i18n.$t("Stock").StockTable.Out
                              : i18n.$t("Stock").StockTable.In
                          }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.in_date_search"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="i18n.$t('Stock').StockTable.InDate.Show"
                    :showButtonBar="true"
                  />
                </span>
              </div>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.out_date_search"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="i18n.$t('Stock').StockTable.OutDate.Show"
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </AccordionTab>
          </Accordion>

          <div
            class="p-col-12 p-d-flex p-jc-end"
            style="white-space: nowrap; overflow: scroll"
          >
            <label class="button-up p-mr-2">
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="readFile"
              />
              <i class="pi pi-plus"></i>
              {{ i18n.$t("Material").MaterialTable.Excel }}
            </label>
            <Button
              :label="i18n.$t('Stock').StockTable.PrintTotal"
              icon="pi pi-print"
              class="p-button-info p-mr-2"
              @click="printTotalStock"
            />
            <Button
              :label="i18n.$t('Stock').StockTable.AddTotal"
              icon="pi pi-box"
              class="p-button-success p-mr-2"
              @click="addTotalStock"
            />
            <Button
              :label="i18n.$t('Stock').StockTable.Excel"
              icon="pi pi-book"
              class="p-button-success p-mr-2"
              @click="excelStock"
            />
            <Button
              :label="i18n.$t('Stock').StockTable.Add"
              icon="pi pi-inbox"
              class="p-button-success p-mr-2 p-d-none"
              @click="addStock"
            />
            <Button
              :label="i18n.$t('Stock').StockTable.Delete"
              icon="pi pi-qrcode"
              class="p-button-danger p-mr-2"
              @click="confirmDeleteSelected"
            />
            <Button
              v-if="store.state.user.role == 1 || store.state.user.role == 3"
              :disabled="!data.selectedStocks || !data.selectedStocks.length"
              :label="i18n.$t('Stock').StockTable.TotalDelete"
              icon="pi pi-qrcode"
              class="p-button-danger p-mr-2"
              @click="confirmDeleteSelectedTotal"
            />
            <Button
              v-if="store.state.user.role == 1 || store.state.user.role == 3"
              :disabled="!data.selectedStocks || !data.selectedStocks.length"
              :label="i18n.$t('Stock').StockTable.TotalTransfer"
              icon="pi pi-reply"
              class="p-button-danger"
              @click="transferStockBatch"
            />
          </div>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.ID.Field"
        :header="i18n.$t('Stock').StockTable.ID.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.Chinese.Field"
        :header="i18n.$t('Stock').StockTable.Chinese.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.English.Field"
        :header="i18n.$t('Stock').StockTable.English.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.Factory.Field"
        :header="i18n.$t('Stock').StockTable.Factory.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.WarehouseSupplierName.Field"
        :header="i18n.$t('Stock').StockTable.WarehouseSupplierName.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.Warehouse.Field"
        :header="i18n.$t('Stock').StockTable.Warehouse.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.Iteam.Field"
        :header="i18n.$t('Stock').StockTable.Iteam.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.Weight.Field"
        :header="i18n.$t('Stock').StockTable.Weight.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Stock').StockTable.WeightUnit.Field"
        :header="i18n.$t('Stock').StockTable.WeightUnit.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.Count.Field"
        :header="i18n.$t('Stock').StockTable.Count.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :field="i18n.$t('Stock').StockTable.UnitDollar.Field"
        :header="i18n.$t('Stock').StockTable.UnitDollar.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Stock').StockTable.Remark.Field"
        :header="i18n.$t('Stock').StockTable.Remark.Show"
        style="width: 120px; overflow: auto"
      ></Column>
      <Column
        :header="i18n.$t('Stock').StockTable.Status.Show"
        style="width: 120px; overflow: auto"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Stock").StockTable.Out
              : i18n.$t("Stock").StockTable.In
          }}
        </template>
      </Column>
      <Column
        :header="i18n.$t('Stock').StockTable.Date"
        style="width: 120px; overflow: auto"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? dateFormate(slotProps.data.out_date) +
                `(${i18n.$t("Stock").StockTable.Out})`
              : dateFormate(slotProps.data.in_date) +
                `(${i18n.$t("Stock").StockTable.In})`
          }}
        </template>
      </Column>
      <Column style="width: 120px; overflow: auto">
        <template #body="slotProps">
          <div style="width: 80px">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-1"
              v-if="store.state.user.role == 1"
              @click="editStock(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDelete(slotProps.data)"
            />
            <Button
              icon="pi pi-print"
              class="p-button-rounded p-button-danger p-mr-1"
              @click="printStock(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.status == 2"
              icon="pi pi-reply"
              class="p-button-rounded p-button-info"
              @click="transferStock(slotProps.data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="data.download"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').ExcelStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="factory">{{ i18n.$t("Stock").ExcelStock.Total }}</label>
        {{ data.excelTotal }}
        <vue3-json-excel
          class="button"
          :header="'Stock'"
          :fields="{
            Serial_No: 'id',
            Factory: 'factory_name',
            Warehouse: 'warehouse_name',
            Supplier: 'warehouse_supplier_name',
            Name_CH: 'chinese',
            Name_EN: 'english',
            Qty_Unit: 'weight',
            Unit: 'weight_unit',
            Unit_Counter: 'count',
            Total_Price: 'unit_dollar',
            Stock_In_Date: 'in_date',
            Stock_Out_Date: 'out_date',
            Status: 'status',
            Remark: 'remark',
          }"
          :json-data="data.excel"
          :name="data.nowDate"
        >
          {{ i18n.$t("Stock").ExcelStock.Download }}
        </vue3-json-excel>
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.download = false"
        />
      </template>
    </Dialog>
    <!-- 轉倉 -->
    <Dialog
      v-model:visible="data.transferDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').TransferStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div v-if="!data.transfer_batch_check" class="p-field">
        <Dropdown
          v-model="data.transfer.status"
          :options="[
            {
              name: i18n.$t('Stock').TransferStock.Transfer,
              value: 1,
            },
            {
              name: i18n.$t('Stock').TransferStock.Sell,
              value: 2,
            },
          ]"
          optionLabel="name"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>
                {{
                  slotProps.value.value == 1
                    ? i18n.$t("Stock").TransferStock.Transfer
                    : i18n.$t("Stock").TransferStock.Sell
                }}
              </div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="in_date">{{ i18n.$t("Stock").AddStock.Date }}</label>
        <InputText
          id="in_date"
          :value="dateFormate(new Date())"
          readonly="true"
          required="true"
        />
      </div>
      <div
        class="p-field"
        v-if="data.transfer.status.value == 2 && !data.transfer_batch_check"
      >
        <label for="in_date">{{ i18n.$t("Stock").AddStock.Factory }}</label>
        <Dropdown
          v-model="data.transferFactorySearch"
          :options="data.selectFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="in_date">{{ i18n.$t("Stock").AddStock.Warehouse }}</label>
        <Dropdown
          v-model="data.transferWarehouseSearch"
          :options="data.selectTransferWarehouseList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.Warehouse.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div
        class="p-field"
        v-if="data.transfer.status.value == 2 && !data.transfer_batch_check"
      >
        <label for="in_date">{{ i18n.$t("Stock").AddStock.Iteam }}</label>
        <Dropdown
          v-model="data.transfer.iteam"
          :options="data.selectIteamList"
          optionLabel="id"
          :filter="true"
          :placeholder="i18n.$t('Stock').AddStock.Iteam"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.id }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.id }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      -------------------------------------------------------
      <div v-if="!data.transfer_batch_check" class="p-field">
        <label for="english">{{ i18n.$t("Stock").AddStock.ID }}</label>
        <InputText
          id="english"
          v-model.trim="data.transferStockDetail.id"
          required="true"
          readonly="true"
        />
      </div>
      <div v-if="!data.transfer_batch_check" class="p-field">
        <label for="english">{{ i18n.$t("Stock").AddStock.English }}</label>
        <InputText
          id="english"
          v-model.trim="data.transferStockDetail.english"
          required="true"
          readonly="true"
        />
      </div>
      <div v-if="!data.transfer_batch_check" class="p-field">
        <label for="english">{{ i18n.$t("Stock").AddStock.Factory }}</label>
        <InputText
          id="english"
          v-model.trim="data.transferStockDetail.factory_name"
          required="true"
          readonly="true"
        />
      </div>
      <div v-if="!data.transfer_batch_check" class="p-field">
        <label for="english">{{ i18n.$t("Stock").AddStock.Warehouse }}</label>
        <InputText
          id="english"
          v-model.trim="data.transferStockDetail.warehouse_name"
          required="true"
          readonly="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.transferDialog = false"
        />
        <Button
          v-if="!data.transfer_batch_check"
          :label="i18n.$t('Stock').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveTransferStock"
        />
        <Button
          v-else
          :label="i18n.$t('Stock').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveTransferStockBatch"
        />
      </template>
    </Dialog>

    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addTotalDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').AddStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Iteam }}</label>
        <Dropdown
          v-model="data.add_stock.iteam"
          :options="data.selectIteamList"
          optionLabel="id"
          :filter="true"
          :placeholder="i18n.$t('Stock').AddStock.Iteam"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.id }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.id }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Factory }}</label>
        <Dropdown
          v-model="data.add_stock_factory"
          :options="data.selectFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Warehouse }}</label>
        <Dropdown
          v-model="data.add_stock_warehouse"
          :options="data.selectWarehouseAddList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.Warehouse.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Supplier }}</label>
        <Dropdown
          v-model="data.add_stock_supplier"
          :options="data.selectWarehouseSupplierAddList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.WarehouseSupplierName.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <!-- <div class="p-field">
        <label for="factory">{{ i18n.$t("Stock").AddStock.Factory }}</label>
        <InputText
          id="factory"
          v-model.trim="data.add_stock.factory"
          required="true"
        />
      </div> -->
      <div class="p-field">
        <label for="english">{{ i18n.$t("Stock").AddStock.English }}</label>
        <InputText
          id="english"
          v-model.trim="data.add_stock.english"
          required="true"
          readonly="true"
        />
      </div>
      <div class="p-field">
        <label for="chinese">{{ i18n.$t("Stock").AddStock.Chinese }}</label>
        <InputText
          id="chinese"
          v-model.trim="data.add_stock.chinese"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="weight">{{ i18n.$t("Stock").AddStock.Weight }}</label>
        <InputText
          id="weight"
          v-model.trim="data.add_stock.weight"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="weight">{{ i18n.$t("Stock").AddStock.WeightUnit }}</label>
        <InputText
          id="weight"
          v-model.trim="data.add_stock.weight_unit"
          required="true"
        />
      </div>
      <div class="p-field">
        <label>{{ i18n.$t("Stock").AddStock.Dollar }}</label>
        <InputNumber v-model="data.add_stock.unit_dollar" required="true" />
      </div>
      <div class="p-field">
        <label for="count">{{ i18n.$t("Stock").AddStock.Count }}</label>
        <InputText
          id="count"
          v-model.trim="data.add_stock.count"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="remark">{{ i18n.$t("Stock").AddStock.Remark }}</label>
        <InputText
          id="remark"
          v-model.trim="data.add_stock.remark"
          required="false"
        />
      </div>
      <!-- <div class="p-field">
        <label for="in_date">{{ i18n.$t("Stock").AddStock.In_Date }}</label>
        <InputText
          id="in_date"
          v-model.trim="data.add_stock.in_date"
          readonly="true"
          required="true"
        />
      </div> -->
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addTotalDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddTotalStock"
        />
      </template>
    </Dialog>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').AddStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Iteam }}</label>
        <Dropdown
          v-model="data.add_stock.iteam"
          :options="data.selectIteamList"
          optionLabel="id"
          :filter="true"
          :placeholder="i18n.$t('Stock').AddStock.Iteam"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.id }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.id }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Factory }}</label>
        <Dropdown
          v-model="data.add_stock_factory"
          :options="data.selectFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.Factory.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Warehouse }}</label>
        <Dropdown
          v-model="data.add_stock_warehouse"
          :options="data.selectWarehouseAddList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.Warehouse.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="iteam">{{ i18n.$t("Stock").AddStock.Supplier }}</label>
        <Dropdown
          v-model="data.add_stock_supplier"
          :options="data.selectWarehouseSupplierAddList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Stock').StockTable.WarehouseSupplierName.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <!-- <div class="p-field">
        <label for="factory">{{ i18n.$t("Stock").AddStock.Factory }}</label>
        <InputText
          id="factory"
          v-model.trim="data.add_stock.factory"
          required="true"
        />
      </div> -->
      <div class="p-field">
        <label for="english">{{ i18n.$t("Stock").AddStock.English }}</label>
        <InputText
          id="english"
          v-model.trim="data.add_stock.english"
          required="true"
          readonly="true"
        />
      </div>
      <div class="p-field">
        <label for="chinese">{{ i18n.$t("Stock").AddStock.Chinese }}</label>
        <InputText
          id="chinese"
          v-model.trim="data.add_stock.chinese"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="weight">{{ i18n.$t("Stock").AddStock.Weight }}</label>
        <InputText
          id="weight"
          v-model.trim="data.add_stock.weight"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="weight_unit">{{
          i18n.$t("Stock").AddStock.WeightUnit
        }}</label>
        <InputText
          id="weight"
          v-model.trim="data.add_stock.weight_unit"
          required="true"
        />
      </div>
      <div class="p-field">
        <label>{{ i18n.$t("Stock").AddStock.Dollar }}</label>
        <InputNumber v-model="data.add_stock.unit_dollar" required="true" />
      </div>
      <div class="p-field">
        <label for="count">{{ i18n.$t("Stock").AddStock.Count }}</label>
        <InputText
          id="count"
          v-model.trim="data.add_stock.count"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="remark">{{ i18n.$t("Stock").AddStock.Remark }}</label>
        <InputText
          id="remark"
          v-model.trim="data.add_stock.remark"
          required="false"
        />
      </div>
      <!-- <div class="p-field">
        <label for="in_date">{{ i18n.$t("Stock").AddStock.In_Date }}</label>
        <InputText
          id="in_date"
          v-model.trim="data.add_stock.in_date"
          readonly="true"
          required="true"
        />
      </div> -->
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddStock"
        />
      </template>
    </Dialog>
    <!-- 列印視窗 -->
    <Dialog
      v-model:visible="data.printTotalDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').PrintStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div ref="printDom">
        <div
          class="p-mt-1"
          style="
            width: 400px;
            border: 1px solid;
            border-radius: 20px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
          "
          v-for="(item, index) in data.print_total_stock"
        >
          <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <label for="factory"
              >{{ i18n.$t("Stock").PrintStock.Factory }}:{{
                item.factory_name
              }}</label
            ><br />
            <label for="factory"
              >{{ i18n.$t("Stock").AddStock.Supplier }}:{{
                item.warehouse_supplier_name
              }}</label
            ><br />
            <label for="factory"
              >{{ i18n.$t("Stock").PrintStock.Warehouse }}:{{
                item.warehouse_name
              }}</label
            ><br />
            <label for="iteam"
              >{{ i18n.$t("Stock").PrintStock.Iteam }}:{{ item.iteam }}</label
            ><br />
            <label class="p-d-none" for="chinese"
              >{{ i18n.$t("Stock").PrintStock.Chinese }}:{{
                item.chinese
              }}</label
            ><br class="p-d-none" />
            <label for="english"
              >{{ i18n.$t("Stock").PrintStock.English }}:{{
                item.english
              }}</label
            ><br />
            <label for="weight"
              >{{ i18n.$t("Stock").AddStock.Weight }}:{{ item.weight }}</label
            ><br />
            <label for="weight_unit"
              >{{ i18n.$t("Stock").StockTable.WeightUnit.Show }}:{{
                item.weight_unit
              }}</label
            ><br />
            <label for="count"
              >{{ i18n.$t("Stock").AddStock.Count }}:{{ item.count }}</label
            ><br />
            <label class="p-d-none" for="unit_dollar"
              >{{ i18n.$t("Stock").AddStock.Dollar }}:{{
                item.unit_dollar
              }}</label
            ><br class="p-d-none" />
            <label for="in_date"
              >{{ i18n.$t("Stock").AddStock.In_Date }}:{{
                item.in_date.substring(0, 10)
              }}</label
            >
          </div>
          <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <QRCodeVue3
              :width="160"
              :height="160"
              :qrOptions="{
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'H',
              }"
              :dotsOptions="{
                type: 'classy',
                color: '#26249a',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#26249a' },
                    { offset: 1, color: '#26249a' },
                  ],
                },
              }"
              :value="
                JSON.stringify({
                  id: item.id,
                })
              "
            />
            Serial No.:{{ JSON.stringify({ id: item.id }) }}
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.printTotalDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').PrintStock.Print"
          icon="pi pi-check"
          class="p-button-text"
          @click="printHTML(printDom)"
        />
      </template>
    </Dialog>
    <!-- 列印視窗 -->
    <Dialog
      v-model:visible="data.printDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Stock').PrintStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div ref="printDom">
        <div
          class="p-mt-1"
          style="
            width: 400px;
            border: 1px solid;
            border-radius: 20px;
            display: flex;
            flex-wrap: wrap;
          "
        >
          <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <label for="factory"
              >{{ i18n.$t("Stock").PrintStock.Factory }}:{{
                data.print_stock.factory_name
              }}</label
            ><br />
            <label for="factory"
              >{{ i18n.$t("Stock").AddStock.Supplier }}:{{
                data.print_stock.warehouse_supplier_name
              }}</label
            ><br />
            <label for="factory"
              >{{ i18n.$t("Stock").PrintStock.Warehouse }}:{{
                data.print_stock.warehouse_name
              }}</label
            ><br />
            <label for="iteam"
              >{{ i18n.$t("Stock").PrintStock.Iteam }}:{{
                data.print_stock.iteam
              }}</label
            ><br />
            <label class="p-d-none" for="chinese"
              >{{ i18n.$t("Stock").PrintStock.Chinese }}:{{
                data.print_stock.chinese
              }}</label
            ><br class="p-d-none" />
            <label for="english"
              >{{ i18n.$t("Stock").PrintStock.English }}:{{
                data.print_stock.english
              }}</label
            ><br />
            <label for="weight"
              >{{ i18n.$t("Stock").AddStock.Weight }}:{{
                data.print_stock.weight
              }}</label
            ><br />
            <label for="weight_unit"
              >{{ i18n.$t("Stock").StockTable.WeightUnit.Show }}:{{
                data.print_stock.weight_unit
              }}</label
            ><br />
            <label for="count"
              >{{ i18n.$t("Stock").AddStock.Count }}:{{
                data.print_stock.count
              }}</label
            ><br />
            <label class="p-d-none" for="unit_dollar"
              >{{ i18n.$t("Stock").AddStock.Dollar }}:{{
                data.print_stock.unit_dollar
              }}</label
            ><br class="p-d-none" />
            <label for="in_date"
              >{{ i18n.$t("Stock").AddStock.In_Date }}:{{
                data.print_stock.in_date.substring(0, 10)
              }}</label
            ><br />
            <!-- JSON:{{ JSON.stringify({ id: data.print_stock.id }) }} -->
          </div>
          <!-- <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <label for="chinese"
              >{{ i18n.$t("Stock").PrintStock.Chinese }}:{{
                data.print_stock.chinese
              }}</label
            ><br />
            <label for="english"
              >{{ i18n.$t("Stock").PrintStock.English }}:{{
                data.print_stock.english
              }}</label
            >
          </div> -->
          <!-- <div
            style="width: 45%; flex: 0 0 auto; padding: 0.5rem; align-self: end"
          >
            <label for="weight"
              >{{ i18n.$t("Stock").AddStock.Weight }}:{{
                data.print_stock.weight
              }}</label
            ><br />
            <label for="count"
              >{{ i18n.$t("Stock").AddStock.Count }}:{{
                data.print_stock.count
              }}</label
            ><br />
            <label for="in_date"
              >{{ i18n.$t("Stock").AddStock.In_Date }}:{{
                data.print_stock.in_date
              }}</label
            >
          </div> -->
          <div style="width: 45%; flex: 0 0 auto; padding: 0.5rem">
            <QRCodeVue3
              :width="160"
              :height="160"
              :qrOptions="{
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'H',
              }"
              :dotsOptions="{
                type: 'classy',
                color: '#26249a',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#26249a' },
                    { offset: 1, color: '#26249a' },
                  ],
                },
              }"
              :value="
                JSON.stringify({
                  id: data.print_stock.id,
                })
              "
            />
            Serial No.:{{ JSON.stringify({ id: data.print_stock.id }) }}
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.printDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').PrintStock.Print"
          icon="pi pi-check"
          class="p-button-text"
          @click="printHTML(printDom)"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Stock').EditStock.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-fluid" v-if="data.stock != null">
        <div class="p-field">
          <label for="factory">{{ i18n.$t("Stock").AddStock.ID }}</label>
          <InputText
            id="factory"
            v-model.trim="data.stock.id"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="factory">{{ i18n.$t("Stock").AddStock.Factory }}</label>
          <InputText
            id="factory"
            v-model.trim="data.stock.factory_name"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="factory">{{ i18n.$t("Stock").AddStock.Supplier }}</label>
          <InputText
            id="factory"
            v-model.trim="data.stock.warehouse_supplier_name"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="factory">{{ i18n.$t("Stock").AddStock.Warehouse }}</label>
          <InputText
            id="factory"
            v-model.trim="data.stock.warehouse_name"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="english">{{ i18n.$t("Stock").AddStock.English }}</label>
          <InputText
            id="english"
            v-model.trim="data.stock.english"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="chinese">{{ i18n.$t("Stock").AddStock.Chinese }}</label>
          <InputText
            id="chinese"
            v-model.trim="data.stock.chinese"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="weight">{{ i18n.$t("Stock").AddStock.Weight }}</label>
          <InputText
            id="weight"
            v-model.trim="data.stock.weight"
            required="true"
          />
        </div>
        <div class="p-field">
          <label>{{ i18n.$t("Stock").AddStock.Dollar }}</label>
          <InputNumber v-model="data.stock.unit_dollar" required="true" />
        </div>
        <div class="p-field">
          <label for="count">{{ i18n.$t("Stock").AddStock.Count }}</label>
          <InputText
            id="count"
            v-model.trim="data.stock.count"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="remark">{{ i18n.$t("Stock").AddStock.Remark }}</label>
          <InputText
            id="remark"
            v-model.trim="data.stock.remark"
            required="false"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="in_date">{{ i18n.$t("Stock").AddStock.In_Date }}</label>
          <InputText
            id="in_date"
            :value="dateFormate(data.stock.in_date)"
            readonly="true"
            required="true"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditStock"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Stock').OutStock"
      :modal="true"
    >
      <div class="p-fluid">
        <div class="p-field">
          <label for="name">{{ i18n.$t("Stock").OutStockScan }}</label>
          <InputText
            class="p-ml-1"
            v-model="data.outJson"
            id="name"
            required="true"
          />
          <qrcode-stream
            v-if="data.outData == null"
            class="p-mt-1"
            @decode="onDecode"
            :track="drawOutline"
          >
          </qrcode-stream>
        </div>
      </div>
      <div class="p-fluid" v-if="data.outData != null">
        <div class="p-field">
          <label for="factory">{{ i18n.$t("Stock").AddStock.ID }}</label>
          <InputText
            id="factory"
            v-model.trim="data.out_stock.id"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="factory">{{ i18n.$t("Stock").AddStock.Factory }}</label>
          <InputText
            id="factory"
            v-model.trim="data.out_stock.factory"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="english">{{ i18n.$t("Stock").AddStock.English }}</label>
          <InputText
            id="english"
            v-model.trim="data.out_stock.english"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="chinese">{{ i18n.$t("Stock").AddStock.Chinese }}</label>
          <InputText
            id="chinese"
            v-model.trim="data.out_stock.chinese"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="weight">{{ i18n.$t("Stock").AddStock.Weight }}</label>
          <InputText
            id="weight"
            v-model.trim="data.out_stock.weight"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="count">{{ i18n.$t("Stock").AddStock.Count }}</label>
          <InputText
            id="count"
            v-model.trim="data.out_stock.count"
            required="true"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="remark">{{ i18n.$t("Stock").AddStock.Remark }}</label>
          <InputText
            id="remark"
            v-model.trim="data.out_stock.remark"
            required="false"
            readonly="true"
          />
        </div>
        <div class="p-field">
          <label for="in_date">{{ i18n.$t("Stock").AddStock.In_Date }}</label>
          <InputText
            id="in_date"
            :value="dateFormate(data.out_stock.in_date)"
            readonly="true"
            required="true"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').OutStock"
          icon="pi pi-check"
          class="p-button-text"
          :disabled="data.outData == null ? true : false"
          @click="deleteSelectedStock"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Stock').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.stock"
          >{{ i18n.$t("Stock").Confirm.Content }}
          <b>{{ data.stock.english }}({{ data.stock.chinese }})</b>?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteStock"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedTotalDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Stock').OutStock"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Stock").Confirm.OutStock }} ?</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Stock').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedTotalDialog = false"
        />
        <Button
          :label="i18n.$t('Stock').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteStockTotal"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 8px 14px;
  border: 0px solid #258f3e;
  border-radius: 17px;
  background: #3ce964;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3ce964),
    to(#258f3e)
  );
  background: -moz-linear-gradient(top, #3ce964, #258f3e);
  background: linear-gradient(to bottom, #3ce964, #258f3e);
  -webkit-box-shadow: #37d75c 0px 0px 0px 0px;
  -moz-box-shadow: #37d75c 0px 0px 0px 0px;
  box-shadow: #37d75c 0px 0px 0px 0px;
  text-shadow: #175a27 0px 1px 1px;
  font: normal normal bold 15px arial;
  color: #ffffff;
  text-decoration: none;
}
.button:hover,
.button:focus {
  border: 0px solid #2eb34d;
  background: #48ff78;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#48ff78),
    to(#2cac4a)
  );
  background: -moz-linear-gradient(top, #48ff78, #2cac4a);
  background: linear-gradient(to bottom, #48ff78, #2cac4a);
  color: #ffffff;
  text-decoration: none;
}
.button:active {
  background: #258f3e;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#258f3e),
    to(#258f3e)
  );
  background: -moz-linear-gradient(top, #258f3e, #258f3e);
  background: linear-gradient(to bottom, #258f3e, #258f3e);
}
.button:after {
  content: "\0000a0";
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 -4px -6px 4px;
  position: relative;
  top: 0px;
  left: 0px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABqElEQVRIie3UsWoUYRQF4O+ucQlqkcaQQsRGEBRJkUINYiEhTAKpdix8gV2fQHySjD6Cm06yQTSCWAjBKoiIgnWIIhYhhcj+FpldNqOJk1QWOTDMP/+595x7/mGGE/wDUbdw8V67kVK8gYh0e/Xp436dvrHao/Qb4yJND9fs1mlr1DY4Jk4M/nODLO80slbnwJqs1Wlk+cE8h3wHWd4eC7GOM1iMFDsp0leIFOdT9M8Rq9ilf7fXffLrSAlCgnHMYD1JUwMuRZqyZz6D8b3SIyaAhbw9RTzHdXzCpZL6givYxHyvW2wdywCyVnsyItZxrUK9x9xh4n8YLOSdt+jjTq9bDM80a3UmI7wok8BmSubWVortkd4xvEaj1y1uDvar7+AGplPSHN1cWym2UzKPDWykZH5UHMqe6VJjiKrBDpoRJqpR11aKLdIsaXZvXTmKSBNolhpDVP+mr7CE5SzvPCD92Ddlec/y9r6EkWICy6Xey8MMHuIWloIF4md10r8iNEut73g0Sp0affj84d23y1dnnuECLuIsTte4dtHD/V63+FhrqBMM8Btk+3u+nyYmoAAAAABJRU5ErkJggg==")
    no-repeat left center transparent;
  background-size: 100% 100%;
}
</style>
