import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PurchaseOrderOther {
  getPurchaseOrderOtherInfo(id) {
    return axios.get(
      `/api/order/purchase-order-other/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPurchaseOrderOtherList(purchase_order_id, is_use, page, page_num) {
    return axios.get(
      `/api/order/purchase-order-other/list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
          is_use: is_use,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPurchaseOrderOtherSelectList(purchase_order_id) {
    return axios.get(
      `/api/order/purchase-order-other/select-list`,
      {
        params: {
          purchase_order_id: purchase_order_id,
        },
      },
      config
    );
  }
  getPurchaseOrderOtherExcel(purchase_order_ids) {
    return axios.get(
      `/api/order/purchase-order-other/select-list`,
      {
        params: {
          purchase_order_ids: purchase_order_ids,
          is_use: 1,
        },
      },
      config
    );
  }
  batchPurchaseOrderOther(datas) {
    return axios.post(
      `/api/order/batch/purchase-order-other`,
      {
        datas: datas,
      },
      config
    );
  }
  addPurchaseOrderOther(
    is_use_ids,
    not_is_use_ids,
    purchase_order_id,
    name,
    price,
    count,
    total_price,
    version,
    employer_id,
    employer_email,
    is_use
  ) {
    return axios.post(
      `/api/order/purchase-order-other`,
      {
        is_use_ids: is_use_ids,
        not_is_use_ids: not_is_use_ids,
        purchase_order_id: purchase_order_id,
        name: name,
        price: price,
        count: count,
        total_price: total_price,
        version: version,
        employer_id: employer_id,
        employer_email: employer_email,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderOther(id, is_use) {
    return axios.put(
      `/api/order/purchase-order-other/is-use`,
      {
        id: id,
        is_use: is_use,
      },
      config
    );
  }
  editPurchaseOrderOtherContent(
    purchase_order_id,
    id,
    name,
    price,
    count,
    total_price
  ) {
    return axios.put(
      `/api/order/purchase-order-other/`,
      {
        purchase_order_id: purchase_order_id,
        id: id,
        name: name,
        price: price,
        count: count,
        total_price: total_price,
      },
      config
    );
  }
  // deletePurchaseOrderOther(id) {
  //   return axios.delete(
  //     `/api/order/purchase-order-other`,
  //     {
  //       data: {
  //         ids: id,
  //       },
  //     },
  //     config
  //   );
  // }
}
