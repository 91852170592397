<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import CmtFactory from "../../api/order/cmt_factory";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let CmtFactoryAPI = new CmtFactory();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  add_cmt_factory: {
    id: 0,
    name: "",
    description: "",
  },
  cmt_factoryLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  cmt_factorys: { data: null },
  cmt_factory: null,
  page: 1,
  page_num: 10,
  selected_cmt_factorys: null,
  cmt_factoryFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getCmtFactoryList();
  }
});

function getCmtFactoryList() {
  CheckUser();
  CmtFactoryAPI.getCmtFactoryList(
    data.cmt_factoryFilters.id.value,
    data.cmt_factoryFilters.name.value,
    data.page,
    data.page_num
  ).then((res) => {
    if (res.data.error_code == 0) {
      data.totalRecords = res.data.data.total_count;
      data.cmt_factorys.data = res.data.data.datas;
      data.page = res.data.data.page;
      // data.page_num = res.data.data.page_num;
      // console.log(res);
      data.cmt_factoryLoading = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });

      data.totalRecords = 0;
      data.cmt_factoryLoading = false;
    }
  });
}

function saveEditCmtFactory() {
  CmtFactoryAPI.editCmtFactory(
    data.cmt_factory.id,
    data.cmt_factory.name,
    data.cmt_factory.description
  ).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("CmtFactory").EditSuccess,
        life: 3000,
      });

      data.cmt_factoryLoading = true;
      getCmtFactoryList();
      data.editDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function saveAddCmtFactory() {
  CmtFactoryAPI.addCmtFactory(
    data.add_cmt_factory.name,
    data.add_cmt_factory.description
  ).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("CmtFactory").AddSuccess,
        life: 3000,
      });

      data.cmt_factoryLoading = true;
      getCmtFactoryList();
      data.addDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function addCmtFactory() {
  data.addDialog = true;
}
function editCmtFactory(cmt_factory) {
  data.cmt_factory = { ...cmt_factory };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(cmt_factory) {
  data.cmt_factory = cmt_factory;
  data.deleteDialog = true;
}
function deleteCmtFactory() {
  CmtFactoryAPI.deleteCmtFactory([data.cmt_factory.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("CmtFactory").DeleteSuccess,
        life: 3000,
      });

      data.cmt_factoryLoading = true;
      getCmtFactoryList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedCmtFactory() {
  let selectDelete = [];
  for (let i = 0; i < data.selected_cmt_factorys.length; i++) {
    selectDelete.push(data.selected_cmt_factorys[i].id);
  }
  CmtFactoryAPI.deleteCmtFactory(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("CmtFactory").DeleteSuccess,
        life: 3000,
      });

      data.CmtFactoryLoading = true;
      data.selected_cmt_factorys = [];
      getCmtFactoryList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.cmt_factoryLoading = true;
    getCmtFactoryList();
  }
);
watch(
  () => data.cmt_factoryFilters.name.value,
  () => {
    data.cmt_factoryLoading = true;
    getCmtFactoryList();
  }
);
watch(
  () => data.cmt_factoryFilters.id.value,
  () => {
    data.cmt_factoryLoading = true;
    getCmtFactoryList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.cmt_factorys.data"
      :loading="data.cmt_factoryLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 255px)"
      style="min-width: 375px; margin-top: 150px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.cmt_factoryFilters"
      v-model:selection="data.selected_cmt_factorys"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("CmtFactory").CmtFactoryTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.cmt_factoryFilters[
                        i18n.$t('CmtFactory').CmtFactoryTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('CmtFactory').CmtFactoryTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.cmt_factoryFilters[
                        i18n.$t('CmtFactory').CmtFactoryTable.Name.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('CmtFactory').CmtFactoryTable.Name.Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('CmtFactory').CmtFactoryTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addCmtFactory"
            />
            <Button
              :label="i18n.$t('CmtFactory').CmtFactoryTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selected_cmt_factorys ||
                !data.selected_cmt_factorys.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('CmtFactory').CmtFactoryTable.ID.Field"
        :header="i18n.$t('CmtFactory').CmtFactoryTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('CmtFactory').CmtFactoryTable.Name.Field"
        :header="i18n.$t('CmtFactory').CmtFactoryTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('CmtFactory').CmtFactoryTable.Description.Field"
        :header="i18n.$t('CmtFactory').CmtFactoryTable.Description.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editCmtFactory(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('CmtFactory').AddCmtFactory.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CmtFactory").CmtFactoryTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_cmt_factory.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CmtFactory").CmtFactoryTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_cmt_factory.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CmtFactory').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('CmtFactory').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddCmtFactory"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('CmtFactory').EditCmtFactory.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CmtFactory").CmtFactoryTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.cmt_factory.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CmtFactory").CmtFactoryTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.cmt_factory.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <template #footer>
        <Button
          :label="i18n.$t('CmtFactory').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('CmtFactory').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditCmtFactory"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('CmtFactory').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.cmt_factory"
          >{{ i18n.$t("CmtFactory").Confirm.Content }}
          <b>{{ data.cmt_factory.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CmtFactory').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('CmtFactory').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteCmtFactory"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('CmtFactory').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("CmtFactory").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CmtFactory').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('CmtFactory').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedCmtFactory"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
