import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Cloth {
  getClothInfo(id) {
    return axios.get(
      `/api/order/cloth/select-list`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getClothList(id, code_no, page, page_num) {
    return axios.get(
      `/api/order/cloth/list`,
      {
        params: {
          id: id,
          code_no: code_no,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getClothSelectList(code_no) {
    return axios.get(
      `/api/order/cloth/select-list`,
      {
        params: {
          code_no: code_no,
        },
      },
      config
    );
  }
  batchCloth(datas) {
    return axios.post(
      `/api/order/batch/cloth`,
      {
        datas: datas,
      },
      config
    );
  }
  addCloth(
    gsm,
    width,
    code_no,
    construction,
    composition,
    yarn_count,
    code_weight
  ) {
    return axios.post(
      `/api/order/cloth`,
      {
        gsm: gsm,
        width: width,
        code_no: code_no,
        construction: construction,
        composition: composition,
        yarn_count: yarn_count,
        code_weight: code_weight,
      },
      config
    );
  }
  editCloth(
    id,
    gsm,
    width,
    code_no,
    construction,
    composition,
    yarn_count,
    code_weight
  ) {
    return axios.put(
      `/api/order/cloth`,
      {
        id: id,
        gsm: gsm,
        width: width,
        code_no: code_no,
        construction: construction,
        composition: composition,
        yarn_count: yarn_count,
        code_weight: code_weight,
      },
      config
    );
  }
  deleteCloth(id) {
    return axios.delete(
      `/api/order/cloth`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
